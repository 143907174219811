import { 
    ElementRef, 
    EventEmitter, 
    HostListener, 
    Injectable,
    Output, 
    Renderer2 
} from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";

@Injectable()
export class ValueAccessorBase implements ControlValueAccessor {

    /**
     * Emits `noeWriteValue` event and updates of element value.
     */
    @Output() noeWriteValue: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Emits `noeDisabledState` event and updates state of disabled.
     */
    @Output() noeDisabledState: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostListener('noeBlur')
    handleNoeBlurEvent(): void {
        this.onTouched();
    }

    @HostListener('noeChange', ['$event.value'])
    handleNoeChangeEvent(value: any): void {
        this.handleChangeEvent(value);
    }

    @HostListener('noeInput', ['$event.target.value'])
    handleNoeInputEvent(value: any): void {
        console.log('value!!!', value);
        this.handleChangeEvent(value);
    }

    private lastValue: any;

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    get nativeInput(): HTMLInputElement {
        return this.elRef.nativeElement.querySelector('input');
    }

    /**
     * Writes a new value to the element.
     */
    writeValue(value: any): void {
        console.log('value write', value);
        this.lastValue = value === null ? '' : value;

        if (this.nativeInput) {
            this.renderer.setProperty(this.nativeInput, 'value', this.lastValue);
        }

        this.noeWriteValue.emit(this.lastValue);
    }

    /**
     * Registers a callback function that is called when the control's value
     * changes in the UI.
     */
    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API
     * on initialization to update the form model on blur.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Function that is called by the forms API when the control status
     * changes to or from 'DISABLED'.
     */
    setDisabledState(isDisabled: boolean): void {
        if (this.nativeInput) {
            this.renderer.setProperty(this.nativeInput, 'disabled', isDisabled);
        }

        this.noeDisabledState.emit(isDisabled);
    }

    private handleChangeEvent(value: any): void {
        if (this.lastValue !== value) {
            this.lastValue = value;
            this.onChange(value);
        }
    }

    /**
     * Function that is called when the value change.
     */
    private onChange: (value: any) => void;

    /**
     * Function that is called when the input is touched.
     */
    private onTouched: () => void;
}