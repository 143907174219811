import { 
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { from, Observable } from "rxjs";
import Auth from "@aws-amplify/auth";

import { AuthService } from "../services/auth.service";
import { AlertGlobalLogoutServie } from "../services/alert-global-logout.service";

@Injectable()
export class NoAuthorizedInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private alertGlobalLogout: AlertGlobalLogoutServie,
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (!req.headers.has('authorization')) {
            return next.handle(req);
        }
        
        return from(this.handleNoAuthorized(req, next));
    }

    /**
     * Handles no authorized error whenever 
     * the refresh token has been revoked.
     */
    async handleNoAuthorized(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Promise<HttpEvent<any>> { 
        
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const userSession = await Auth.currentSession();

        cognitoUser.refreshSession(
            userSession.getRefreshToken(),
            (error, _) => {
                if (!!error && error.code === 'NotAuthorizedException') {
                    this.alertGlobalLogout.show();                    
                }
            }
        );

        return next.handle(req).toPromise();    
    }
}