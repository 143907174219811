import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-dot-new]',
  templateUrl: './dot-new.component.html',
  styleUrls: ['./dot-new.component.scss']
})
export class DotNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
