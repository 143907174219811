import { Component, OnInit, TemplateRef, Input, Output, EventEmitter, Self, Optional, HostListener, AfterViewChecked } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit, ControlValueAccessor {

  @Input() label:string
  @Input() required:boolean
  @Input() template:TemplateRef<any>
  @Input() displayTextField:string
  @Input() keyProp:string = 'id'
  @Input() inscroller:boolean
  @Input() displayFilter: boolean = true
  @Input() set items(value:any[]) { 
    // console.log(value)
    if (value) this._items = JSON.parse(JSON.stringify(value)) 
    if (this._items.length) {
      this._items.forEach(it => {it.visible = true})
    }
  }

  @Input() search: boolean = false

  @Output()
  selection: EventEmitter<any> = new EventEmitter

  _items: any[]
  _selections: any[]

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl) {
      if (this.ngControl) { this.ngControl.valueAccessor = this }
  }

  onChangeCallback: any = () => {}
  onTouchedCallback: any = () => {}

  writeValue(value:any) {
    // console.log(value)
    this.onChangeCallback(this.clean(value))
    if (value) this.setSelected(value)
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn
  }
  setDisabledState(disabled:boolean) {
    // this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', disabled);
  }

  @HostListener('document:click') clickout():void {
    if (this.show) this.onTouchedCallback()
    this.close()
  }

  show:boolean
  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    // console.log(this.ngControl)
  }

  open():void {
    this.show = true
  }
  close():void {
    this.ngControl.control.markAsTouched()
    this.onTouchedCallback()
    this.show = false
    this.filter('')
  }

  clean(arr:any[]):any[] {
    let cleared = JSON.parse(JSON.stringify(arr));

    if (!!cleared && cleared.length) {
      cleared.forEach((it:any): void => {
        delete it.selected;
        delete it.listIndex;
        delete it.visible;
      });
    } else {
      cleared = [];
    }
    return cleared;
  }

  handleSelectSimple(item:any){
    this.selected = [item]
    this.writeValue(this.selected)
  }

  handleSelect(item:any) {
    if (item.selected) {
      const index:number = this.selected.findIndex(it => it[this.keyProp] === item[this.keyProp])
      this.selected.splice(index, 1)
      item.selected = false
    } else {
      this.selected.push(item)
      item.selected = true
    }
    this.writeValue(this.selected)
  }

  selected:any[] = []
  setSelected(selected:any[]) {
    this.selected = selected
    const identifiers = selected.map(it => it[this.keyProp])
    this._items.forEach(it => it.selected = identifiers.includes(it[this.keyProp]))
    this.selection.emit(this.clean(this.selected))
  }

  remove(event:MouseEvent, selectedIndex:number, itemListIndex:number) {

    event.stopPropagation()

    itemListIndex = this._items.findIndex(it => it[this.keyProp] === this.selected[selectedIndex][this.keyProp])

    this.selected.splice(selectedIndex, 1) 
    this._items[itemListIndex].selected = false

    this.writeValue(this.selected)
  }

  filter(value:string) {
    //console.log("1",value)
    if (value.trim() === '') {
      this._items.forEach(it => it.visible = true)
      return
    }

    this._items.forEach(it => {
      const val = it[this.displayTextField].toLowerCase()
      val.indexOf(value.toLowerCase()) > -1 ? it.visible = true : it.visible = false
    })
  }

}
