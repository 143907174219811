import { Injectable } from "@angular/core";

@Injectable()
export class FirmarPdfNuevoService {
    /**
     * To save the checkboxes status, only for massive signature
     */
    checkStatusList: boolean[];
    /**
     * To save the signatures status, only for massive signature
     */
    signStatusList: boolean[];

    /**
     * Build the initial checkboxes status.
     */
    buildCheckboxesStatus(length: number): void {
        this.checkStatusList = Array.from({ length }, () => false);
    }

    /**
     * Build the initial signatures status.
     */
    buildSignaturesStatus(length: number): void {
        this.signStatusList = Array.from({ length }, () => false);
    }
}