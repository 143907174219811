import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { PasswordValidatorsService } from '../services/password-validators.service';
import { FormValidationService } from '../services/form-validation.service';

import { Auth,Storage } from 'aws-amplify';
import { ModalComponent } from '../shared/modal/modal.component';
import { AuthService } from '../services/auth.service';
import { UsuariosService } from '../services/usuarios.service';
import { EmpresaService } from '../services/empresa.service';
import { PermisosService } from '../services/permisos.service';
import { ReceptorService } from '../services/receptor.service';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { environment } from 'src/environments/environment.qa';
import { PersonalizacionService } from '../services/personalizacion.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {

  @ViewChild('user') userInput:ElementRef
  @ViewChild('passModal') passModal:ModalComponent
  @ViewChild('recoveryModal') recoveryModal:ModalComponent
  @ViewChild('modalSesion') modalSesion:ModalComponent
  @ViewChild('captcha') captcha:InvisibleReCaptchaComponent

  loginForm:FormGroup
  passForm:FormGroup
  recoveryForm:FormGroup
  codeForm:FormGroup
  colorTheme: string;
  inputType = 'password';

  constructor(
    private fb: FormBuilder,
    private formValidation: FormValidationService,
    private router: Router,
    private renderer: Renderer2,
    public validators: PasswordValidatorsService,
    public usuarioService: UsuariosService,
    public authService: AuthService,
    public permisosService: PermisosService,
    public empresaService: EmpresaService,
    public personalizacionService:PersonalizacionService,
    public receptorService:ReceptorService
  ) { }

  errorSubdominio:boolean
  subdominio:string
  imagenCentral:string
  fondoCentral:string
  logoWeb:string

  ngOnInit(): void {
    this.scriptTraductorStyle();
    this.scriptTraductor();
    const url:string = window.location.href
    this.subdominio = url.split('.')[0].split('//')[1]

    // this.renderer.addClass(document.body, 'login')
    
    const passwordValidators = this.validators.set()
    const captchaValidators = environment.TESTING ? [] : [Validators.required]

    this.loginForm = this.fb.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required]],
      recaptcha: ['', captchaValidators]
    })

    this.passForm = this.fb.group({
      password: ['', passwordValidators],
      confirm: ['', [Validators.required]]
    }, { validators: this.checkPasswords })

    this.recoveryForm = this.fb.group({
      user: ['', [Validators.required]]
    })

    this.codeForm = this.fb.group({
      user: ['', [Validators.required]],
      code: ['', [Validators.required]],
      password: ['', passwordValidators],
      confirm: ['', [Validators.required]]
    }, { validators: this.checkPasswords })

     this.obtenerImagenesPersonalizacion();

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-open');
  }

  scriptTraductor() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    document.body.appendChild(script);
  }

  scriptTraductorStyle() {
    var jq = document.createElement("script");
    jq.type = "text/javascript";
    jq.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js";
    document.body.appendChild(jq);
  }

  loadingImagenes:boolean =true
  obtenerImagenesPersonalizacion(){
    this.loadingImagenes = true
    
    this.personalizacionService.listarImagenesPersonalizacion(this.subdominio).subscribe((data:any) => {

      this.loadingImagenes = false
      this.colorTheme = data.color;
      localStorage.setItem('customData', JSON.stringify(data));
      this.imagenCentral=data.imageCentralWeb
      this.fondoCentral=data.fondoWeb
      this.logoWeb = data.logoWeb
      if(this.fondoCentral == "" || this.fondoCentral == null){
        this.fondoCentral = '../../assets/img/fondo-azul.jpg'
      }
      if(this.imagenCentral == "" || this.imagenCentral == null){
        this.imagenCentral = '../../assets/img/imagen-central.jpg'
      }
      
     

     
      //this.fondoWeb=data.imageCentralWeb
     //this.imagenCentral = "public/firmas_holograficas/00001111/1615999823880.jpg"


    }, error => {
      this.loadingImagenes = false
    
    })

  }

  sessionMsg:string = 'Su sesión ha sido terminada por inactividad'
  ngAfterViewInit() {
    // if (JSON.parse(sessionStorage.getItem('LOGGED_OUT'))) setTimeout(() => { 
    //   if(JSON.parse(sessionStorage.getItem('EXPIRED'))) this.sessionMsg = 'Parece que su sesión ha expirado'
    //   this.modalSesion.open() 
    //   sessionStorage.clear()
    // }, 500)
  }

  errorCredenciales:boolean
  errorReset:boolean
  loading:boolean
  user:any
  siteKey:string = environment.captchaSiteKey
  recaptcha:string

  get f() { return this.loginForm.controls; }

  checkCaptcha (e) {
    if (environment.TESTING) {
      this.login(e)
    } else {
     // if (this.btnLoad) return
      
      //this.btnLoad = true
      this.captcha.execute()
      
    }
      
    
  }


  async login(e) {
  //  e.preventDefault()

    if (this.loading) return

    if (this.loginForm.invalid) {
      return this.formValidation.validateForm(this.loginForm)
    }

    this.loading = true

    // Pre Auth
    try {
      this.errorSubdominio = false
      await this.authService.preAuth(this.loginForm.get('user').value.trim(), this.subdominio,this.f.recaptcha.value)
    } catch (error) {
      this.errorSubdominio = true
      this.loading = false
      
      return
    }

    try {
      this.user = await Auth.signIn(this.loginForm.get('user').value.trim(),this.loginForm.get('password').value);
      this.authService.handleTokenExpiration();

      // Cambiar contraseña temporal
      if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.loading = false
        this.passModal.open()

      // Login
      } else {

        this.dologin()
      
      }

    } catch (err) {
      console.log(err)
      this.loading = false
      if (err.code == 'NotAuthorizedException' || err.code == 'UserNotFoundException') {
        this.errorCredenciales = true
      }
      if (err.code == 'NetworkError') {
        // this.errorConexion = true
      }
      if (err.code == 'PasswordResetRequiredException') {
        this.errorReset = true
      }
    }
  }

  reset() {
    //this.btnLoad = false
  }
  ready() {    
  }
  load() {
  }
  success(e) {
    this.loginForm.controls['recaptcha'].setValue(e)
    this.login(e)
  }
  expire() {
    //this.btnLoad = false
  }
  error() {
    //this.btnLoad = false
  }


  // Cambiar contraseña temporal
  pLoading:boolean
  async completarPass(e) {

    e.preventDefault()
    if (this.pLoading) return
    if (this.passForm.invalid) {
      
      return this.formValidation.validateForm(this.passForm)
    }

    this.pLoading = true

    try {

      this.user = await Auth.completeNewPassword(
      this.user,
      this.passForm.get('password').value)
      
      this.receptorService.actualizarFechaContraseña(this.loginForm.get('user').value.trim(), this.subdominio).subscribe(
        data => {
          console.log('FECHA CONTRASEÑA ACTUALIZADA')
        }
      );

      // return
      this.authService.handleTokenExpiration();
      this.dologin()

    } catch (err) {
      console.log(err)
      this.pLoading = false
    }

  }


  recovery() {
    this.recoveryModal.open()
  }

  rLoading:boolean
  errorNoVerificado:boolean
  errorNoExiste:boolean
  errorTemporal:boolean
  insertCode:boolean
  code:string

  // Recuperar contraseña
  async recover(e) {

    e.preventDefault()
    if (this.rLoading) return
    if (this.recoveryForm.invalid) {
      return this.formValidation.validateForm(this.recoveryForm)
    }

    this.rLoading = true
    // Pre Auth
    try {
      await this.authService.preRecover(this.recoveryForm.get('user').value.trim(), this.subdominio)
    } catch (error) {
      this.loading = false
      console.log(error)
      return
    }

    // Pre Auth
    // try {
    //   this.errorSubdominio = false
    //   await this.authService.preAuth(this.recoveryForm.get('user').value.trim(), this.subdominio, this.f.recaptcha.value)
    // } catch (error) {
    //   this.errorSubdominio = true
    //   this.rLoading = false
    //   console.log(error)
    //   return
    // }

    try {
      let data = await Auth.forgotPassword(this.recoveryForm.get('user').value.trim())
      this.codeForm.controls['user'].setValue(this.recoveryForm.get('user').value.trim())
      this.codeForm.updateValueAndValidity()
      this.insertCode = true
    } catch (err) {
      
      if (err.code == 'InvalidParameterException') {
        this.errorNoVerificado = true
      } else if (err.code == 'UserNotFoundException') {
        this.errorNoExiste = true
      } else if (err.code == 'NotAuthorizedException') {
        this.errorTemporal = true
        this.reestablecer()
      } else if (err.code === 'LimitExceededException') {
        this.maximoDeIntentos = true
      }
      if (!this.errorTemporal) this.rLoading = false
    }
    
  }

 

  correoUsuario:string = ''
  reestablecer() {

    this.usuarioService.RestablecerContrasena(
      this.recoveryForm.get('user').value.trim(), 
      this.subdominio).subscribe(data => {      
      this.correoUsuario = this.recoveryForm.get('user').value.trim()
      this.rLoading = false

      // this.notify.success('Operación exitosa')
      // this.loaders[loader] = false
      // this.buildBody()

    }, error => {
      console.log(error)
      this.rLoading = false
      // this.notify.error('Hubo un error inesperado')
      // this.loaders[loader] = false
    })
  }

  cLoading:boolean
  maximoDeIntentos:boolean
  errorCode:boolean
  // Establecer contraseña recuperada
  async submitCode(e) {

    e.preventDefault()
    if (this.cLoading) return
    if (this.codeForm.invalid) {
      
      return this.formValidation.validateForm(this.codeForm)
    }

    this.cLoading = true

    try {
        await Auth.forgotPasswordSubmit(
        this.codeForm.get('user').value,
        this.codeForm.get('code').value.trim(),
        this.codeForm.get('password').value)

      this.user = await Auth.signIn(this.codeForm.get('user').value.trim(), this.codeForm.get('password').value);
      this.authService.handleTokenExpiration();
      this.dologin()

    } catch (err) {
      console.log(err)
      if (err.code === 'CodeMismatchException') this.errorCode = true
      // else this.notify.error('Hubo un problema al procesar la solicitud')
      this.cLoading = false
    }

  }

  clearRecovery() {
    this.errorNoVerificado = false
    this.errorNoExiste = false
    this.errorTemporal = false
    this.maximoDeIntentos = false
    this.code = null
    this.rLoading = false
    this.insertCode = null
    this.recoveryForm.reset()
    this.codeForm.reset()
    // this.correoUsuario = null
    this.errorCode = false
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirm').value;
    return pass === confirmPass ? null : { mismatch: true }     
  } 

  async dologin() {

    const user = this.loginForm.get('user').value || this.codeForm.get('user').value.trim()
    const subdomains:string = this.user['attributes'] ? this.user['attributes']['custom:subdomain'] : this.user['challengeParam']['userAttributes']['custom:subdomain']
    const parseSubdomains = JSON.parse(subdomains)
    let subdomain = ""
    parseSubdomains.forEach(element => {
      if (element.name == this.subdominio){
        subdomain = element.name
      }
    });
    this.usuarioService.obtenerUsuario(user, subdomain).subscribe(data => {

      this.authService.guardarDatos(data)
      this.permisosService.setOpciones()

      this.empresaService.obtenerEmpresaActiva(this.authService.obtenerIdEmpresaActiva()).subscribe(data => {

        this.authService.guardarDataEmpresaActiva(data.find(c => c.id == this.authService.obtenerIdEmpresaActiva()))
        
        //this.router.navigate(['/'], {queryParams: {login:1}})
        this.router.navigate(['/'])
      }, error => {
        console.log(error)
        this.errorLogout()
      })

    }, error => {
      console.log(error)
      this.errorLogout()
    })
    
  }

  async errorLogout() {
    await Auth.signOut({ global: true });
    localStorage.clear()
    this.loading = false
  }
}
