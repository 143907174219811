import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';


type ControlType = 'text' | 'email' | 'number' | 'tel' | 'password' | 'select' | 'textarea' | 'checkbox' | 'file' | 'selectOnboarding' | 'selectOnboardingSize';
type Autocomplete = 'on' | 'off' | 'new-password';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  host: { class: 'app-input' }
})
export class InputComponent implements ControlValueAccessor, OnInit {

  @ViewChild('element') element: ElementRef;

  @Input('type') type:ControlType
  @Input('label') label:string
  @Input('initialValue') value:any
  @Input('required') required:boolean
  @Input('selectOptions') options:any[] = []
  @Input('selectOptionsKey') optionsKey:string
  @Input('selectOptionsName') optionsName:string
  @Input('disabledTextValue') disabledTextValue:string
  @Input('terms') terms:boolean
  @Input('readonly') readonly:boolean
  @Input('step') step:number
  @Input('no-margins') noMargins:boolean
  @Input('accept') accept:string
  @Input('mime-types') mimeTypes:string
  @Input() autocomplete: Autocomplete = 'off';
  @Input() placeholder = '';
  @Input('pattern') pattern:boolean


  @Output()
  change: EventEmitter<any> = new EventEmitter()
  @Output()
  keyup: EventEmitter<any> = new EventEmitter()

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl) {
      if (this.ngControl) { this.ngControl.valueAccessor = this }
  }

  onChangeCallback: any = () => {}
  onTouchedCallback: any = () => {}

  writeValue(value:any) {
    // if (this.type==='select') console.log('wv', value)
    this.value = value?.target ? value.target.value : value
    this.onChangeCallback(value)
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn
  }
  disabled:boolean
  setDisabledState(disabled:boolean) {
    this.disabled = disabled
  }

  selectTextValue:string
  ngOnInit(): void {
  }

  handleChange(e) {
    let val:any
    if (this.type === 'checkbox') val = e.target.checked
    else val = e.target.value

    this.writeValue(val)
    this.change.emit(val)
  }

  formatError:boolean
  file:any
  handleFileChange(e) {
    this.formatError = false
    let val:any = e.target.files[0]

    if (this.mimeTypes) {
      let valid:boolean = true
      const types:string[] = this.mimeTypes.split(',')
      types.forEach((t:string) => t = t.trim())
      valid = types.includes(val.type)

      if (!valid) {
        this.formatError = true
        return
      }
    }

    this.file = val
    this.writeValue(val)
    this.change.emit(val)
  }

  fileInputClick() {
    this.element.nativeElement.click()
  }

  onInput(event): void {
    const value = event.target.value;
    this.writeValue(value);
    this.change.emit(value);
  }
}
