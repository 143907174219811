import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { Proceso } from 'src/app/models/Proceso.model';
import { Observable } from 'rxjs';


type requestBody = {
  method: string
  data?: Proceso,
  id?: string,
  idCompany?: string
  subdomain?: string
}


@Injectable({
  providedIn: 'root'
})
export class ProcesoService {

  private urlProcesos:string = `${environment.apiCore}/process-type/`;

  constructor( private http: HttpClient ) { }

  listarProcesos(empresa:string):Observable<any> {
    const body:requestBody = { method: 'LIST', idCompany:empresa }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarProcesos2(empresa:string):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-TYPE', idCompany:empresa }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarProcesosSubdominio(subdomain:string):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-TYPE-SUBDOMAIN', subdomain:subdomain }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  /*listarTipoProcesosActivos(empresa:string):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-TYPE-ACTIVE', idCompany:empresa }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }*/

  listarTipoProcesosActivos(empresa:any, flujo:number):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-TYPE-ACTIVE',  data:{idCompany:empresa, flow:flujo} }
    return this.http.post(this.urlProcesos ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarProcesosActivos(empresa:string):Observable<any> {
    const body:requestBody = { method: 'LIST-ACTIVE', idCompany:empresa }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarProcesosOficina(empresa:string,user:string,subdomain:string):Observable<any> {
    const body:requestBody = { method: 'LIST-ACTIVE-FOR-OFFICE', data: {idCompany:empresa,user:user,subdomain:subdomain} }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarProcesosCompartidosActivos(subdomain:string):Observable<any> {
    const body:requestBody = { method: 'LIST-SHARED-ACTIVE', subdomain:subdomain }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  crearProceso(proceso:Proceso) {
    const body:requestBody = { method: 'POST', data: proceso }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerProceso(id:string):Observable<Proceso> {
    const body:requestBody = { method: 'GET', id: id }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarProceso(proceso:Proceso) {
    const body:requestBody = { method: 'PUT', data: proceso }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  deshabilitarProceso(id:string) {
    const body:requestBody = { method: 'DELETE', id:id }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  habilitarProceso(id:string) {
    const body:requestBody = { method: 'PATCH', id:id }
    return this.http.post(this.urlProcesos, body, { headers: { "x-api-key": environment.xApiKey } })
  }
}
