import { 
  Component, 
  OnInit, 
  Input, 
  Output, 
  ViewChild, 
  ElementRef, 
  HostListener, 
  EventEmitter, 
  OnChanges, 
  SimpleChanges
} from '@angular/core';

import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Storage } from 'aws-amplify';

import { FirmaHolograficaService } from 'src/app/services/firma-holografica.service';
import { S3ImageComponent } from '../s3-image/s3-image.component';
import { firma } from 'src/app/util/types';
import { ModalComponent } from '../modal/modal.component';
import { FirmaHolografica } from 'src/app/models/FirmaHolografica.model';
import { NotificationsService } from 'src/app/services/notifications.service';
import { AuthService } from 'src/app/services/auth.service';
import { FirmarPdfNuevoService } from './firmar-pdf-nuevo.service';
import { ServiciosProcesoService } from '../../services/servicios-proceso.service';
import { S3ImagePreviewComponent } from '../s3-image-preview/s3-image-preview.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ProcesoFirma } from '@noe/models/ProcesoFirma.model';
import { Subject, Subscription } from 'rxjs';
import { NgxCopilotService } from 'ngx-copilot';
import { ModalFelicitacionesComponent } from '../modal-felicitaciones/modal-felicitaciones.component';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { S3ImageMainComponent } from '../s3-image-main/s3-image-main.component';
import { ReceptorService } from '@noe/services/receptor.service';
import { UsuariosService } from '@noe/services/usuarios.service';

@Component({
  selector: 'app-firmar-pdf-nuevo',
  templateUrl: './firmar-pdf-nuevo.component.html',
  styleUrls: ['./firmar-pdf-nuevo.component.scss'],
  providers: [FirmarPdfNuevoService]
})
export class FirmarPdfNuevoComponent implements OnChanges, OnInit {

  @ViewChild('signature') pad:SignaturePadComponent
  @ViewChild('viewer') viewer: ElementRef
  @ViewChild('current') current: S3ImagePreviewComponent
  //@ViewChild('main') main: S3ImageMainComponent
  @ViewChild('modalDibujar') modalDibujar:ModalComponent
  @ViewChild('modalFelicitaciones') modalFelicitaciones:ModalFelicitacionesComponent
  @ViewChild('modalCargar') modalCargar:ModalComponent
  @ViewChild('modalTerminos') modalTerminos:ModalComponent
  @ViewChild('modalCambiarmeCuentaPago') modalCambiarmeCuentaPago:ModalComponent
  @ViewChild('overlayCanvas') canvasElement: ElementRef<HTMLCanvasElement>;
  @ViewChild('modalCerrarDocumento') modalCerrarDocumento:ModalComponent;

  @ViewChild('currentPage') inputSearch;
  @ViewChild('tabGroup') tabGroup;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  subscriptions = new Subscription();
  scrollToIndex$: Subject<void> = new Subject();

  @Input('masivo') set masivo (valor: boolean) {
    this._masivo = valor
  }
  pdfList:any[] = []
  @Input('pdfList') set getPdfList (lista: any) {
    this.pdfList = [...lista];

    if (this._masivo) {
      this.initFirmasMasivo();
    }
  }
  @Input('files') files:string[] = [];
  @Input('filesThumbs') filesThumbs:string[] = [];
  @Input('loading') loading:boolean;
  @Input('currentDoc') currentDoc: any;
  @Output() 
  onconfirm: EventEmitter<any> = new EventEmitter()
  @Output() onCheckTodosPaginas: EventEmitter<boolean> = new EventEmitter()
  @Input() childMessage: any;

  masivoCurrent: number = 0;
  _masivo:boolean;
  // only for single doument.
  isSignedDoc: boolean = false;
  esGratis:boolean
  @Input('loading') loadingScroll:boolean = false;
  @Input('loading') loadingScrollThumb:boolean = false;

  public albumdetails = [];
  currentFirmar:any
  proceso:ProcesoFirma | any
  cargaId:string
  currentServ:any
  imagenesPdf = [];
  items = [];
  itemsThumbs = [];
  selectFirma: boolean = false;
  selectFirmaSize: boolean = false;
  posicionFirma: boolean = false;
  textoFirmar: boolean = false;
  firma: boolean = false;
  fondoPosicionFirmar: boolean = false;
  selected: any;
  selectedTabs = new FormControl(0);

  numberPage:number = 0
  pageActive:number = 1
  cantidadPaginas: number = 0;
  nombreDocumento: string;
  loaderFirmar: boolean = false;
  verUbicarFirma: boolean = false;
  verFirmaIndividual: boolean = false;
  flagOnboarding: number;
  rotateLoader: boolean
  agregarLoader: boolean

  constructor(
    public firmaHolograficaService: FirmaHolograficaService,
    public notify: NotificationsService,
    public authService: AuthService,
    private signService: FirmarPdfNuevoService,
    public serviciosProcesoService: ServiciosProcesoService,
    private copilot: NgxCopilotService,
    public router: Router,
    public service: ReceptorService,
    private userService: UsuariosService
  ) { 

  }

  draggingCorner: boolean
  draggingWindow: boolean

  ngOnChanges(change: SimpleChanges): void {  
    const { masivo, currentDoc } = change;
    if (masivo) {
      this.masivo = masivo.currentValue;
      this.signService.buildCheckboxesStatus(this.pdfList.length);
      this.signService.buildSignaturesStatus(this.pdfList.length);
      
      console.log('ngOnChanges : pdfList',this.pdfList)
      console.log('ngOnChanges : pdfList',this.pdfList[0])
    }

    if (!!currentDoc && currentDoc?.currentValue) {
      const { signedStatusDocument, statusSignatureExternal } = currentDoc.currentValue;
      this.isSignedDoc = !!signedStatusDocument && !!this.authService.obtenerEstadoActivarTimbrado() && !statusSignatureExternal;
    }

  }

  ngOnInit(): void {
    this.listarFirmasHolograficas();
    this.esGratis = this.authService.obtenerTipoCuenta() === 'GRATUITA'
    console.log('pdfList', this.pdfList)
    //this.esGratis = true
    //this.cargarImagenesInit();
  }

  modificarOnboarding() {
    this.service.ModificarOnboarding(this.authService.obtenerUsername(), this.authService.obtenerSubdominio()).subscribe(data => {
      console.log(data,'modificarOnboarding');
    }, error => {
      console.log(error)
    })
  }
  
  estadoOnboarding() {
    this.userService.obtenerUsuario(
      this.authService.obtenerUsername(), 
      this.authService.obtenerSubdominio())
      .subscribe(data => {
      this.flagOnboarding = data.flagOnboarding
      if (this.firmasUsuario.length > 0) {
        if (this.flagOnboarding == 0) {
          this.init();
        }
      }
      console.log(data.flagOnboarding, 'estadoOnboarding') 
    }, error => {
      console.log(error)
    }); 
  }

  verBloqueoFirma() {
    this.verUbicarFirma = true;
    console.log(this.verUbicarFirma, 'verBloqueoFirma');
  }

  removeTabSelected:number
  modalremoveTab(i){
    if(this.pdfList.length > 1){
      this.removeTabSelected = i
      this.modalCerrarDocumento.open();
      console.log('removeTabSelected modalremoveTab',this.removeTabSelected)
      console.log('i',i)
      //this.pdfList.splice(i, 1);
    }
  }
  removeTab(){
    this.pdfList.splice(this.removeTabSelected, 1);
    this.firmasMasivo.splice(this.removeTabSelected, 1);
    //this.firmasMasivo[this.removeTabSelected] = [] //FALTA ELIMINAR LA FIRMA SI SE INCRUSTO
    this.masivoCurrent = this.removeTabSelected
    if(this.masivoCurrent == 0 ){
      this.navegarMasivo('next')
    }else{
      this.navegarMasivo('prev')
    }
    console.log('removeTabSelected removeTab',this.removeTabSelected)
    console.log('masivoCurrent removeTab',this.masivoCurrent)
    console.log('firmasMasivo removeTab',this.firmasMasivo)
    this.modalCerrarDocumento.close();
    
  }

  scrollEnd() {
    this.subscriptions.add(
      this.scrollToIndex$
        .pipe(delay(0))
        .subscribe(() =>
          this.viewport.scrollTo({ bottom: 0, behavior: 'smooth' })
        )
    );
    this.scrollToIndex$.next();
  }

  scrollStart() {
    this.subscriptions.add(
      this.scrollToIndex$
        .pipe(delay(0))
        .subscribe(() =>
          this.viewport.scrollTo({ top: 0, behavior: 'smooth' })
        )
    );
    this.scrollToIndex$.next();
  }

  select(item, $event) {
    this.selected = (this.selected === item ? null : item);
    $event ? $event.stopPropagation() : null;
  }

  isActive(item) {
    return this.selected === item;
  }

  selectMasivo(item, $event) {
    this.selected = (this.selected === item ? null : item);
    $event ? $event.stopPropagation() : null;
  }
  
  isActiveMasivo(item) {
    return this.selected === item;
  }

  selectMasivoMenu(item, $event) {
    this.selected = (this.selected === item ? null : item);
    $event ? $event.stopPropagation() : null;
  }

  isActiveMasivoMenu(item) {
    return this.selected === item;
  }

  selectIndividualMenu(item, $event) {
    this.selected = (this.selected === item ? null : item);
    $event ? $event.stopPropagation() : null;
  }
  
  isActiveIndividualMenu(item) {
    return this.selected === item;
  }

  selectBoton(item, $event) {
    this.selected = (this.selected === item ? null : item);
    $event ? $event.stopPropagation() : null;
  }

  isActiveBoton(item) {
    return this.selected === item;
  }

  verSelectFirma() {
    this.selectFirma = true;
  }

  sinSelectFirma() {
    this.selectFirma = false;
  }

  verSelectFirmaSize() {
    this.selectFirmaSize = true;
  }

  sinSelectFirmaSize() {
    this.selectFirmaSize = false;
  }

  verTextoFirma() {
    this.textoFirmar = true;
  }

  sinTextoFirma() {
    this.textoFirmar = false;
  }

  verFondoPosicionFirmar() {
    this.fondoPosicionFirmar = true;
  }

  sinFondoPosicionFirmar() {
    this.fondoPosicionFirmar = false;
  }

  verPosicionFirma() {
    this.posicionFirma = true;
  }

  sinPosicionFirma() {
    this.posicionFirma = false;
  }

  verFirma() {
    this.firma = true;
  }

  sinFirma() {
    this.firma = false;
  }


  /*Re initialize in specify step*/
  initPosition(stepNumber: any) { 
    this.copilot.checkInit(stepNumber);
  } 

  /*Initialize*/
  init() {
    this.viewport.scrollToIndex(100, 'smooth');
    setTimeout(() => {
      this.copilot.checkInit();
      this.selectFirma = true;
    }, 1500)
    this.textoFirmar = false;
    this.fondoPosicionFirmar = true;
    this.selectFirmaSize = false;
    this.posicionFirma = false;
    this.firma = false;
  } 

  /*Next Step*/
  siguiente(stepNumber: any) { 
    this.copilot.next(stepNumber);
  } 
  
  /*Finish*/
  omitir() {
    this.copilot.removeWrapper();
    this.textoFirmar = false;
    this.fondoPosicionFirmar = false;
    this.selectFirma = false;
    this.selectFirmaSize = false;
    this.posicionFirma = false;
    this.firma = false;
    //this.modificarOnboarding();
  } 

  handlerPrev(){
    console.log("this.pdfList",this.pdfList, this.notEmptyPost)
    if (this.notEmptyPost && !this._masivo) {
      this.loadingScrollThumb = true;
      this.getImages(this.items.length);
    }

    if(this.pdfList.length > 0){
      if (this.pdfList[this.masivoCurrent].notEmptyPost && this._masivo) {
        console.log("handlerPrev MASIVO")
        this.loadingScrollThumb = true;
        this.getImages(this.pdfList[this.masivoCurrent].imagenes.length);
      }
    }
  }

  cargarImagenesMasiveInit(){
    this.loaderFirmar = false;
    this.estadoOnboarding();
    this.verUbicarFirma = false;
    this.verFirmaIndividual = false;
    console.log("%%%%%%%%%%%%%",this.pdfList)
    this.loadingScroll = true;
    this.loadingScrollThumb = true;
    this.items = []
    if (this._masivo) {
      this.items = []
      this.pdfList[0].imagenes = []
    }
    this.getImages(0); 

    for (let index = 1; index < this.pdfList.length; index++) {
      const element = this.pdfList[index];
      this.nextLoadDocuments(element,index)
    }
  }

  nextLoadDocuments(doc:any,index:number){
    const bodyPages:any = {
      idProcess: doc.idProcess,
      filePath: doc.filePath,
      idDocumentSigningProcess: doc.id,
      idCompany: this.authService.obtenerIdEmpresaActiva(),
      count_page: 0
    }
    this.serviciosProcesoService.convertirPDFImagePagination(bodyPages).subscribe((data) => {
      this.pdfList[index].imagenes = data['paginas'];
      this.pdfList[index].cantidadPaginas = data['cantidadPaginas'];
      this.pdfList[index].nombreDocumento = data['nombreDocumento'];
      this.firmasMasivo[index] = []
    }, error => {
      console.log(error)
    })
  }

  cargarImagenesInit(){ 
    this.loaderFirmar = false;
    this.estadoOnboarding();
    this.verUbicarFirma = false;
    this.verFirmaIndividual = false;
    console.log("##############",this.items)
    console.log("%%%%%%%%%%%%%",this.pdfList)
    this.loadingScroll = true;
    this.loadingScrollThumb = true;
    this.items = []
    this.pdfList = []
    this.getImages(0); 
  }

  handler(){
    console.log("this.pdfList",this.pdfList, this.notEmptyPost)
    if (this.notEmptyPost && !this._masivo) {
      this.loadingScroll = true;
      this.getImages(this.items.length);
    }

    if (this.pdfList.length > 0){
      if (this.pdfList[this.masivoCurrent].notEmptyPost && this._masivo) {
        console.log("handler MASIVO")
        this.loadingScroll = true;
        this.getImages(this.pdfList[this.masivoCurrent].imagenes.length);
      }
    }
  }

  notEmptyPost:boolean = true;
  getImages(total:number){
    console.log('childMessage getImages',this.childMessage)
    const bodyPages:any = {
      idProcess: this.childMessage.idProcess,
      filePath: this.childMessage.filePath,
      idDocumentSigningProcess: this.pdfList.length>0 ?  this.pdfList[this.masivoCurrent].id : this.childMessage.idDocumentSigningProcess,
      idCompany: this.childMessage.idCompany,
      count_page: total
    }
    console.log(bodyPages, 'ENVIO PAGINAS SCROLL getImages')
    this.serviciosProcesoService.convertirPDFImagePagination(bodyPages).subscribe((data) => {
      console.log("&&&&&&&&&&&&&&&&&&&&&&&&&",data)
      this.notEmptyPost = data['paginas'].length === 0 ? false : true;
      this.cantidadPaginas = data['cantidadPaginas']
      this.nombreDocumento = data['nombreDocumento']
      
      this.items = [
        ...this.items,
        ...data['paginas'].map((value, i) => {
          return value
        })
      ];
      console.log(this.masivoCurrent,this.pdfList)
      //console.log(this.pdfList[this.masivoCurrent])
      if(this.pdfList.length > 0){
        this.pdfList[this.masivoCurrent].cantidadPaginas = data['cantidadPaginas'];
        this.pdfList[this.masivoCurrent].imagenes = [
            ...this.pdfList[this.masivoCurrent].imagenes, 
            ...data['paginas'].map((value, i) => {
            return value
          })
        ]
        this.pdfList[this.masivoCurrent].notEmptyPost = data['paginas'].length === 0 ? false : true;
      }
      
      this.loadingScroll = false;
      this.loadingScrollThumb = false;
      //console.log(this.items,'CARGANDO DATA NUEVA');
      if (this.numberPage == 0) {
        this.getMasiveImagens()
      }
    }, error => {
      console.log(error)
    })
  }

  countPage() {
    for (let i = 3; i <= 20; i += 4) {
      console.log(i, 'Valor');
    }
  }

  checkFirmarTodas:boolean

  mensaje: string = "Firmar";
    saludo(value) {
    this.mensaje = value;
  }

  mensajeDocumentos: string = "Documentos";
    saludoDocumentos(value) {
    this.mensajeDocumentos = value;
  }

  mensajeCheck: string = "Check";
    saludoCheck(value) {
    this.mensajeCheck = value;
  }

  initFirmasMasivo() {
    this.firmasMasivo.length = this.pdfList.length;
    this.firmasMasivo.forEach(f => {
      f = []; 
      f.forEach(p => {
        p = [];
      });
    });
  }

  
  //christian anchivilca
  comboPersonalizacionSelect:any 
  idNameCombo:string
  idKeyCombo:string
  verNumeroPaginas :boolean=false
  txtNumeroPaginas:string
  tipos:any[] = [
    'Todos',
    //'Personalizado'
  ]

  options = [
    
    { name: "Todos", value: 1 },
    //{ name: "Personalizado", value: 2 }
  ]

  getPersonalizar(){
    //this.comboPersonalizacionSelect = this.tipos[this.tipos.length - 1]
    if(this.comboPersonalizacionSelect === 'Personalizado'){
      this.verNumeroPaginas = true
    }else{
      this.verNumeroPaginas = false
    }
  }

  firmaSeleccionada:any
  
  firmasUsuario:any[] = []
  loadingFirmas:boolean
  listarFirmasHolograficas() {
  
    this.loadingFirmas = true
    this.firmaHolograficaService.listarFirmas(
      this.authService.obtenerIdEmpresaActiva(),
      this.authService.obtenerUsername()
      ).subscribe(data => {
      this.firmasUsuario = [...data]
      this.firmaSeleccionada = this.firmasUsuario[this.firmasUsuario.length - 1]?.route
      this.loadingFirmas = false;
    }, error => {
      console.log(error)
      this.loadingFirmas = false
    })
  }


  iterar:number[] = [0,1]

  hayFirmas: boolean = false;
  firmas:firma[][] = []
  firmasMasivo:firma[][][] = [[]]
  indexMasivo:number  

  accept:boolean
  recibeAccept(e) {
    this.accept = e;
  }

  addFirma(e: MouseEvent, index: number, masivoIndex: number = null) {
    //console.log('hayFirmas',this.hayFirmas)
    //console.log('index addFirma',index)
    //console.log('masivoIndex addFirma',masivoIndex)
    const target: HTMLImageElement = e.target as HTMLImageElement;

    this.recibeAccept(e);
    if (!this._masivo && this.isStamped(index)) {
      return;
    }
    if (this.draggingWindow || this.draggingCorner || !this.firmaSeleccionada) {
      return;
    }
    if (this._masivo) {
      if (this.pdfList[index].signed) {
        return;
      }
    }
    if (this.hayFirmas) {
      return;
    }

    const firma = {
      key: this.current.key,
      keyOrigen: this.current.key,
      src: this.current.el.nativeElement.src,
      x: e.offsetX,
      y: e.offsetY,
      height: this.current.el.nativeElement.height,
      width: this.current.el.nativeElement.width,
      minArea: e.offsetY * e.offsetX,
      docClientHeight: target.clientHeight,
      docClientWidth: target.clientWidth,
      docNaturalHeight: target.naturalHeight,
      docNaturalWidth: target.naturalWidth,
      page: (masivoIndex != null && masivoIndex >=0 ? masivoIndex + 1 : index + 1).toString()
    }
    //console.log('index ', index)
    //console.log('masivoIndex ', masivoIndex)
    console.log('FIRMA ADD ', firma)

    if (this._masivo) {
      if (!this.firmasMasivo[index][masivoIndex]) this.firmasMasivo[index][masivoIndex] = []
      this.firmasMasivo[index][masivoIndex].push(firma);
      this.pdfList[index].signed = true

      this.signService.signStatusList[this.masivoCurrent] = true;
    } else {
      if (!this.firmas[index]) this.firmas[index] = []
      this.firmas[index].push(firma);
    }

    this.hayFirmas = true;
    console.log('firmasMasivo', this.firmasMasivo)
    this.validarFirmasCompletas()
  }

  firmasCompletas: boolean = false
  documentosFirmados:number = 0
  validarFirmasCompletas(){
    //Validando firmasCompletas
    var lstSigned = []
    for(let item of this.pdfList){
      if(item.signed == true){
        lstSigned.push(item)
      }
    }

    if (lstSigned.length === this.pdfList.length){
      this.firmasCompletas = true;
    }else{
      this.firmasCompletas = false;
    }
    this.documentosFirmados = lstSigned.length; // actualizando contados de doc firmados
    //console.log('lstSigned',lstSigned)
    //console.log('pdfList',this.pdfList)
    console.log('Firmas Completas', this.firmasCompletas)
  }

  remove(doc: number, firma: number, masivoFirma: number = null) {
    if (this._masivo) {
      this.firmasMasivo[doc][firma].splice(masivoFirma, 1);
      this.signService.signStatusList[this.masivoCurrent] = false;

      this.pdfList[doc].signed = false
    } else {
      this.firmas[doc].splice(firma, 1);
    }
    this.hayFirmas = false;
    console.log('pdfList',this.pdfList)
    this.validarFirmasCompletas();
    this.verUbicarFirma = false;
    this.verFirmaIndividual = false;
  }

  async obtenerFirmaRotadaAndIncrustarla(key:any,doc:number,firma:number){
    const src:any = await Storage.get(key, {customPrefix: {public: ''}})
    console.log(this.firmasMasivo)
    try {
      if(this._masivo){
        this.firmasMasivo[doc][firma][0].key = key;
        this.firmasMasivo[doc][firma][0].src = src;
        
        var width = this.firmasMasivo[doc][firma][0].width;
        var height = this.firmasMasivo[doc][firma][0].height;
        this.firmasMasivo[doc][firma][0].width = height;
        this.firmasMasivo[doc][firma][0].height = width;
      }else{
        this.firmas[doc][0].key = key;
        this.firmas[doc][0].src = src;

        var width = this.firmas[doc][0].width;
        var height = this.firmas[doc][0].height;
        this.firmas[doc][0].width = height;
        this.firmas[doc][0].height = width;
      }
      
    } catch (error) {
      console.log(error)
      this.notify.info('Hubo un problema al rotar su imagen')
    }
  }

  dataFirmaRotate: FirmaHolografica
  async rotate(doc: number, firma: number, side: string, masivoFirma: number = null) {
    //Validando angulo
    var addAngle = side == 'right' ? 0 - 90 :  0 + 90;
    //Ruta para firma
    var firmaRotate = this._masivo ? this.firmasMasivo[doc][firma][0] : firmaRotate = this.firmas[doc][0]

    var routeArray = firmaRotate.key.split('/')
    var routeRotate = routeArray[0] + '/' + routeArray[1] + '/' + routeArray[2] + '/' + routeArray[3] + '/' + routeArray[4] + '/'

    this.dataFirmaRotate = {
      route: firmaRotate.key,
      routeRotate: routeRotate,
      angle: addAngle
    }

    this.rotateLoader = true;

    this.firmaHolograficaService.rotateFirma(this.dataFirmaRotate).subscribe( 
      data => {
        this.obtenerFirmaRotadaAndIncrustarla(data['routeRotate'],doc,firma)
        this.rotateLoader = false;
      }
    )
    console.log('firmaRotate', this.dataFirmaRotate)
    console.log('this.firmasMasivo', this.firmasMasivo)
  }

  isMassiveStamped(document: any, indexImage: number) {
    const { imagenes, signedStatusDocument } = document;

    return !!signedStatusDocument && indexImage === imagenes.length - 1;
  }

  isStamped(indexPage: number): boolean {
    const isLastPage = indexPage === this.items.length - 1;
    return isLastPage && this.isSignedDoc;
  }

  /*accept:boolean
  handleAccept() {
    if (this.accept) {
      this.accept = false
    } else {
      this.modalTerminos.open()
    }
  }*/

  checkPaginas : boolean = false
  handleCheck() {


    if(this.esGratis){

      this.modalCambiarmeCuentaPago.open()

    }else{

      if (this.checkPaginas) {
        this.checkPaginas = false
        this.verNumeroPaginas = false
      } else {
  
    
        this.checkPaginas = true
        if(this.comboPersonalizacionSelect === 'Personalizado'){
          this.verNumeroPaginas = true
    
        }else{
          this.verNumeroPaginas = false
        }
      }
  
      if (this._masivo) {
        this.signService.checkStatusList[this.masivoCurrent] = this.checkPaginas;
      }
      
      this.onCheckTodosPaginas.emit(this.checkPaginas);

    }
 
  }

  checkPaginasDetalle : boolean = false
  handleCheckDetalle() {


    if(this.esGratis){

      this.modalCambiarmeCuentaPago.open()

    }else{

      if (this.checkPaginasDetalle) {
        this.checkPaginasDetalle = false
        this.verNumeroPaginas = false
      } else {
  
    
        this.checkPaginasDetalle = true
        if(this.comboPersonalizacionSelect === 'Personalizado'){
          this.verNumeroPaginas = true
    
        }else{
          this.verNumeroPaginas = false
        }
      }
  
      if (this._masivo) {
        this.signService.checkStatusList[this.masivoCurrent] = this.checkPaginasDetalle;
      }
      
      this.onCheckTodosPaginas.emit(this.checkPaginasDetalle);

    }
 
  }

  modalCambiarmeCuentaPagoLoading:boolean
  aceptarCambiarmeCuentaPago(){
    this.modalCambiarmeCuentaPagoLoading = true
    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio()
     
   
    }
    this.serviciosProcesoService.enviarCorreoContactarme(data).subscribe(data => {
     // console.log(""data)
      this.notify.success("Se envió la solicitud correctamente.")
      this.modalCambiarmeCuentaPagoLoading = false
      this.modalCambiarmeCuentaPago.close()
      //this.modalContactarCuentaFreeLoading = false
    }, error => {
      this.notify.error('Ocurrio un error inesperado')
      this.modalCambiarmeCuentaPagoLoading = false
      this.modalCambiarmeCuentaPago.close()
      //this.modalContactarCuentaFreeLoading = false
    })

  }

  enviar() {
    if (this._masivo) {
      this.setSignMassiveWithChecks();
      this.obtenerPaginasDocumento(this.docActive,this.masivoCurrent) //Manteniendo documento actual al firmar
    }
    console.log('######### ENVIANDO FIRMAS MASIVO')
    console.log(this.firmasMasivo)
    console.log(this.pdfList)
    console.log(this.firmasCompletas)
    this.firmasCompletas = false;
    this.hayFirmas = false;
    this.onconfirm.emit(this._masivo ? this.firmasMasivo : this.firmas);
  }

  /**
   * Set massive signature with checkAllPages property.
   */
  setSignMassiveWithChecks(): void {
    const checkList = this.signService.checkStatusList;

    this.firmasMasivo = this.firmasMasivo.map((pdfs: firma[][], index) => {
      return pdfs.map((pdfArr) => {
        return pdfArr.map((pdf) => {
          return {
            ...pdf,
            checkAllPages: checkList[index]
          };
        });
      });
    });
  }

  // DRAG & RESIZE

  /*tooltipMove(event: MouseEvent) {
      var x = event.clientX;
      var y = event.clientY;
      document.getElementById("tooltip").style.left = (x - 630) + "px";
      document.getElementById("tooltip").style.top = (y - 280) + "px";
  }*/

  /*tooltipMoveMasivo(event: MouseEvent) {
      var x = event.clientX;
      var y = event.clientY;
      document.getElementById("tooltip").style.left = (x - 630) + "px";
      document.getElementById("tooltip").style.top = (y - 330) + "px";
  }*/

  area(f:firma) {
    return f.width * f.height;
  }

  currentFirma:firma = {}
  onWindowPress(event: MouseEvent, f:firma) {
    this.currentFirma = f
    this.draggingWindow = true;
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
  }

  onWindowDrag(event: MouseEvent) {
    if (!this.draggingWindow) {
      return;
    }
    let offsetX = event.clientX - this.currentFirma.px;
    let offsetY = event.clientY - this.currentFirma.py;

    this.currentFirma.x += offsetX;
    this.currentFirma.y += offsetY;
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
  }

  topLeftResize(offsetX: number, offsetY: number) {
    this.currentFirma.x += offsetX;
    this.currentFirma.y += offsetY;
    this.currentFirma.width -= offsetX;
    this.currentFirma.height -= offsetY;
  }

  topRightResize(offsetX: number, offsetY: number) {
    this.currentFirma.y += offsetY;
    this.currentFirma.width += offsetX;
    this.currentFirma.height -= offsetY;
  }

  bottomLeftResize(offsetX: number, offsetY: number) {
    this.currentFirma.x += offsetX;
    this.currentFirma.width -= offsetX;
    this.currentFirma.height += offsetY;
  }

  bottomRightResize(offsetX: number, offsetY: number) {
    this.currentFirma.width += offsetX;
    this.currentFirma.height += offsetY;
  }

  onCornerClick(event: MouseEvent, f:firma, resizer?:string) {
    this.currentFirma = f
    this.draggingCorner = true;
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
    this.currentFirma.resizer = resizer;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:mousemove', ['$event'])

  onCornerMove(event: MouseEvent) {
    if (!this.draggingCorner) {
      return;
    }

    let offsetX = event.clientX - this.currentFirma.px;
    let offsetY = event.clientY - this.currentFirma.py;

    let lastX = this.currentFirma.x;
    let lastY = this.currentFirma.y;
    let pWidth = this.currentFirma.width;
    let pHeight = this.currentFirma.height;

    this[this.currentFirma.resizer](offsetX, offsetY);
    if (this.area(this.currentFirma) < 2000) {
      this.currentFirma.x = lastX;
      this.currentFirma.y = lastY;
      this.currentFirma.width = pWidth;
      this.currentFirma.height = pHeight;
    }
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease(event: MouseEvent) {
    this.draggingWindow = false;
    this.draggingCorner = false;
  }




  preview:string
  setPreview(base:string) {
    this.preview = base
  }

  title:string
  titleDraw:string
  titleUpload:string
  titleWrite:string
  descriptionDraw:string
  descriptionWrite:string
  descriptionUpload:string
  signWrite:string

  description:string
  baseFirma:any
  blob:any
  currentFirmaNueva:any

  tab:number


  tabs(id:number){
    this.tab = id
    console.log('pintame el tabs',this.tab)
  }

  save() {
    console.log("pintame imagen",this.blob)
    this.pad.getCanvas().toBlob((blob) => {this.blob = blob}, 'image/jpg', 0.95)
    this.baseFirma = this.pad.toDataURL()
    console.log("pintame imagen 2",this.baseFirma)
  }

  clear() {
    if (this.tab == 1) {
      this.pad.clear()
      this.blob = null
      this.baseFirma  = false
      console.log('pintame el tabs',this.tab)
    }else if (this.tab == 2) {
      this.signWrite = null
      console.log('pintame el tabs',this.tab)
    }else if (this.tab == 3) {
      this.preview = null
      this.blob = null
      console.log('pintame el tabs',this.tab)
    }
  }

  cargarLoadingFirma:boolean
  async cargarFirma() {

    //Validacion de campos para carga de firma
    let validation = this.validarCargarFirma()
    if (!validation){
      this.notify.info('Completar los datos para cargar la firma.')
      return;
    }

    this.cargarLoadingFirma = true
    const timestamp:number = new Date().getTime()
    const key:string = `${this.authService.obtenerRucEmpresaActiva()}/usuarios/${this.authService.obtenerDocumento()}/firmas_holografas/${timestamp}.jpg`
    const ruta:string = `public/${key}`

    if (this.tab != 2){
      if (this.blob.size > 50000){
        this.notify.info('Por favor ingrese otra imagen de menor peso. Verificar la indicaciones dadas.')
        this.cargarLoadingFirma = false
        return
      }
  
      try {
        await Storage.put(key, this.blob)
      } catch (error) {
        this.notify.error('Hubo un problema al cargar la imagen')
      }
    }

    if (this.tab == 1) {
      this.title = this.titleDraw
      this.description = this.descriptionDraw
      //console.log('title', this.title)
      //console.log('description', this.description)
    } else if (this.tab == 2) {
      this.title = this.titleWrite
      this.description = this.descriptionWrite
      //console.log('title', this.title)
      //console.log('description', this.description)
    } else if (this.tab == 3) {
      this.title = this.titleUpload
      this.description = this.descriptionUpload
      //console.log('title', this.title)
      //console.log('description', this.description)
    }

    const firma:FirmaHolografica = {
      title: this.title,
      description: this.description,
      route: ruta,
      user: this.authService.obtenerUsername(),
      idCompany: this.authService.obtenerIdEmpresaActiva(),
      textoFirma: this.signWrite //Añadido para convertir texto en imagen
    }
    console.log('Firma enviada', firma)

    this.agregarLoader = true;
    
    this.firmaHolograficaService.crearFirma(firma).subscribe(data => {
      this.listarFirmasHolograficas()
      setTimeout(() => {
        console.log('this.masivoCurrent cargarFirma', this.masivoCurrent)
        this.agregarLoader = false;
        this.modalCargar.close()
        this.modalDibujar.close()
        this.notify.success('Operación exitosa')
        this.reset()
        //this.obtenerPaginasDocumento(this.pdfList[this.masivoCurrent],this.masivoCurrent)
      }, 800)
    }, error => {
      this.cargarLoadingFirma = false
      this.notify.error('Hubo un problema al crear su firma')
    })
  }

  validarCargarFirma():boolean{
    if(this.tab == 1 && this.blob != undefined && this.titleDraw != undefined && this.blob != null
       && this.titleDraw != null && this.titleDraw != ''){
      return true;
    }else if(this.tab == 2 && this.signWrite != undefined && this.titleWrite != undefined 
      && this.signWrite != null && this.titleWrite != null  && this.titleWrite != ''){
      return true;
    }else if(this.tab == 3 && this.preview != undefined && this.titleUpload != undefined 
      && this.preview != null && this.titleUpload != null && this.titleUpload != ''){
      return true;
    }else{
      return false;
    }
  }

  reset() {
    this.preview = null
    this.blob = null
    this.baseFirma = null
    this.cargarLoadingFirma = false
    this.title = null
    this.description = null
    this.currentFirmaNueva = null
    
    this.titleDraw = null
    this.titleWrite = null
    this.titleUpload = null

    this.descriptionDraw = null
    this.descriptionWrite = null
    this.descriptionUpload = null

    this.signWrite = null
    this.numberPage = 0
    this.pageActive = 1
    this.pad.clear()
  }

  documentListShown:boolean = false

  navegarMasivo(action:string) {
    
    if (action === 'prev') {
      if (this.masivoCurrent > 0) {
        if (!this.firmasMasivo[this.masivoCurrent - 1]) this.firmasMasivo[this.masivoCurrent - 1] = [[]]
        this.masivoCurrent--;
      }

    } else {
      if (this.masivoCurrent < this.pdfList.length - 1) {
        if (!this.firmasMasivo[this.masivoCurrent + 1]) this.firmasMasivo[this.masivoCurrent + 1] = [[]]
        this.masivoCurrent++;
      }
    }
    this.tabGroup.selectedIndex =  this.masivoCurrent
    this.checkPaginas = this.signService.checkStatusList[this.masivoCurrent];
    this.hayFirmas = this.signService.signStatusList[this.masivoCurrent];
    this.obtenerPaginasDocumento(this.pdfList[this.masivoCurrent],this.masivoCurrent)
  }

  /*numPag: number = 0;
  navegarMasivo(action:string) {
    this.inputSearch.nativeElement.value = ' ';
    //console.log(this.inputSearch.nativeElement.value);

    if (action === 'prev') {
      if (this.masivoCurrent > 0) {
        if (!this.firmasMasivo[this.masivoCurrent - 1]) this.firmasMasivo[this.masivoCurrent - 1] = [[]]
        this.masivoCurrent--;
      }
      this.numPaginas();
    } else {
      if (this.masivoCurrent < this.pdfList.length - 1) {
        if (!this.firmasMasivo[this.masivoCurrent + 1]) this.firmasMasivo[this.masivoCurrent + 1] = [[]]
        this.masivoCurrent++;
      }
       console.log(this.masivoCurrent++);
       this.numPaginas();
    }

    this.checkPaginas = this.signService.checkStatusList[this.masivoCurrent];
    this.hayFirmas = this.signService.signStatusList[this.masivoCurrent];
  }

  vnumPag: number
  sendValue(inputValue) {
    this.vnumPag = inputValue
    console.log(this.vnumPag);

    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio(),
      numPag: this.vnumPag
    }

    this.envioNumPag(data);
  }
  
  numPaginas(){
    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio(),
      numPag: this.masivoCurrent + 1
    }
    
    this.envioNumPag(data);
  }

  envioNumPag(data){
    this.serviciosProcesoService.enviarCorreoContactarme(data).subscribe(data => {
      //this.notify.success("Se envió la solicitud correctamente.")
      if (this.vnumPag == 0 || this.vnumPag > 4) {
        this.notify.error("No hay ninguna página con el número " + "'" + this.vnumPag + "'" + " en este documento.")
      } 
    }, error => {
      //this.notify.error('Ocurrio un error inesperado')
    })

    console.log("DATA",data)
  }*/

  restart() {
    setTimeout(() => {
      this.accept = false
      this.hayFirmas = false
      //this.checkPaginas=false
      this.firmas = []
      this.firmasMasivo = [[]]
      this.masivoCurrent = 0
    }, 800)
    this.reset()
  }

  scaleZoom = 1
  zoomPorcentaje = 100
  zoomPlus(){
    if (this.zoomPorcentaje >= 200)
    return;
    //console.log('EVENTO ZOOM PLUS ')
    this.scaleZoom += 0.1;
    this.zoomPorcentaje += 10;
    let zoom = document.getElementById("document-container-signature");
    zoom.style.transform = "scale(" + this.scaleZoom + ")";
    zoom.style.transformOrigin = "top"
  }

  zoomMinus(){
    if (this.zoomPorcentaje == 10)
    return;
    //console.log('EVENTO ZOOM Minus ')
    this.scaleZoom -= 0.1;
    this.zoomPorcentaje -= 10;
    let zoom = document.getElementById("document-container-signature");
    zoom.style.transform = "scale(" + this.scaleZoom + ")";
    zoom.style.transformOrigin = "top"
  }

  docActive:any
  obtenerPaginasDocumento(doc:any,index:number){
    console.log("CLICK",doc)
    if (this.pdfList.length > 1) {
      if(doc.signed == true){
        this.verUbicarFirma = true;
      } else {
        this.verUbicarFirma = false;
      }
    }
    this.loadingScroll = true;
    this.items = []
    this.documentListShown = false; //ocultando select
    this.tabGroup.selectedIndex =  index;
    this.masivoCurrent = index;
    this.docActive = doc //Documento para mantener activo al firmar

    this.checkPaginas = this.signService.checkStatusList[index];
    this.hayFirmas = this.signService.signStatusList[index];

    this.numberPage = 0
    this.pageActive = 1
    //this.autoAjustarPage()

    this.scaleZoom = 1
    this.zoomPorcentaje = 100
    let zoom = document.getElementById("document-container-signature");
    zoom.style.transform = "scale(" + this.scaleZoom + ")";

    this.loadingScroll = false;
    this.loadingScrollThumb = false; 

    if(this._masivo){
      this.cantidadPaginas = this.pdfList[index].cantidadPaginas;
      this.nombreDocumento = this.pdfList[index].nombreDocumento;
      if(this.pdfList[index].imagenes == undefined){
        console.log("ENTRO AQUI")
        const bodyPages:any = {
          idProcess: doc.idProcess,
          filePath: doc.filePath,
          idDocumentSigningProcess: doc.id,
          idCompany: this.authService.obtenerIdEmpresaActiva(),
          count_page: 0
        }
  
        this.loadingScroll = true;
        this.loadingScrollThumb = true;
  
        this.serviciosProcesoService.convertirPDFImagePagination(bodyPages).subscribe((data) => {
          this.cantidadPaginas = data['cantidadPaginas'];
          this.nombreDocumento = data['nombreDocumento'];
          this.pdfList[index].imagenes = data['paginas'];
          this.pdfList[index].cantidadPaginas = data['cantidadPaginas'];
          this.pdfList[index].nombreDocumento = data['nombreDocumento'];
          this.firmasMasivo[index] = []
  
          this.loadingScroll = false;
          this.loadingScrollThumb = false;    
        }, error => {
          console.log(error)
        })
      }else{
        this.loadingScroll = false;
      }
    }else{
      this.getImages(0)
      this.loadingScroll = false;
    }
  }

  pageNext(){
    if(this.scaleZoom != 1){
      this.scaleZoom = 1
      this.zoomPorcentaje = 100
      let zoom = document.getElementById("document-container-signature");
      zoom.style.transform = "scale(1)";
    }    
    var cantPaginas = this.masivo ? this.pdfList[this.masivoCurrent].cantidadPaginas : this.cantidadPaginas
    if(this.numberPage < (cantPaginas - 1)){
      this.numberPage += 1
      this.pageActive += 1
      this.autoAjustarPageMasivo()
    }
  }

  pagePrev(){
    if(this.scaleZoom != 1){
      this.scaleZoom = 1
      this.zoomPorcentaje = 100
      let zoom = document.getElementById("document-container-signature");
      zoom.style.transform = "scale(1)";
    }

    if(this.numberPage >0){
      this.numberPage -= 1
      this.pageActive -= 1
      this.autoAjustarPageMasivo()
    }
  }

  autoAjustarPageMasivo(page:number = null){
    //Actualizando pagina al seleccionar view
    if (page != null){
      this.numberPage = page
      this.pageActive = page + 1
    }
    var arrDocument  = null
    var arrDocumentPreview  = null
    var view = null
    var viewMin = null
    var addScroll = 0
    var addScrollMin = 0
    var toptip = null
    var titulos = null

    //console.log('es masivo:', this._masivo)
    if(this._masivo){
      arrDocument =  document.getElementsByClassName('documentList')
      arrDocumentPreview =  document.getElementsByClassName('document-masivo-preview')
  
      view = document.getElementsByClassName('viewer-masivo-document')[0]
      viewMin = document.getElementsByClassName('viewer-masivo-min')[0]

      titulos = document.getElementsByClassName('titulos-document-masivo')[0]

    }else{
      arrDocument =  document.getElementsByClassName('documentList')
      arrDocumentPreview =  document.getElementsByClassName('document-nomasivo-preview')

      view = document.getElementsByClassName('viewer-document')[0]
      viewMin = document.getElementsByClassName('viewer-min')[0]

      titulos = document.getElementsByClassName('titulos-document-no-masivo')[0]
    }
    
    //addScroll = this.numberPage == 1 ? addScroll + (toptip.clientHeight + titulosdocument.clientHeight) : addScroll
    
    for(var i = 0 ; i < this.numberPage; i++){
      var add = i == 0 ? arrDocument[i].clientHeight + titulos.clientHeight : arrDocument[i].clientHeight //Añadiendo tamaño por pagina + titulos
      addScroll += add //Añadiendo tamaño por pagina
      addScrollMin += arrDocumentPreview[i].clientHeight //Añadiendo tamaño por preview
    }

    view.scrollTop = addScroll; //Recorriendo Scroll documentos
    viewMin.scrollTop = addScrollMin; //Recorriendo Scroll preview
  }

  changeFirma(e){
    console.log(this.firmaSeleccionada)
    console.log('event',e)
  }

  async getMasiveImagens(){
    if (this.pdfList[0]!=undefined){
      this.scrollHeight = await this.obtnerImagenKey(this.pdfList[0].imagenes[this.numberPage])
      //console.log('this.scrollHeight',this.scrollHeight)
    }else{
      this.scrollHeight = await this.obtnerImagenKey(this.items[this.numberPage])
      //console.log('this.scrollHeight',this.scrollHeight)
    }
  }

  detener:boolean = true
  isShow:boolean = false
  scrollHeight: number = 0
  flagScrollY:number = 0

  currentPosition: any;
  startPosition: number;
  showButton:any

  async onContainerScroll(e){ 
    const scrollY = (e.target as Element).scrollTop
    this.flagScrollY = scrollY
    let cantPaginasScroll = this._masivo ? this.pdfList[this.masivoCurrent].cantidadPaginas : this.cantidadPaginas
    let numbPage = this._masivo ?  this.pdfList[this.masivoCurrent].imagenes[this.numberPage] : this.items[this.numberPage]
    //console.log("onContainerScroll",scrollY,"=>",this.scrollHeight, "=>",this.numberPage,  Math.round((scrollY * 100)/this.scrollHeight ) )  
    if (scrollY > this.currentPosition) {
      this.showButton = false;
      if (this.detener) { 
        if (Math.round((scrollY * 100)/this.scrollHeight) >= 100 && Math.round((scrollY * 100)/this.scrollHeight) <= 120 ) {
          if (cantPaginasScroll > this.numberPage)  {
            if(numbPage!= undefined){
              this.detener = false
              const scrollHeight2 = this._masivo ? await this.obtnerImagenKey(this.pdfList[this.masivoCurrent].imagenes[this.numberPage]) : await this.obtnerImagenKey(this.items[this.numberPage])
              this.scrollHeight = this.scrollHeight + scrollHeight2 
              this.detener = true 
              this.numberPage += 1  
              this.pageActive += 1
            }
          }
        }
      }
    } else {
      this.showButton = true;
      if(this.detener && this.numberPage!=0){
        //console.log("x")
        if (Math.round((scrollY * 100)/this.scrollHeight) >= 60 && Math.round((scrollY * 100)/this.scrollHeight) <= 85) {
          //console.log("x",Math.round((scrollY * 100)/this.scrollHeight))
          if (cantPaginasScroll > this.numberPage)  {
            if(numbPage!= undefined){
              this.detener = false
              const scrollHeight2 = this._masivo ? await this.obtnerImagenKey(this.pdfList[this.masivoCurrent].imagenes[this.numberPage]) : await this.obtnerImagenKey(this.items[this.numberPage])
              this.scrollHeight = this.scrollHeight - scrollHeight2
              this.detener = true
              this.numberPage -= 1
              this.pageActive -= 1
              
            } 
          } 
        }
      }
    }
    this.currentPosition = scrollY;  
    //console.log("showButton", this.showButton,  this.currentPosition) 
  }

  async obtnerImagenKey(key){
    //console.log("obtnerImagenKey",key)
    const src = await Storage.get(key, {customPrefix: {public: ''}})
    //console.log("obtnerImagenKey src",src)
    return this.loadImgAsync(src)
  }

  loadImgAsync(imgSrc) {
    return new Promise<number>((resolve, reject) => {
     let img = new Image();
     img.onload = () => {
      resolve(img.naturalHeight);
     };
     img.onerror = reject;
     img.src = imgSrc;
    })
   }

}
