<div class="sign-overlay">
    <cdk-virtual-scroll-viewport class="contenedor-scroll" [itemSize]="100">                                                             
        <div class="viewer-container">

            <ng-container *ngIf="_masivo">
                <div class="menu-resp">
                    <div class="document-selector">
                        <span class="selected-doc" (click)="documentListShown=!documentListShown">
                            <b>Documentos</b>
                            <div class="select-pdf">
                                <b class="select-name" matTooltip="{{pdfList[masivoCurrent].documentName}}">
                                    {{pdfList[masivoCurrent].documentName}}
                                </b>
                                <i class="las la-angle-down icon-select-menu"></i>
                            </div>
                        </span>

                        <div class="hrVertical"></div>

                        <div class="doc-firmado">
                            <b>Documentos {{mensajeDocumentos}}</b>
                            <b>{{documentosFirmados}} / {{pdfList.length}} </b>
                        </div>

                        <div class="hrVertical"></div>

                        <button class="btn-ant" [class.disabled]="masivoCurrent===0" (click)="navegarMasivo('prev')"><i class="las la-arrow-left btn-icon"></i>Anterior</button>
                        <button [class.disabled]="masivoCurrent===pdfList.length-1" (click)="navegarMasivo('next')"><i class="las la-arrow-right btn-icon"></i> Siguiente</button>

                        <div class="hrVertical"></div>
                
                        <button *ngIf="firmasCompletas" class="btn-firmar" [class.disabled]="false" (click)="enviar()">{{mensaje}}</button>
                        <button *ngIf="!firmasCompletas" class="btn-firmar" [class.disabled]="true">{{mensaje}}</button>

                        <div class="document-list" [class.open]="documentListShown">
                            <ul>
                                <li class="double-row-select" *ngFor="let doc of pdfList; index as i" [class.active]="masivoCurrent === i" (click)="obtenerPaginasDocumento(doc,i)">
                                    <div *ngIf="!doc.signed" class="item__not__signedSelect"></div><div *ngIf="doc.signed" class="item__signedSelect"></div>
                                    <div class="docNameSelect" matTooltip="{{doc.documentName}}">{{doc.documentName}}</div><div class="hrHorizontal"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="btn-resp">
                    <div class="document-selector">
                        <span class="selected-doc" (click)="documentListShown=!documentListShown">
                            <b>Documentos</b>
                            <div class="select-pdf">
                                <b class="select-name" matTooltip="{{pdfList[masivoCurrent].documentName}}">
                                    {{pdfList[masivoCurrent].documentName}}
                                </b>
                                <i class="las la-angle-down icon-select-menu"></i>
                            </div>
                        </span>

                        <div class="cont-desplegable-resp" (click)="selectMasivoMenu('mainMenu');" [ngClass]="{active: isActiveMasivoMenu('mainMenu')}">
                            <div class="double-row-button">
                                <div class="col-button-resp-menu">
                                    <div *ngIf="!isActiveMasivoMenu('mainMenu')" class="btn-pag-resp"><i class="las la-bars"></i></div>
                                    <div *ngIf="isActiveMasivoMenu('mainMenu')" class="btn-pag-resp"><i class="las la-times"></i></div>
                                </div>
                                <div class="btn-desplegable">
                                    <div class="cont-menu" [ngClass]="{active: isActiveMasivoMenu('subMenu')}" (click)="selectMasivoMenu('subMenu');">
                                        <div class="doc-firmado">
                                            <b>Documentos {{mensajeDocumentos}}</b>
                                            <b>{{masivoCurrent+1}} / {{pdfList.length}} </b>
                                        </div>
                                        <button class="btn-ant" [class.disabled]="masivoCurrent===0" (click)="navegarMasivo('prev')"><i class="las la-arrow-left btn-icon"></i>Anterior</button>
                                        <button class="btn-sig" [class.disabled]="masivoCurrent===pdfList.length-1" (click)="navegarMasivo('next')"><i class="las la-arrow-right btn-icon"></i> Siguiente</button>
                                        <button *ngIf="firmasCompletas" class="btn-firmar" [class.disabled]="false" (click)="enviar()">{{mensaje}}</button>
                                        <button *ngIf="!firmasCompletas" class="btn-firmar" [class.disabled]="true">{{mensaje}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="document-list" [class.open]="documentListShown">
                            <ul>
                                <li class="double-row-select" *ngFor="let doc of pdfList; index as i" [class.active]="masivoCurrent === i" (click)="obtenerPaginasDocumento(doc,i)">
                                    <div class="docNameSelect" matTooltip="{{doc.documentName}}">
                                        <div *ngIf="!doc.signed" class="item__not__signedSelect"></div>
                                        <div *ngIf="doc.signed" class="item__signedSelect"></div>
                                        {{doc.documentName}}</div><div class="hrHorizontal">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="panel-firma-config">
                    <app-loader-full-screen *ngIf="loaderFirmar" [big]="true"></app-loader-full-screen>
                    <mat-tab-group #tabGroup class="tab-group-ind" [selectedIndex]="selectedTabs.value">
                        <mat-tab *ngFor="let doc of pdfList; index as i" [class.active]="masivoCurrent === i" (click)="masivoCurrent = i">
                            <ng-template mat-tab-label>
                                <div class="tab-document-masivo" (click)="obtenerPaginasDocumento(doc,i)">
                                    <div class="tab-document">
                                        <div *ngIf="!doc.signed" class="item__not__signed"></div>
                                        <div *ngIf="doc.signed" class="item__signed"></div>
                                        <div class="docName" matTooltip="{{doc.documentName}}">{{doc.documentName}}</div>
                                        <div class="docIcon" (click)='modalremoveTab(i)'><i class="las la-times"></i></div>
                                    </div>
                                </div>
                            </ng-template>
        
                            <!--<div class="instructions" *ngIf="!hayFirmas">Haz click en el documento para agregar la firma seleccionada</div>-->
                            <ng-container *ngIf="masivoCurrent === i" >
                                <div class="double-row-scroll">            
                                    <ng-container *ngIf="_masivo">
                                        <ng-container *ngFor="let documento of pdfList; index as i;">
                                            <ng-container *ngIf="masivoCurrent === i">
                                                <div class="cont-desplegable" [ngClass]="{active: isActiveMasivo('main')}">
                                                    <div class="double-row-button">
                                                        <div class="col-button">
                                                            <div *ngIf="!isActiveMasivo('main')" class="btn-pag" (click)="selectMasivo('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                                            <div *ngIf="isActiveMasivo('main')" class="btn-pag-disabled" (click)="selectMasivo('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                                        </div>
                                                        <div class="col-button-resp">
                                                            <div *ngIf="isActiveMasivo('main')" class="btn-pag" (click)="selectMasivo('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                                            <div *ngIf="!isActiveMasivo('main')" class="btn-pag-disabled" (click)="selectMasivo('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                                        </div>
                                                        <div class="btn-desplegable">
                                                            <div class="cont-paginas" [ngClass]="{active: isActiveMasivo('sub')}" (click)="selectMasivo('sub');">
                                                                <app-loader-scroll-preview [big]="true" *ngIf="loadingScrollThumb"></app-loader-scroll-preview>
                                                                <div class="viewer-masivo viewer-masivo-min"
                                                                    infiniteScroll
                                                                    [infiniteScrollDistance]="1"
                                                                    [infiniteScrollThrottle]="500"
                                                                    [scrollWindow]="false"
                                                                    (scrolled)="handlerPrev()"                                        
                                                                    >
                                                                <!--<cdk-virtual-scroll-viewport class="viewer-masivo" #viewer *ngIf="!loadingScroll" [itemSize] = "15" (scrolledIndexChange)="handlerPrev($event)">-->                        
                                                                    <div class="document-container is-masivo document-masivo-preview" *ngFor="let img of documento.imagenes; index as j;">
                                                                        <div class="document-preview" (click)="autoAjustarPageMasivo(j)">
                                                                            <div class="double-row-paginas">
                                                                                <app-s3-image-preview [key]="img"></app-s3-image-preview>
                                                                                <div class="cant-pag">{{j + 1}}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            <!--</cdk-virtual-scroll-viewport>-->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                
                                    <ng-container *ngIf="_masivo">
                                        <ng-container *ngFor="let documento of pdfList; index as i;">
                                            <ng-container *ngIf="masivoCurrent === i">
                                                <ng-template #step4>
                                                    <div class="copilot-custom">
                                                        <h3>4. Ubícala donde quieras.</h3>
                                                        <div class="footer-btn">
                                                            <div class="num-step">4/5</div>
                                                            <div>
                                                                <button class="btn-omitir" (click)="sinPosicionFirma(); sinFondoPosicionFirmar(); omitir();" type="button">Omitir</button>
                                                                <button class="btn-siguiente" (click)="sinPosicionFirma(); verFirma(); siguiente(5);" type="button">Siguiente</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                
                                                <ng-template #step5>
                                                    <div class="copilot-custom">
                                                        <h3>5. Gracias por firmar el documento.</h3>
                                                        <div class="footer-btn">
                                                            <div class="num-step">5/5</div>
                                                            <div>
                                                                <button class="btn-omitir" (click)="sinFirma(); sinFondoPosicionFirmar(); omitir()" type="button">Omitir</button>
                                                                <button class="btn-siguiente" (click)="sinFirma(); sinFondoPosicionFirmar(); omitir(); this.modalFelicitaciones.open();" type="button">Terminar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                
                                                <div class="instructions-onboarding-firma" *ngIf="fondoPosicionFirmar">
                                                    <div copilot [copilot-template]="step4" [copilot-step]="4">
                                                        <div style="background-color: #FFFFFF;">
                                                            <img *ngIf="posicionFirma" class="icon-pag" src="assets/img/firma-preview.png">
                                                        </div>
                                                    </div>
                                                    
                                                    <div copilot [copilot-template]="step5" [copilot-step]="5">
                                                        <div style="background-color: #FFFFFF;">
                                                            <img *ngIf="firma" class="icon-pag" src="assets/img/firma.png">
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <!--<div *ngIf="firmasUsuario.length > 0 && !verUbicarFirma" class="instructions-onboarding-full" (click)="verBloqueoFirma()">Haz click para ubicar tu firma</div>-->
                                                
                                                <div class="viewer-masivo viewer-masivo-document"
                                                    infiniteScroll
                                                    [infiniteScrollDistance]="1"
                                                    [infiniteScrollThrottle]="900"
                                                    [scrollWindow]="false"
                                                    (scrolled)="handler()"         
                                                    (scroll)="onContainerScroll($event)"         
                                                    >
                                                    <app-loader-scroll [big]="true" *ngIf="loadingScroll"></app-loader-scroll>
    
                                                <!--<cdk-virtual-scroll-viewport class="viewer-masivo" #viewer *ngIf="!loadingScroll" [itemSize]="100" (scrolledIndexChange)="handler($event)">  -->                   
                                                    <div id="document-container-signature">
                                                        <div class="is-masivo titulos-document-masivo">
                                                            <div class="document-tooltip">
                                                                <div class="titulos-pdf">
                                                                    <div>
                                                                        <h4 class="titulo-pdf">{{this.nombreDocumento}}</h4>
                                                                    </div>
                                                                    <p class="paginas-pdf">{{this.cantidadPaginas}} Página(s)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="document-container is-masivo documentList" *ngFor="let img of documento.imagenes; index as j;">
                                                            <app-loader-scroll [big]="true" *ngIf="loadingScroll"></app-loader-scroll>
                                                            <div class="document">
                                                                <app-s3-image-bloqueo *ngIf="firmasUsuario.length > 0 && !verUbicarFirma" class="s3-image-bloqueo" [key]="img" (click)="verBloqueoFirma();addFirma($event, i, j);">
                                                                </app-s3-image-bloqueo> 
    
                                                                <ng-template #step3>
                                                                    <div class="copilot-custom">
                                                                        <h3>3. Tendrás que hacer clic en la pantalla para poder posicionar tu firma.</h3>
                                                                        <div class="footer-btn">
                                                                            <div class="num-step">3/5</div>
                                                                            <div>
                                                                                <button class="btn-omitir" (click)="sinTextoFirma(); sinFondoPosicionFirmar(); omitir();" type="button">Omitir</button>
                                                                                <button class="btn-siguiente" (click)="sinTextoFirma(); verPosicionFirma(); siguiente(4);" type="button">Siguiente</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                        
                                                                <div *ngIf="fondoPosicionFirmar">
                                                                    <div copilot [copilot-template]="step3" [copilot-step]="3">
                                                                        <div *ngIf="textoFirmar"></div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div *ngIf="firmasUsuario.length > 0 && !verUbicarFirma" class="instructions-onboarding-full"> 
                                                                </div>
                                                                <app-s3-image-main #main [key]="img"></app-s3-image-main>
                                                                <div *ngIf="!checkPaginas">
                                                                    <div *ngFor="let f of firmasMasivo[i][j]; index as k;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">
                            
                                                                        <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>
    
                                                                        <app-loader-img *ngIf="rotateLoader" [big]="true"></app-loader-img>
                            
                                                                        <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">
                            
                                                                        <div class="option-sign move" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)">
                                                                            <i class="la la-arrows-alt"></i>
                                                                        </div>
                                                                        <div class="option-sign remove" (click)="remove(i,j)">
                                                                            <i class="la la-trash"></i>
                                                                        </div>
                                                                        <div class="option-sign rotate-left" (click)="rotate(i,j,'left')">
                                                                            <i class="la la-undo-alt"></i>
                                                                        </div>
                                                                        <div class="option-sign rotate-right" (click)="rotate(i,j,'right')">
                                                                            <i class="la la-redo-alt"></i>
                                                                        </div>
                                                                        
                                                                        <!--<div class="remove" (click)="remove(i,j)">
                                                                            <i class="las la-times"></i>-->
                            
                                                                        <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                                                        <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                                                        <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                                                                    </div>
                                                                </div>
                            
                                                                <div *ngIf="checkPaginas">
                                                                    <ng-container *ngIf="!isMassiveStamped(documento, j)">
                                                                        <div *ngFor="let g of iterar">
                                                                            <div *ngFor="let f of firmasMasivo[i][g]; index as k;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">
                            
                                                                                <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>
    
                                                                                <app-loader-img *ngIf="rotateLoader" [big]="true"></app-loader-img>
                            
                                                                                <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">
    
                                                                                <div class="option-sign move" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)">
                                                                                    <i class="la la-arrows-alt"></i>
                                                                                </div>
                            
                                                                                <div class="option-sign remove" (click)="remove(i,j)">
                                                                                    <i class="la la-trash"></i>
                                                                                </div>
                                                                                <div class="option-sign rotate-left" (click)="rotate(i,j,'left')">
                                                                                    <i class="la la-undo-alt"></i>
                                                                                </div>
                                                                                <div class="option-sign rotate-right" (click)="rotate(i,j,'right')">
                                                                                    <i class="la la-redo-alt"></i>
                                                                                </div>
                            
                                                                                <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                                                                <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                                                                <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <!--</cdk-virtual-scroll-viewport>-->
                                                </div>
                                                <div class="btn-paginado-flotante">
                                                    <span class="paginado">
                                                        <i (click)="pagePrev()" class="las la-angle-up icon-paginado">
                                                        </i> Página {{pageActive}} de {{cantidadPaginas}} <i (click)="pageNext()" class="las la-angle-down icon-paginado">
                                                        </i></span> | <span class="paginado"><i (click)="zoomMinus()" class="las la-search-minus icon-paginado">
                                                        </i>  {{zoomPorcentaje}}% <i (click)="zoomPlus()" class="las la-search-plus icon-paginado"></i>
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </mat-tab>
                    </mat-tab-group>
                    
                    <div class="actions">
        
                        <div class="title-firma">
                            <h4>Configuración de Firmas</h4>
                        </div>
                        
                        <div class="body-firma">
                        <ng-container *ngIf="firmasUsuario.length === 0">
                            <div class="empty">
                                <h4>No hay firmas registradas</h4>
                                <p>Haga click en el menú para dibujar o cargar una nueva firma holográfica</p>
                            </div>
                        </ng-container>
                
                        <!--<ng-container *ngIf="checkPaginas">-->
                            <!--<app-input type="select" label="" [selectOptions]="tipos" [selectOptionsName]="idNameCombo" [selectOptionsKey]="idKeyCombo" [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()"></app-input>-->
                            <!--<select [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()">
                                
                                <option *ngFor="let o of options">
                                    {{o.name}}
                                </option>
                                </select>
                
                        </ng-container>-->
                
                        <ng-container *ngIf="verNumeroPaginas">
                            <app-input type="text" label="Número Páginas" [(ngModel)]="txtNumeroPaginas"></app-input>
                        </ng-container>

                        <ng-template #step1>
                            <div class="copilot-custom">
                                <h3>1. Dirígete al panel donde están guardadas tus firmas</h3>
                                <div class="footer-btn">
                                    <div class="num-step">1/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirma(); omitir();" type="button">Omitir</button>
                                        <button (click)="sinSelectFirma(); verSelectFirmaSize(); siguiente(2);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-template #step2>
                            <div class="copilot-custom">
                                <h3>2. Selecciona tu firma.</h3>
                                <div class="footer-btn">
                                    <div class="num-step">2/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirmaSize(); omitir();" type="button">Omitir</button>
                                        <button class="btn-siguiente" (click)="verTextoFirma(); sinSelectFirmaSize(); siguiente(3);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0 && !selectFirma">
                            <app-input type="select" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                
                        <!--BODY-->	
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0  && selectFirma">
                            <app-input copilot [copilot-template]="step1" [copilot-step]="1" type="selectOnboarding" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                        
                        <div copilot [copilot-template]="step2" [copilot-step]="2" style="margin-top: -10px;">
                            <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                                <app-input *ngIf="selectFirmaSize" type="selectOnboardingSize" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                            </ng-container>
                        </div>
                
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                
                            <app-s3-image #current *ngIf="firmasUsuario.length > 0" [key]="firmaSeleccionada" class="s3-image-firma"></app-s3-image>
                
                        </ng-container>
                
                        <p class="text">
                            <span [class.checked]="false" [class.disabled]="true" class="check-box" (click)="handleCheckDetalle()"></span>
                            <span class="text-firma">Deseas adjuntar detalle de firma</span><i class="las la-question-circle" style="font-size: 15px; margin-left: 5px;"></i>
                        </p>
                        <p class="text">
                            <span [class.checked]="checkPaginas" class="check-box" (click)="handleCheck()"></span><span class="text-firma">{{mensajeCheck}} en todas las hojas</span>
                        </p>
                
                        <hr class="solid-firma">
                        <p class="text-no-firma">
                            En caso no tenga firma:
                        </p>

                        <div class="sign-actions">
                            <!--<button class="btn outline" (click)="modalCargar.open()">Cargar nueva firma</button>-->
                            <button class="btn outline" (click)="modalDibujar.open()" [class.disabled]="loadingScroll && loadingScrollThumb">Cargar nueva firma</button>
                        </div>
                
                        <!-- <app-input 
                        type="checkbox" 
                        label="Acepto los términos y condiciones" 
                        [terms]="true" 
                        [(ngModel)]="accept"></app-input> -->
                
                        <!--<p class="text-center">
                            <span [class.checked]="accept" class="check-box" (click)="handleAccept()"></span>
                            <em>Acepto los términos y condiciones</em>
                        </p>-->
                
                        </div>
                    </div>
                </div>

                <div class="panel-firma-config">
                    <div class="actions-resp">
        
                        <div class="title-firma">
                            <h4>Configuración de Firmas</h4>
                        </div>
                        
                        <div class="body-firma">
                        <ng-container *ngIf="firmasUsuario.length === 0">
                            <div class="empty">
                                <h4>No hay firmas registradas</h4>
                                <p>Haga click en el menú para dibujar o cargar una nueva firma holográfica</p>
                            </div>
                        </ng-container>
                
                        <!--<ng-container *ngIf="checkPaginas">-->
                            <!--<app-input type="select" label="" [selectOptions]="tipos" [selectOptionsName]="idNameCombo" [selectOptionsKey]="idKeyCombo" [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()"></app-input>-->
                            <!--<select [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()">
                                
                                <option *ngFor="let o of options">
                                    {{o.name}}
                                </option>
                                </select>
                
                        </ng-container>-->
                
                        <ng-container *ngIf="verNumeroPaginas">
                            <app-input type="text" label="Número Páginas" [(ngModel)]="txtNumeroPaginas"></app-input>
                        </ng-container>

                        <ng-template #step1>
                            <div class="copilot-custom">
                                <h3>1. Dirígete al panel donde están guardadas tus firmas</h3>
                                <div class="footer-btn">
                                    <div class="num-step">1/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirma(); omitir();" type="button">Omitir</button>
                                        <button (click)="sinSelectFirma(); verSelectFirmaSize(); siguiente(2);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-template #step2>
                            <div class="copilot-custom">
                                <h3>2. Selecciona tu firma.</h3>
                                <div class="footer-btn">
                                    <div class="num-step">2/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirmaSize(); omitir();" type="button">Omitir</button>
                                        <button class="btn-siguiente" (click)="verTextoFirma(); sinSelectFirmaSize(); siguiente(3);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0 && !selectFirma">
                            <app-input type="select" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                
                        <!--BODY-->	
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0  && selectFirma">
                            <app-input copilot [copilot-template]="step1" [copilot-step]="1" type="selectOnboarding" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                        
                        <div copilot [copilot-template]="step2" [copilot-step]="2" style="margin-top: -10px;">
                            <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                                <app-input *ngIf="selectFirmaSize" type="selectOnboardingSize" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                            </ng-container>
                        </div>
                
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                
                            <app-s3-image #current *ngIf="firmasUsuario.length > 0" [key]="firmaSeleccionada" class="s3-image-firma"></app-s3-image>
                
                        </ng-container>
                
                        <p class="text">
                            <span [class.checked]="false" [class.disabled]="true" class="check-box" (click)="handleCheckDetalle()"></span>
                            <span class="text-firma">Deseas adjuntar detalle de firma</span><i class="las la-question-circle" style="font-size: 15px; margin-left: 5px;"></i>
                        </p>
                        <p class="text">
                            <span [class.checked]="checkPaginas" class="check-box" (click)="handleCheck()"></span><span class="text-firma">{{mensajeCheck}} en todas las hojas</span>
                        </p>
                
                        <hr class="solid-firma">
                        <p class="text-no-firma">
                            En caso no tenga firma:
                        </p>

                        <div class="sign-actions">
                            <!--<button class="btn outline" (click)="modalCargar.open()">Cargar nueva firma</button>-->
                            <button class="btn outline" (click)="modalDibujar.open()" [class.disabled]="loadingScroll && loadingScrollThumb">Cargar nueva firma</button>
                        </div>
                
                        <!-- <app-input 
                        type="checkbox" 
                        label="Acepto los términos y condiciones" 
                        [terms]="true" 
                        [(ngModel)]="accept"></app-input> -->
                
                        <!--<p class="text-center">
                            <span [class.checked]="accept" class="check-box" (click)="handleAccept()"></span>
                            <em>Acepto los términos y condiciones</em>
                        </p>-->
                
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="!_masivo">
                <div class="menu-resp-individual">
                    <div class="document-selector">
                        <div class="doc-firmado">
                            <b>Documentos {{mensajeDocumentos}}</b>
                            <b *ngIf="!verFirmaIndividual">0 / 1</b>
                            <b *ngIf="verFirmaIndividual">1 / 1</b>
                        </div>
                        <div class="hrVertical"></div>
                        <!--<button class="btn-firmar" (click)="enviar()" [class.loading]="loading" [class.disabled]="!accept && !hayFirmas">{{mensaje}} </button>-->

                        <button *ngIf="hayFirmas" class="btn-firmar" [class.disabled]="false" (click)="enviar()">{{mensaje}}</button>
                        <button *ngIf="!hayFirmas" class="btn-firmar" [class.disabled]="true">{{mensaje}}</button>
                    </div>
                </div>

                <div class="btn-resp-individual">
                    <div class="document-selector">
                        <div class="doc-firmado">
                            <b>Documentos {{mensajeDocumentos}}</b>
                            <b *ngIf="!verFirmaIndividual">0 / 1</b>
                            <b *ngIf="verFirmaIndividual">1 / 1</b>
                        </div>
                        <div class="cont-desplegable-resp" (click)="selectIndividualMenu('mainMenu');" [ngClass]="{active: isActiveIndividualMenu('mainMenu')}">
                            <div class="double-row-button">
                                <div class="col-button-resp-menu">
                                    <div *ngIf="!isActiveIndividualMenu('mainMenu')" class="btn-pag-resp"><i class="las la-bars"></i></div>
                                    <div *ngIf="isActiveIndividualMenu('mainMenu')" class="btn-pag-resp"><i class="las la-times"></i></div>
                                </div>
                                <div class="btn-desplegable">
                                    <div class="cont-menu-individual" [ngClass]="{active: isActiveIndividualMenu('subMenu')}" (click)="selectIndividualMenu('subMenu');">
                                        <!--<button class="btn-firmar" (click)="enviar()" [class.loading]="loading" [class.disabled]="!accept && !hayFirmas">{{mensaje}}</button>-->

                                        <button *ngIf="!hayFirmas" class="btn-firmar" [class.disabled]="false" (click)="enviar()">{{mensaje}}</button>
                                        <button *ngIf="hayFirmas" class="btn-firmar" [class.disabled]="true">{{mensaje}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel-firma-config">
                    <app-loader-full-screen *ngIf="loaderFirmar" [big]="true"></app-loader-full-screen>
                    <mat-tab-group #tabGroup class="tab-group-ind" [selectedIndex]="selectedTabs.value">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <div class="tab-document-masivo">
                                    <div class="tab-document">
                                        <div *ngIf="!verFirmaIndividual" class="item__not__signed"></div>
                                        <div *ngIf="verFirmaIndividual" class="item__signed"></div>
                                        <div class="docName" matTooltip="{{this.nombreDocumento}}">{{this.nombreDocumento}}</div>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="double-row-scroll">
                        
                                <ng-container *ngIf="!_masivo">
                                    <div class="cont-desplegable" [ngClass]="{active: isActive('main')}">
                                        <div class="double-row-button">
                                            <div class="col-button">
                                                <div *ngIf="!isActive('main')" class="btn-pag" (click)="select('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                                <div *ngIf="isActive('main')" class="btn-pag-disabled" (click)="select('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                            </div>
                                            <div class="col-button-resp">
                                                <div *ngIf="isActive('main')" class="btn-pag" (click)="select('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                                <div *ngIf="!isActive('main')" class="btn-pag-disabled" (click)="select('main');"><img class="icon-pag" src="assets/img/pages.png">{{this.cantidadPaginas}}</div>
                                            </div>
                                            <div class="btn-desplegable">
                                                <div class="cont-paginas" [ngClass]="{active: isActive('sub')}" (click)="select('sub');">
                                                    <app-loader-scroll-preview [big]="true" *ngIf="loadingScrollThumb"></app-loader-scroll-preview>
                                                    <div class="viewer viewer-min"
                                                        infiniteScroll
                                                        [infiniteScrollDistance]="1"
                                                        [infiniteScrollThrottle]="500"
                                                        [scrollWindow]="false"
                                                        (scrolled)="handlerPrev()"                                        
                                                        >
                                                        <!--<cdk-virtual-scroll-viewport class="viewer" #viewer *ngIf="!loadingScrollThumb" [itemSize] = "15" (scrolledIndexChange)="handlerPrev($event)">
                                                        <div class="document-container no-masivo" *cdkVirtualFor="let img of items; index as i; trackBy: trackByIdx">-->
                                                        <div class="document-container no-masivo document-nomasivo-preview" *ngFor="let img of items; index as i;">
                                                            <div class="document-preview" (click)="autoAjustarPageMasivo(i)">
                                                                <div class="double-row-paginas">
                                                                    <app-s3-image-preview [key]="img"></app-s3-image-preview>
                                                                    <div class="cant-pag">{{i + 1}}</div>
                                                                </div>
                                                            </div>
                                                        </div>                    
                                                        <!--</cdk-virtual-scroll-viewport>-->
        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
        
                                <ng-container *ngIf="!_masivo">
                                    <ng-template #step4>
                                        <div class="copilot-custom">
                                            <h3>4. Ubícala donde quieras.</h3>
                                            <div class="footer-btn">
                                                <div class="num-step">4/5</div>
                                                <div>
                                                    <button class="btn-omitir" (click)="sinPosicionFirma(); sinFondoPosicionFirmar(); omitir();" type="button">Omitir</button>
                                                    <button class="btn-siguiente" (click)="sinPosicionFirma(); verFirma(); siguiente(5);" type="button">Siguiente</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
        
                                    <ng-template #step5>
                                        <div class="copilot-custom">
                                            <h3>5. Gracias por firmar el documento.</h3>
                                            <div class="footer-btn">
                                                <div class="num-step">5/5</div>
                                                <div>
                                                    <button class="btn-omitir" (click)="sinFirma(); sinFondoPosicionFirmar(); omitir()" type="button">Omitir</button>
                                                    <button class="btn-siguiente" (click)="sinFirma(); sinFondoPosicionFirmar(); omitir(); this.modalFelicitaciones.open();" type="button">Terminar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
        
                                    <div class="instructions-onboarding-firma" *ngIf="fondoPosicionFirmar">
                                        <div copilot [copilot-template]="step4" [copilot-step]="4">
                                            <div style="background-color: #FFFFFF;">
                                                <img *ngIf="posicionFirma" class="icon-pag" src="assets/img/firma-preview.png">
                                            </div>
                                        </div>
                                        
                                        <div copilot [copilot-template]="step5" [copilot-step]="5">
                                            <div style="background-color: #FFFFFF;">
                                                <img *ngIf="firma" class="icon-pag" src="assets/img/firma.png">
                                            </div>
                                        </div>
                                    </div>
        
                                    <!--<div *ngIf="firmasUsuario.length > 0 && !verUbicarFirma" class="instructions-onboarding-full" (click)="verBloqueoFirma()">Haz click para ubicar tu firma</div>-->
        
                                    <div class="viewer viewer-document"
                                        infiniteScroll
                                        [infiniteScrollDistance]="1"
                                        [infiniteScrollThrottle]="900"
                                        [scrollWindow]="false"
                                        (scrolled)="handler()"
                                        (scroll)="onContainerScroll($event)"                                          
                                        >
                                        <app-loader-scroll [big]="true" *ngIf="loadingScroll"></app-loader-scroll>
                                
                                        <!--<cdk-virtual-scroll-viewport class="viewer" #viewer *ngIf="!loadingScroll" [itemSize]="100" (scrolledIndexChange)="handler($event)"> -->
                                        <!--<div id="document-container-signature" class="document-container no-masivo" *cdkVirtualFor="let img of items; index as i; trackBy: trackByIdx">-->
                                        <div id="document-container-signature">
                                            <div class="no-masivo titulos-document-no-masivo">
                                                <div class="document-tooltip">
                                                    <div class="titulos-pdf">
                                                        <div>
                                                            <h4 class="titulo-pdf">{{this.nombreDocumento}}</h4>
                                                        </div>
                                                        <p class="paginas-pdf">{{this.cantidadPaginas}} Página(s)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="document-container no-masivo documentList" *ngFor="let img of items; index as i;">    
                                                <app-loader-scroll [big]="true" *ngIf="loadingScroll"></app-loader-scroll>
                                                <div class="document">
                                                    <app-s3-image-bloqueo *ngIf="firmasUsuario.length > 0 && !verUbicarFirma" class="s3-image-bloqueo" [key]="img" (click)="verBloqueoFirma();addFirma($event, i);this.verFirmaIndividual = true;">
                                                    </app-s3-image-bloqueo> 

                                                    <ng-template #step3>
                                                        <div class="copilot-custom">
                                                            <h3>3. Tendrás que hacer clic en la pantalla para poder posicionar tu firma.</h3>
                                                            <div class="footer-btn">
                                                                <div class="num-step">3/5</div>
                                                                <div>
                                                                    <button class="btn-omitir" (click)="sinTextoFirma(); sinFondoPosicionFirmar(); omitir();" type="button">Omitir</button>
                                                                    <button class="btn-siguiente" (click)="sinTextoFirma(); verPosicionFirma(); siguiente(4);" type="button">Siguiente</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                    <div *ngIf="fondoPosicionFirmar">
                                                        <div copilot [copilot-template]="step3" [copilot-step]="3">
                                                            <div *ngIf="textoFirmar"></div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div *ngIf="firmasUsuario.length > 0 && !verUbicarFirma" class="instructions-onboarding-full-ind"> 
                                                    </div>
                                                    <app-s3-image-main #main id="document_image_{{i}}" [key]="img"></app-s3-image-main>
                                                    
                                                    <div *ngIf="!checkPaginas">
                                                                    
                                                        <div *ngFor="let f of firmas[i]; index as j;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">
                    
                                                            <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>

                                                            <app-loader-img *ngIf="rotateLoader" [big]="true"></app-loader-img>

                                                            <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">
                    
                                                            <div class="option-sign move" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)">
                                                                <i class="la la-arrows-alt"></i>
                                                            </div>
                                                            <div class="option-sign remove" (click)="remove(i,j)">
                                                                <i class="la la-trash"></i>
                                                            </div>
                                                            <div class="option-sign rotate-left" (click)="rotate(i,j,'left')">
                                                                <i class="la la-undo-alt"></i>
                                                            </div>
                                                            <div class="option-sign rotate-right" (click)="rotate(i,j,'right')">
                                                                <i class="la la-redo-alt"></i>
                                                            </div>
                                                            
                                                            <!--<div class="remove" (click)="remove(i,j)">
                                                                <i class="las la-times"></i>-->
                    
                                                            <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                                            <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                                            <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                                                        </div>
                    
                                                    </div>
                    
                                                    <div *ngIf="checkPaginas" class="all-pages">
                                                        <ng-container *ngIf="!isStamped(i)">
                                                            <div *ngFor="let k of iterar">
                                                                <div *ngFor="let f of firmas[k]; index as j;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">
                    
                                                                    <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>

                                                                    <app-loader-img *ngIf="rotateLoader" [big]="true"></app-loader-img>
                    
                                                                    <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">

                                                                    <div class="option-sign move" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)">
                                                                        <i class="la la-arrows-alt"></i>
                                                                    </div>
                    
                                                                    <div class="option-sign remove" (click)="remove(i,j)">
                                                                        <i class="la la-trash"></i>
                                                                    </div>
                                                                    <div class="option-sign rotate-left" (click)="rotate(i,j,'left')">
                                                                        <i class="la la-undo-alt"></i>
                                                                    </div>
                                                                    <div class="option-sign rotate-right" (click)="rotate(i,j,'right')">
                                                                        <i class="la la-redo-alt"></i>
                                                                    </div>
                    
                                                                    <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                                                    <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                                                    <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--</cdk-virtual-scroll-viewport>-->
        
                                    <div class="btn-paginado-flotante">
                                        <span class="paginado">
                                            <i (click)="pagePrev()" class="las la-angle-up icon-paginado">
                                            </i> Página {{pageActive}} de {{cantidadPaginas}} <i (click)="pageNext()" class="las la-angle-down icon-paginado">
                                            </i></span> | <span class="paginado"><i (click)="zoomMinus()" class="las la-search-minus icon-paginado">
                                            </i>  {{zoomPorcentaje}}% <i (click)="zoomPlus()" class="las la-search-plus icon-paginado"></i>
                                        </span>
                                    </div>
                                </ng-container>    
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                    
                    <div class="actions">
        
                        <div class="title-firma">
                            <h4>Configuración de Firmas</h4>
                        </div>
                        
                        <div class="body-firma">
                        <ng-container *ngIf="firmasUsuario.length === 0">
                            <div class="empty">
                                <h4>No hay firmas registradas</h4>
                                <p>Haga click en el menú para dibujar o cargar una nueva firma holográfica</p>
                            </div>
                        </ng-container>
                
                        <!--<ng-container *ngIf="checkPaginas">-->
                            <!--<app-input type="select" label="" [selectOptions]="tipos" [selectOptionsName]="idNameCombo" [selectOptionsKey]="idKeyCombo" [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()"></app-input>-->
                            <!--<select [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()">
                                
                                <option *ngFor="let o of options">
                                    {{o.name}}
                                </option>
                                </select>
                
                        </ng-container>-->
                
                        <ng-container *ngIf="verNumeroPaginas">
                            <app-input type="text" label="Número Páginas" [(ngModel)]="txtNumeroPaginas"></app-input>
                        </ng-container>
                        
                        <ng-template #step1>
                            <div class="copilot-custom">
                                <h3>1. Dirígete al panel donde están guardadas tus firmas</h3>
                                <div class="footer-btn">
                                    <div class="num-step">1/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirma(); omitir();" type="button">Omitir</button>
                                        <button (click)="sinSelectFirma(); verSelectFirmaSize(); siguiente(2);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-template #step2>
                            <div class="copilot-custom">
                                <h3>2. Selecciona tu firma.</h3>
                                <div class="footer-btn">
                                    <div class="num-step">2/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirmaSize(); omitir();" type="button">Omitir</button>
                                        <button class="btn-siguiente" (click)="verTextoFirma(); sinSelectFirmaSize(); siguiente(3);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0 && !selectFirma">
                            <app-input type="select" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                
                        <!--BODY-->	
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0  && selectFirma">
                            <app-input copilot [copilot-template]="step1" [copilot-step]="1" type="selectOnboarding" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                        
                        <div copilot [copilot-template]="step2" [copilot-step]="2" style="margin-top: -10px;">
                            <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                                <app-input *ngIf="selectFirmaSize" type="selectOnboardingSize" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                            </ng-container>
                        </div>
                
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                
                            <app-s3-image #current *ngIf="firmasUsuario.length > 0" [key]="firmaSeleccionada" class="s3-image-firma"></app-s3-image>
                
                        </ng-container>
                        
                        <p class="text">
                            <span [class.checked]="false" [class.disabled]="true" class="check-box" (click)="handleCheckDetalle()"></span><span class="text-firma">Deseas adjuntar detalle de firma</span><i class="las la-question-circle" style="font-size: 15px; margin-left: 5px;"></i>
                        </p>
                        <p class="text">
                            <span [class.checked]="checkPaginas" class="check-box" (click)="handleCheck()"></span ><span class="text-firma">{{mensajeCheck}} en todas las hojas</span>
                        </p>
                
                        <hr class="solid-firma">
                        <p class="text-no-firma">
                            En caso no tenga firma:
                        </p>
                
                        <div class="sign-actions">
                            <!--<button class="btn outline" (click)="modalCargar.open()">Cargar nueva firma</button>-->
                            <button class="btn outline" (click)="modalDibujar.open()" [class.disabled]="loadingScroll && loadingScrollThumb">Cargar nueva firma</button>
                        </div>
                
                        <!-- <app-input 
                        type="checkbox" 
                        label="Acepto los términos y condiciones" 
                        [terms]="true" 
                        [(ngModel)]="accept"></app-input> -->
                
                        <!--<p class="text-center">
                            <span [class.checked]="accept" class="check-box" (click)="handleAccept()"></span>
                            <em>Acepto los términos y condiciones</em>
                        </p>-->
                        </div>
                    </div>
                </div>

                <div class="panel-firma-config">
                    <div class="actions-resp">
        
                        <div class="title-firma">
                            <h4>Configuración de Firmas</h4>
                        </div>
                        
                        <div class="body-firma">
                        <ng-container *ngIf="firmasUsuario.length === 0">
                            <div class="empty">
                                <h4>No hay firmas registradas</h4>
                                <p>Haga click en el menú para dibujar o cargar una nueva firma holográfica</p>
                            </div>
                        </ng-container>
                
                        <!--<ng-container *ngIf="checkPaginas">-->
                            <!--<app-input type="select" label="" [selectOptions]="tipos" [selectOptionsName]="idNameCombo" [selectOptionsKey]="idKeyCombo" [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()"></app-input>-->
                            <!--<select [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()">
                                
                                <option *ngFor="let o of options">
                                    {{o.name}}
                                </option>
                                </select>
                
                        </ng-container>-->
                
                        <ng-container *ngIf="verNumeroPaginas">
                            <app-input type="text" label="Número Páginas" [(ngModel)]="txtNumeroPaginas"></app-input>
                        </ng-container>

                        <ng-template #step1>
                            <div class="copilot-custom">
                                <h3>1. Dirígete al panel donde están guardadas tus firmas</h3>
                                <div class="footer-btn">
                                    <div class="num-step">1/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirma(); omitir();" type="button">Omitir</button>
                                        <button (click)="sinSelectFirma(); verSelectFirmaSize(); siguiente(2);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-template #step2>
                            <div class="copilot-custom">
                                <h3>2. Selecciona tu firma.</h3>
                                <div class="footer-btn">
                                    <div class="num-step">2/5</div>
                                    <div>
                                        <button class="btn-omitir" (click)="sinSelectFirmaSize(); omitir();" type="button">Omitir</button>
                                        <button class="btn-siguiente" (click)="verTextoFirma(); sinSelectFirmaSize(); siguiente(3);" type="button">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0 && !selectFirma">
                            <app-input type="select" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                
                        <!--BODY-->	
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0  && selectFirma">
                            <app-input copilot [copilot-template]="step1" [copilot-step]="1" type="selectOnboarding" label="Selecciona tu firma existente:" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                        </ng-container>
                        
                        <div copilot [copilot-template]="step2" [copilot-step]="2" style="margin-top: -10px;">
                            <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                                <app-input *ngIf="selectFirmaSize" type="selectOnboardingSize" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>
                            </ng-container>
                        </div>
                
                        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">
                
                            <app-s3-image #current *ngIf="firmasUsuario.length > 0" [key]="firmaSeleccionada" class="s3-image-firma"></app-s3-image>
                
                        </ng-container>
                
                        <p class="text">
                            <span [class.checked]="false" [class.disabled]="true" class="check-box" (click)="handleCheckDetalle()"></span>
                            <span class="text-firma">Deseas adjuntar detalle de firma</span><i class="las la-question-circle" style="font-size: 15px; margin-left: 5px;"></i>
                        </p>
                        <p class="text">
                            <span [class.checked]="checkPaginas" class="check-box" (click)="handleCheck()"></span><span class="text-firma">{{mensajeCheck}} en todas las hojas</span>
                        </p>
                
                        <hr class="solid-firma">
                        <p class="text-no-firma">
                            En caso no tenga firma:
                        </p>

                        <div class="sign-actions">
                            <!--<button class="btn outline" (click)="modalCargar.open()">Cargar nueva firma</button>-->
                            <button class="btn outline" (click)="modalDibujar.open()" [class.disabled]="loadingScroll && loadingScrollThumb">Cargar nueva firma</button>
                        </div>
                
                        <!-- <app-input 
                        type="checkbox" 
                        label="Acepto los términos y condiciones" 
                        [terms]="true" 
                        [(ngModel)]="accept"></app-input> -->
                
                        <!--<p class="text-center">
                            <span [class.checked]="accept" class="check-box" (click)="handleAccept()"></span>
                            <em>Acepto los términos y condiciones</em>
                        </p>-->
                
                        </div>
                    </div>
                </div>
                
            </ng-container>

        </div>
    </cdk-virtual-scroll-viewport>  

    <ngx-wrapper-copilot></ngx-wrapper-copilot>
</div>

<div class="btn-flotante" (click)="selectBoton('mainBoton','');" [ngClass]="{active: isActiveBoton('mainBoton')}">
    <i class="las la-question-circle"></i>
    <div class="btn-desplegable-flotante">
        <div class="btn-flotante-contenido" [ngClass]="{active: isActiveBoton('subBoton')}" (click)="selectBoton('subBoton','');">
            <div class="btn-tutorial">Tutorial de firma pin</div>
            <div class="btn-tutorial">Tutorial de firma holografica</div>
            <div class="btn-tutorial">Tutorial de firma con certificado</div>
        </div>   
    </div>
</div>

<!--<app-modal #modalTerminos titleModal="Términos y Condiciones" [loading]="cargarLoadingFirma" (onconfirm)="accept = true" [scroller]="true">
    <app-terminos-y-condiciones></app-terminos-y-condiciones>
</app-modal>-->

<app-modal-felicitaciones #modalFelicitaciones [closeOnConfirm]="true">
    <img class="img-modal" src="assets/img/party.png">
    <h4 class="title-body">¡¡ Felicitaciones !!</h4>
    <p class="text-body">Haz completado el tutorial exitosamente</p>
</app-modal-felicitaciones>

<app-modal #modalCargar titleModal="Cargar Firma" [loading]="cargarLoadingFirma" (onconfirm)="cargarFirma()" (onclose)="reset()" [closeOnConfirm]="false" [disableConfirm]="!preview || !title">
    <h4>Indicaciones:</h4>
    <p><em>- Tamaño:273 x 185
        - Peso: 50KB
        - Formato: PNG, JPG</em></p>
        
    <div class="drop" *ngIf="!preview">
        <app-dropzone #drop (onconvert)="setPreview($event)" (ondrop)="blob=$event"></app-dropzone>
    </div>

    <div class="preview" *ngIf="preview">
        <button class="remove" (click)="setPreview(null)"><i class="las la-times"></i></button>
        <img [src]="preview">
    </div>

    <app-input type="text" label="Nombre" [(ngModel)]="title"></app-input>

    <app-input type="textarea" label="Descripción" [(ngModel)]="description"></app-input>

</app-modal>

<app-modal #modalDibujar class="modalDibujar" titleModal="Cargar nueva firma" [hide-footer]="true" [loading]="cargarLoadingFirma"
    (onclose)="reset()" [disableConfirm]="!titleDraw && !titleWrite && !titleUpload">

    <mat-tab-group>
        <mat-tab label="Dibujar">
            <div class="pad-container" (click)="tabs(1)">
                <signature-pad #signature (drawEnd)="save()"></signature-pad>
                <button class="clear" (click)="clear()">Borrar</button>
            </div>

            <app-input type="text" label="Nombre" [(ngModel)]="titleDraw"></app-input>

            <app-input type="textarea" label="Descripción" [(ngModel)]="descriptionDraw"></app-input>
        </mat-tab>

        <mat-tab label="Escribir">
            <div class="pad-container" (click)="tabs(2)">
                <textarea class="text-area-pad" [(ngModel)]="signWrite" name="textarea" rows="1" cols="50" placeholder="Escriba aquí"></textarea>
                <button class="clear" (click)="clear()">Borrar</button>
            </div>

            <app-input type="text" label="Nombre" [(ngModel)]="titleWrite"></app-input>

            <app-input type="textarea" label="Descripción" [(ngModel)]="descriptionWrite"></app-input>

        </mat-tab>

        <mat-tab label="Subir">
            <div class="drop" *ngIf="!preview" (click)="tabs(3)">
                <app-dropzone-subir #drop (onconvert)="setPreview($event)" (ondrop)="blob=$event"></app-dropzone-subir>
            </div>

            <div class="preview" *ngIf="preview" (click)="tabs(3)">
                <button class="remove" (click)="setPreview(null)"><i class="las la-times"></i></button>
                <img [src]="preview">
            </div>

            <app-input type="text" label="Nombre" [(ngModel)]="titleUpload"></app-input>
            <app-input type="textarea" label="Descripción" [(ngModel)]="descriptionUpload"></app-input>
        </mat-tab>
    </mat-tab-group>
    <div class="form-footer">
        <button class="btn success btn-cancelar" type="button" (click)="this.modalDibujar.close(); reset();">Cancelar</button>
        <button *ngIf="!agregarLoader" class="btn success" type="button" (click)="cargarFirma()">Agregar rúbrica</button>
        <button *ngIf="agregarLoader" class="btn default disabled" type="button"><app-loader-scroll [big]="false"></app-loader-scroll> Agregar rúbrica</button>
    </div>
</app-modal>

<app-modal #modalCambiarmeCuentaPago titleModal="CAMBIARME A CUENTA PREMIUM" [hide-footer]="true">
    <em>Sólo disponible para Versión premium. Quiero que me contacten</em>
    <div class="form-footer">
        <button class="btn success" (click)="aceptarCambiarmeCuentaPago()" [class.loading]="modalCambiarmeCuentaPagoLoading">Quiero que me contacten</button>
    </div>

</app-modal>

<!-- Modal Cerrar Documento Visor Nuevo -->
<app-modal #modalCerrarDocumento class="modalCerrarDocumento" [hide-footer]="true" [closeOnConfirm]="true">
    <p class="title">¿Deseas eliminar el documento actual?</p>
    <p class="label-subtitle">Tomar en cuenta que al eliminar el documento actual, pasarás al siguiente</p>
    <div class="form-footer">
        <button class="btn success btn-cancelar" type="button" (click)="this.modalCerrarDocumento.close()">Cancelar</button>
        <button class="btn success" type="button" (click)="removeTab()">Eliminar</button>
        <!--<button class="btn default disabled loading" type="button"> Eliminar </button>-->
    </div>
</app-modal>