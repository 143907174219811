import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import Amplify from "aws-amplify";

import { SharedModule } from './shared/shared.module';
import { PasswordValidatorsService } from 'src/app/services/password-validators.service';
import { FormValidationService } from './services/form-validation.service';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { UnauthorizedComponent } from './pages/error/unauthorized/unauthorized.component';

import { environment } from '../environments/environment';
import { NotLoggedInGuard } from './guards/not-logged-in.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { NotificationsService } from './services/notifications.service';
import { TitleService } from './services/title.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { PermisosService } from './services/permisos.service';

// import { QuillModule } from 'ngx-quill';
import { DashboardService } from './services/dasboard.service';
import { NoAuthorizedInterceptor } from './interceptors/no-authorized.interceptor';
import { AuthComponent } from './auth/auth.component';
import { AuthModule } from './auth/auth.module';
import { appReducer } from './store/app.reducer';
import { CompartirDatosService } from './services/compartirDatos.service';
import {MatTabsModule} from '@angular/material/tabs';


Amplify.configure({
  Auth: {
    region: "us-west-2",
    identityPoolId: environment.identityPoolId,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.webClientId
  },
  Storage: {
    AWSS3: {
      bucket: environment.bucket, //REQUIRED -  Amazon S3 bucket name
      region: 'us-west-2' //OPTIONAL -  Amazon service region
    }
  }
});


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'login',
    // loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    component: AuthComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'qr',
    loadChildren: () => import('./qr/qr.module').then(m => m.QrModule),
  },
  {
    path: 'holografica',
    loadChildren: () => import('./holographic/holographic.module').then(m => m.HolopraphicModule),
  },
  {
    path: 'creacion',
    loadChildren: () => import('./creation/creation.module').then(m => m.CreationModule),
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
]

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
    AuthModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducer),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    SharedModule,
    MatTabsModule
  ],
  providers: [
    FormValidationService,
    PasswordValidatorsService,
    NotificationsService,
    TitleService,
    PermisosService,
    DashboardService,
    CompartirDatosService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoAuthorizedInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
