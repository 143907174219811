import { 
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,    
    OnInit,    
    Output,    
    Renderer2,      
    ViewChild
} from "@angular/core";

import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import * as faceApi from 'face-api.js';

import { 
    ChallengeDetails,
    IDrawBoxOptions,
    IDrawOptions,
    IVerify,
    IVerifyRequestData,
    MediaStreamInfo
} from "src/app/models/Challenge.model";
import { NotificationsService } from "src/app/services/notifications.service";
import { BaseUnsubscribe } from "src/app/util/base-unsubscribe";
import { LivenessDetectionUtil as Util } from './liveness-detection.util';
import { LivenessDetectionConstants as Const } from "./liveness-detection.constants";
import { LivenessDetectionService } from "./liveness-detection.service";
import { ChallengeService } from "src/app/services/api/challenge.service";
import * as LiveDetectionActions from './store/liveness-detection.actions';
import * as fromLiveDetection from './store/liveness-detection.reducer';
import * as fromApp from '../../store/app.reducer';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-liveness-detection',
    templateUrl: './liveness-detection.component.html',
    styleUrls: ['./liveness-detection.component.scss'],
    host: { class: 'liveness-detection' },
    providers: [LivenessDetectionService]
})
export class LivenessDetectionComponent extends BaseUnsubscribe 
    implements OnInit, AfterViewInit, OnDestroy {

    @Input() challengeDetail: ChallengeDetails;
    @Output() success: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() errorVerify: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('webcamVideo') videoElement: ElementRef<HTMLVideoElement>;
    @ViewChild('overlayCanvas') canvasElement: ElementRef<HTMLCanvasElement>;

    videoWidth: number;
    videoHeight: number;
    endTime: number;
    famesWithFace: number = 0;
    IDprocessTimerList: any[] = [];
    IDEndTimeTimerList: any[] = [];
    isNoseState: boolean = false;
    endState: boolean = false;
    shouldSaveFrameState: boolean = false;
    drawOptionsState: IDrawOptions = null;
    helpMessage: string;
    isLoading: boolean = false;
    sucessVerify: boolean = false;
    isErrorVerify: boolean = false;
    endVerify: boolean = false;
    isFail: boolean = false;

    constructor(
        private notifyService: NotificationsService,
        private liveDetectionService: LivenessDetectionService,
        private challengeService: ChallengeService,
        private store: Store<fromApp.AppState>,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(new LiveDetectionActions.ResetAction());
    }

    ngAfterViewInit(): void {
        this.loadFaceApiModels();
        this.setEndTime();
        this.listenStore();
    }

    loadFaceApiModels(): void {
        const url = '/assets/weights/';
    
        Promise.all([
            faceApi.nets.tinyFaceDetector.loadFromUri(url),
            faceApi.nets.faceLandmark68Net.loadFromUri(url)
        ])
        .then(this.startVideo)
        .catch((error) => {
            console.error('Error to load the models', error);
        });
    }

    startVideo = (): void => {
        const {
            imageHeight,
            imageWidth
        } = this.challengeDetail;

        Util.loadMediaStream(
            imageHeight,
            imageWidth,
            (stream: MediaStreamInfo) => {

                this.videoWidth = stream.actualWidth;
                this.videoHeight = stream.actualHeight;
                this.videoElement.nativeElement.srcObject = stream.mediaStream;

                this.renderer.listen(
                    this.videoElement.nativeElement,
                    'loadedmetadata',
                    () => { this.process() }
                );
            },
            (message) => {
                this.notifyService.error(message);
            }
        );
    }

    setEndTime(isNoseState = false): void {
        const maximumDurationInSeconds = isNoseState
            ? Const.STATE_NOSE_DURATION_IN_SECONDS
            : Const.STATE_AREA_DURATION_IN_SECONDS;

        const IDEndTimeTimer = setTimeout(() => {
            this.store.dispatch(new LiveDetectionActions.FailAction());
            this.clearTimers();
        }, maximumDurationInSeconds * 1000);

        this.IDEndTimeTimerList.push(IDEndTimeTimer);
    }

    ngOnDestroy(): void {
        Util.stopMediaStream();
        this.store.dispatch(new LiveDetectionActions.FailAction());
    }

    listenStore(): void {
        this.store
            .select('liveDetection')
            .pipe(takeUntil(this.destroy$))
            .subscribe((stateData: fromLiveDetection.State) => {
                const { 
                    drawOptions, success, end, shouldSaveFrame, helpMessage
                } = stateData;
                
                this.isLoading = success;
                this.endState = end;
                this.drawOptionsState = drawOptions;
                this.shouldSaveFrameState = shouldSaveFrame
                this.helpMessage = helpMessage;
                this.isFail = end && !success;

                if (drawOptions?.noseDrawBoxOptions) {
                    this.isNoseState = true;
                }
                this.cdr.detectChanges();
            });
    }

    private async process(): Promise<void> {
        const options = new faceApi.TinyFaceDetectorOptions();

        const detections = await faceApi
            .detectAllFaces(this.videoElement.nativeElement, options)
            .withFaceLandmarks(false);

        if (!detections.length && !this.endState) {
            this.process();
        }
        this.processDetectionResults(detections);
    }

    private processDetectionResults(
        detections: faceApi.WithFaceLandmarks<
                { detection: faceApi.FaceDetection }, 
                faceApi.FaceLandmarks68
            >[]
    ): void {

        if (this.endState) {
            this.clearTimers();
            Util.stopMediaStream();
            return;
        }

        const dims = faceApi.matchDimensions(
            this.canvasElement.nativeElement,
            this.videoElement.nativeElement
        );

        if (this.drawOptionsState) {
            this.draw(this.drawOptionsState);                    
        }

        if (this.shouldSaveFrameState) {
            this.liveDetectionService.uploadFrame(
                this.videoElement.nativeElement,
                this.challengeDetail
            );
        }

        if (!this.isNoseState) {
            this.store.dispatch(new LiveDetectionActions.NoFaceAreaAction());
        }
        
        if (
            detections.length === 1 &&
            this.isFaceBoxInsideFaceArea(detections[0].detection.box, this.isNoseState)
        ) {
            this.store.dispatch(new LiveDetectionActions.FaceAreaAction());
            this.store.dispatch(new LiveDetectionActions.NoseAreaAction());

            if (Const.PROFILING) {
                const resizedDetections = faceApi.resizeResults(detections, dims);
                faceApi.draw.drawDetections(this.canvasElement.nativeElement, resizedDetections);
                faceApi.draw.drawFaceLandmarks(this.canvasElement.nativeElement, resizedDetections);
            }

            const nosePoint = detections[0].landmarks.positions[Const.LANDMARK_NOSE_INDEX];
            if (this.isNoseInsideNoseArea(nosePoint)) {                
                this.store.dispatch(new LiveDetectionActions.SuccessAction());
                this.verify();
            }
            
            if (++this.famesWithFace === 1) {
                this.setEndTime(true);
            }
        } else if (this.isNoseState) {
            // this.store.dispatch(new LiveDetectionActions.NoFaceAreaAction());
            this.store.dispatch(new LiveDetectionActions.FailAction());
        }
        
        if (this.endState) {
            this.clearTimers();
            Util.stopMediaStream();
            return;
        } else {
            const delay = 1000 / Const.MAX_FPS;
            const IDProcessTimer = setTimeout(() => this.process(), delay);
            this.IDprocessTimerList.push(IDProcessTimer);
        }
    }

    /**
     * Send verification to service.
     */
    private verify(): void {
        
        Promise.all(this.liveDetectionService.promises).then(() => {
            const request: IVerifyRequestData = { 
                token: this.challengeDetail.token
            };
            
            this.challengeService.verification(this.challengeDetail.id, request)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: IVerify) => {

                    Util.stopMediaStream();
                    this.success.emit(response.success);
                    this.sucessVerify = response.success;
                    this.endVerify = true;
                    this.isLoading = false;
                    this.cdr.detectChanges();
                }, () => {

                    Util.stopMediaStream();
                    this.errorVerify.emit();
                    this.endVerify = true;
                    this.isErrorVerify = true;
                    this.isLoading = false;
                    this.cdr.detectChanges();
                });
        });
    }

    /**
     * Clear all the timers.
     */
    private clearTimers(): void {        
        this.IDprocessTimerList.forEach((idTimer) => {
            clearTimeout(idTimer);
        });
        this.IDEndTimeTimerList.forEach((idTimer) => {
            clearTimeout(idTimer);
        });
    }

    private isFaceBoxInsideFaceArea(faceBox: faceApi.Box, addTolerance = true): boolean {
        const { areaLeft, areaTop, areaWidth, areaHeight } = this.challengeDetail;
        const tolerance: number = addTolerance ? Const.FACE_AREA_TOLERANCE_PERCENT / 100 : 0;

        return (
          faceBox.x >= areaLeft * (1 - tolerance) &&
          faceBox.y >= areaTop * (1 - tolerance) &&
          faceBox.x + faceBox.width <= areaLeft + areaWidth * (1 + tolerance) &&
          faceBox.y + faceBox.height <= areaTop + areaHeight * (1 + tolerance)
        );
    }

    private isNoseInsideNoseArea(nose: faceApi.IPoint): boolean {
        return (
          nose.x >= this.challengeDetail.noseLeft &&
          nose.y >= this.challengeDetail.noseTop &&
          nose.x <= this.challengeDetail.noseLeft + this.challengeDetail.noseWidth &&
          nose.y <= this.challengeDetail.noseTop + this.challengeDetail.noseHeight
        );
    }

    private draw(drawOptions: IDrawOptions): void {
        if (drawOptions.faceDrawBoxOptions) {
            const faceAreaBox = {
                x: this.challengeDetail.areaLeft,
                y: this.challengeDetail.areaTop,
                width: this.challengeDetail.areaWidth,
                height: this.challengeDetail.areaHeight
            };
            this.drawArea(faceAreaBox, drawOptions.faceDrawBoxOptions);
        }

        if (drawOptions.noseDrawBoxOptions) {
            const noseAreaBox = {
                x: this.challengeDetail.noseLeft,
                y: this.challengeDetail.noseTop,
                width: this.challengeDetail.noseWidth,
                height: this.challengeDetail.noseHeight
            }
            this.drawArea(noseAreaBox, drawOptions.noseDrawBoxOptions);
        }
    }

    private drawArea(box: faceApi.IRect, drawBoxOptions: IDrawBoxOptions): void {
        const drawBox = new faceApi.draw.DrawBox(box, drawBoxOptions);
        drawBox.draw(this.canvasElement.nativeElement);
    }
}