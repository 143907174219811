import { Injectable } from "@angular/core";
import { Opcion } from "../models/Opcion.model";

@Injectable({
    providedIn: 'root'
})
export class PermisosService {

    permisos:string[] = JSON.parse(localStorage.getItem('OPCIONES'))

    setOpciones():void {
        this.permisos = JSON.parse(localStorage.getItem('OPCIONES'))
    }

    permitir(opcion:string):boolean {
        return this.permisos.includes(opcion)
    }
}