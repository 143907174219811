import { Component, EventEmitter, OnInit, Output, HostListener, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { TitleService } from 'src/app/services/title.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { PasswordValidatorsService } from 'src/app/services/password-validators.service';
import { Subscription } from 'rxjs';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { PermisosService } from 'src/app/services/permisos.service';
import { ServiciosProcesoService } from '../../services/servicios-proceso.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ReceptorService } from 'src/app/services/receptor.service';
import { TopbarConstants } from './topbar.constants';
import countries from 'src/app/util/countries';
import { Usuario } from '@noe/models/Usuario.model';
import { PersonalizacionService } from '@noe/services/personalizacion.service';
import { takeUntil } from 'rxjs/operators';
import { BaseUnsubscribe } from 'src/app/util/base-unsubscribe';
import { CompartirDatosService } from '@noe/services/compartirDatos.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent extends BaseUnsubscribe implements OnInit {

  @ViewChild('passModal') passModal:ModalComponent
  @ViewChild('modalCambiarmeCuentaPago') modalCambiarmeCuentaPago:ModalComponent
  @ViewChild('modalConfirmarLogout') modalConfirmarLogout:ModalComponent
  @ViewChild('modalLecturaTerminos') modalLecturaTerminos:ModalComponent
  @ViewChild('editarMiCuentaModal') editarMiCuentaModal:ModalComponent
  @HostListener('document:click') clickout():void {
    if (this.drop) this.drop = false
    if (this.profileDrop) this.profileDrop = false
    if (this.profileTraductor) this.profileTraductor = false;
  }
  
  passForm:FormGroup
  usuarioForm:FormGroup
  subscription: Subscription
  headPremium = TopbarConstants.HEAD_PREMIUM;
  bodyPremium = TopbarConstants.BODY_PREMIUM;

  tipoDocumentos:string[] = [
    'DNI',
    'CE',
    'PTP',
    'Pasaporte'
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public title: TitleService,
    public authService: AuthService,
    public notify: NotificationsService,
    public fb: FormBuilder,
    public fv: FormValidationService,
    public validators: PasswordValidatorsService,
    public usuarioService: UsuariosService,
    public permisosService: PermisosService,
    public serviciosProcesoService: ServiciosProcesoService,
    public empresaService: EmpresaService,
    public receptorService: ReceptorService,
    private customService: PersonalizacionService,
    public compartirDatosService: CompartirDatosService
  ) { 
    super();
  }

  countries: any[] = [...countries]
  .sort((a, b) => a.es.localeCompare(b.es))
  .map((item) => {
    return {
      ...item,
      optionName: `${item.es} (${item.dial})`
    }
  });

  // sidebar:boolean = true
  @Output()
  toggleSidebar: EventEmitter<any> = new EventEmitter()
  @Output()
  toggleSidebarCollapse: EventEmitter<any> = new EventEmitter()
  private readonly defaultLogo = '../../../assets/img/logo-1.png';
  
  name:string
  profiles:string[]
  existeEmail:boolean=false
  crearLoading:boolean
  drop:boolean
  profileDrop:boolean
  profileTraductor: boolean
  empresaActiva:any
  empresas:any[] = []
  esGratuita:boolean

  twice:boolean = false
  sus:Subscription

  logoSidebar: string = this.defaultLogo;
  
  ngOnInit(): void {
    this.scriptTraductorStyle();
    this.scriptTraductor();
    this.compartirDatosService.changeMessage(false);
    this.name = this.authService.obtenerNombres().join(' ')
    this.profiles = this.authService.obtenerPerfiles()
    this.empresas = this.authService.obtenerEmpresas()
    this.empresaActiva = this.empresas.find(e => e.id == this.authService.obtenerIdEmpresaActiva())
    this.esGratuita = this.authService.obtenerTipoCuenta() !== 'PAGO'

    this.sus = this.route.queryParams.subscribe(params => {
      if (!params.login && !this.twice) {
        this.empresaService.obtenerEmpresaActiva(this.authService.obtenerIdEmpresaActiva()).subscribe(data => {
          this.authService.guardarDataEmpresaActiva(data.find(c => c.id == this.authService.obtenerIdEmpresaActiva()))
        }, error => {
          console.log(error)
        })

        this.usuarioService.obtenerUsuario(
          this.authService.obtenerUsername(), 
          this.authService.obtenerSubdominio()).subscribe(data => {
          this.authService.guardarDatos(data, true)
          this.permisosService.setOpciones()
          this.profiles = this.authService.obtenerPerfiles()
        }, error => {
          console.log(error)
        })
        
      } else {
        
      }

      this.twice = true
    })
    //this.listenCustomImages();
  }

  closeDrop() {
    this.profileTraductor = false;
    this.profileDrop = false;
  }

  closeProfileDrop() {
    this.profileTraductor = false;
    this.drop = false;
  }

  closeProfileTraductor() {
    this.profileDrop = false;
    this.drop = false;
  }

  scriptTraductor() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    document.body.appendChild(script);
  }

  scriptTraductorStyle() {
    var jq = document.createElement("script");
    jq.type = "text/javascript";
    jq.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js";
    document.body.appendChild(jq);
  }

  listenCustomImages(): void {
    this.customService.listarImagenesPersonalizacionLogeado(
      this.authService.obtenerSubdominio() , this.authService.obtenerIdEmpresaActiva()
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: any) => {
      const { logoWeb } = response;

      this.logoSidebar = logoWeb
        ? logoWeb
        : this.defaultLogo;
    });
  }

  seleccionarEmpresaActiva(empresa) {
    this.authService.guardarEmpresaActiva(empresa)
    location.reload()
  }

  toggle(event: MouseEvent) {
    event.stopPropagation()
    this.toggleSidebar.emit()
  }

  toggleCollapse(event: MouseEvent) {
    event.stopPropagation()
    this.toggleSidebar.emit()
  }

  async logout() {
    this.sus.unsubscribe();
    await Auth.signOut({ global: true });
    localStorage.clear();
    this.router.navigate(['/login']);
  }


  abrirModalConfirmarSalir(){
    this.modalConfirmarLogout.open()
    this.compartirDatosService.changeMessage(true);

  }
  confirmarSalir(){
    this.logout()
    this.compartirDatosService.changeMessage(false);
  }

  cancelarSalir(){
    this.modalConfirmarLogout.close()
    this.compartirDatosService.changeMessage(false);
  }
  
  valorBloquear:boolean
  enviarValor: boolean
  abrirModalLecturaTerminos(){
    this.modalLecturaTerminos.open()
    this.compartirDatosService.changeMessage(true);
  }

  regresarListaUsuarios(){

  }

  initChangePass() {
    this.compartirDatosService.changeMessage(true);
    if (!this.passForm) {
      const passwordValidators = this.validators.set()
      this.passForm = this.fb.group({
        oldPass: ['', [Validators.required, Validators.minLength(6)]],
        password: ['', passwordValidators],
        confirm: ['', [Validators.required]]
      }, { validators: this.checkPasswords })
    }
    this.passModal.open()
  }


  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirm').value;
    return pass === confirmPass ? null : { mismatch: true }     
  } 

  pLoading:boolean
  // Cambiar contraseña
  async changePassword(e) {

    e.preventDefault()

    if (this.pLoading) return

    if (this.passForm.invalid) {
      return this.fv.validateForm(this.passForm)
    }

    this.pLoading = true

    try { 
      const user = await Auth.currentAuthenticatedUser()
      const result = await Auth.changePassword(user, this.passForm.get('oldPass').value, this.passForm.get('password').value);

      if (result === 'SUCCESS') {
        try{
          this.receptorService.actualizarFechaContraseña(this.authService.obtenerUsername(), this.authService.obtenerSubdominio()).subscribe(
            data => {
              console.log('Fecha Contraseña Actualizada')
          })
          this.pLoading = false
          this.passModal.close()
          this.passForm.reset()
          this.compartirDatosService.changeMessage(false);
          this.notify.success('Contraseña cambiada con éxito')
        }catch(error){
          console.log('Hubo un problema al actualizar la fecha')
        }
        
      } else {
        this.notify.error('Hubo un problema al procesar la solicitud')
      }

    } catch (error) {
      this.notify.error('Hubo un problema al realizar la petición')
    }
    
    this.pLoading = false
  }

  modalCambiarmeCuentaPagoLoading:boolean
  solicitarPremium(){
    this.modalCambiarmeCuentaPago.open()
  }

  aceptarCambiarmeCuentaPago(){
    this.modalCambiarmeCuentaPagoLoading = true
    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio()
    }
    this.serviciosProcesoService.enviarCorreoContactarme(data).subscribe(data => {
      this.notify.success("Se envió la solicitud correctamente.")
      this.modalCambiarmeCuentaPagoLoading = false
      this.modalCambiarmeCuentaPago.close()
      //this.modalContactarCuentaFreeLoading = false
    }, error => {
      this.notify.error(error.error.response)
      //this.modalContactarCuentaFreeLoading = false
    })

  }

   usuarioGuardado: Usuario
  onUpdateUser(): void {
    this.compartirDatosService.changeMessage(true);
    const username = this.authService.obtenerUsername();

    //this.usuarioGuardado = null;
    this.usuarioService.obtenerUsuario(username, this.authService.obtenerSubdominio()).subscribe(data => {

      console.log("DATA USUARIOS:" , data)
      this.usuarioGuardado = data

      const countryCode = countries.find((item) => item.code === data.nationalType)?.dial;
      if (!this.usuarioForm) {
        const passwordValidators = this.validators.set()
        this.usuarioForm = this.fb.group({
          name:[data.name, [Validators.required, Validators.minLength(2)]],
          paternalSurname:[data.paternalSurname, [Validators.required, Validators.minLength(2)]],
          maternalSurname:[data.maternalSurname, [Validators.required, Validators.minLength(2)]],
          identityType:[data.identityType, [Validators.required]],
          identityNumber:[data.identityNumber, [Validators.required, Validators.maxLength(10), Validators.minLength(8)]],
          email:[data.email, [Validators.required, Validators.email]],
          phoneNumber: [data.phoneNumber.slice(countryCode.length), [Validators.required, Validators.minLength(6),Validators.maxLength(9)]],
          nationalType: [data.nationalType, [Validators.required]],
          firmasType: [data.desc_tipos_firmas ?data.desc_tipos_firmas:[]],
          companys: [data.companys, [Validators.required]],
          editSignigProcess: [JSON.parse(data.attributes?.editar_proceso_firma.toLowerCase()) || false],
          enableSignigProcess: [JSON.parse(data.attributes?.desactivar_proceso_firma.toLowerCase()) || false],
          office: [data.attributes.oficina],
          idCompany : this.authService.obtenerIdEmpresaActiva()
          
         
         
        })
      }

    }, error => {
      console.log(error)
    })





    this.editarMiCuentaModal.open()


  }

  cerrarModalEditarCuenta(){
    this.compartirDatosService.changeMessage(false);
    this.editarMiCuentaModal.close()
  }

  handleSubmit(event?:any) {

    /*if (!this.usuarioForm.valid) {
      return this.fv.validateForm(this.usuarioForm)
    }*/

   

    const usuario: Usuario = {...this.usuarioForm.value};
    usuario.login = this.usuarioForm.get('identityNumber').value
    const nationality = this.usuarioForm.get('nationalType').value;
    const countryCode = countries.find((item) => item.code === nationality)?.dial;
    usuario.phoneNumber = countryCode + this.usuarioForm.get('phoneNumber').value;

    const regex:RegExp = new RegExp(' ', 'g')
    usuario.phoneNumber = usuario.phoneNumber.replace(regex, '');
    usuario.document_type = this.usuarioGuardado.attributes.tipos_documentos
    usuario.document_visado = this.usuarioGuardado.attributes.documentos_visado
    usuario.companys=JSON.stringify(this.usuarioGuardado.companys)
    usuario.idCompany = this.authService.obtenerIdEmpresaActiva()
    usuario.editSignigProcess = this.usuarioGuardado.attributes.editar_proceso_firma == "False" ? false : true
    usuario.idProfile=this.usuarioGuardado.idProfile
    usuario.idTipoFirma=JSON.stringify(this.usuarioGuardado.attributes.tipos_firmas)
    usuario.role = this.usuarioGuardado.attributes.roles
    usuario.subdomain = this.usuarioGuardado.subdomain


    console.log("DATOS EDITAR MI CUENTA:", usuario)

    this.crearLoading = true;
    this.usuarioService.actualizarUsuario(
      usuario.email, 
      this.authService.obtenerSubdominio(),
      usuario
    ).subscribe(data => {

      this.notify.success('Edición exitosa')
      this.editarMiCuentaModal.close()
      this.compartirDatosService.changeMessage(false);
    }, error => {
      this.crearLoading = false
      if (error.status === 409) this.existeEmail=true
      else this.notify.error('No se pudo editar')
    })


  }
}
