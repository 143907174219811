import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  notifications: any[] = []

  success(msg:string) {
    this.notificacion('success', 'Éxito', msg, null)
  }

  error(msg:string) {
    this.notificacion('error', 'Error', msg, null)
  }

  info(msg:string) {
    this.notificacion('info', 'Aviso', msg, null)
  }

  notificacion(tipo: string, titulo: string, mensaje: string, url: string, items:any[] = []) {

    let show = true

    const nuevo:any = { tipo, titulo, mensaje, show, url }
    if (items.length > 0) nuevo.items = items
    this.notifications = [...this.notifications, nuevo]

    nuevo.autoCerrar = setTimeout(() => {
      this.close(0)
    }, 8000);
    
  }
  

  close(i:number) {
    if (this.notifications[i]) {
      this.notifications[i].show = false;
      setTimeout(() => {
        this.notifications = this.notifications.filter( n => n != this.notifications[i])
      }, 450);
    }
  }


  enter(i:number) {
    clearTimeout(this.notifications[i].autoCerrar)
  }
  leave(i:number) {
    this.notifications[i].autoCerrar = setTimeout(() => {
      this.close(i)
    }, 3000);
  }

  closeAll() {
    this.notifications = []
  }

}
