import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { CapitalValidator, LowercaseValidator, NumberValidator, SymbolValidator } from '../util/custom-validators';

@Injectable({
  providedIn: 'root'
})
export class PasswordValidatorsService {

    constructor() { }

    validators: any = {
        capitalValidator:false,
        lowercaseValidator:false,
        numberValidator:false,
        symbolValidator:false,
        length8Validator:false,
        length6Validator:false,
        max8Validator:false
    }
    
    set() {
        const validaciones = ['CapitalValidator','LowercaseValidator','NumberValidator','Length8Validator','SymbolValidator']
        let passwordValidators = [Validators.required, Validators.maxLength(99)]
        validaciones.forEach( v => {
            switch (v) {
                case 'CapitalValidator':
                this.validators.capitalValidator = true
                passwordValidators.push(CapitalValidator)
                break
                case 'LowercaseValidator':
                this.validators.lowercaseValidator = true
                passwordValidators.push(LowercaseValidator)
                break
                case 'SymbolValidator':
                this.validators.symbolValidator = true
                passwordValidators.push(SymbolValidator)
                break
                case 'NumberValidator':
                this.validators.numberValidator = true
                passwordValidators.push(NumberValidator)
                break
                case 'Length8Validator':
                this.validators.length8Validator = true
                passwordValidators.push(Validators.minLength(8))
                break
                case 'Length6Validator':
                this.validators.length6Validator = true
                passwordValidators.push(Validators.minLength(6))
                break
                case 'max8Validator':
                this.validators.max8Validator = true
                passwordValidators.push(Validators.maxLength(8))
                break
            }
        })
        return passwordValidators
    }
  
}
