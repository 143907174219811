import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

import { from, Observable, throwError } from 'rxjs';
import { catchError, exhaustMap, switchMap, take } from 'rxjs/operators';
import { Auth } from 'aws-amplify';

import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let sent:boolean;
  
    return from(Auth.currentSession())
      .pipe(
        switchMap((auth: any) => {

          if (auth) {
            const jwt = auth.idToken.jwtToken;
            const modifiedReq = this.injectToken(req, jwt);
            sent = true;
            
            return this.authService.refreshToken$
              .pipe(
                take(1),
                exhaustMap((refreshToken: string) => {
                  
                  if (!refreshToken) {
                    return next.handle(modifiedReq);
                  }

                  this.authService.handleTokenExpiration();
                  const newReq = this.injectToken(req, refreshToken);                
                  return next.handle(newReq);
                })
              );
          }
        }),
        catchError((err: HttpErrorResponse) => {
          if (sent) {
            return throwError(err);
          }
          
          return next.handle(req);
        })
      );

  }

  /**
   * Inject the token to the header.
   */
  private injectToken(request: HttpRequest<any>, token: any): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
