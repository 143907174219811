import { 
    Component,
    Output,
    EventEmitter,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit
} from "@angular/core";

import { IAlterButton } from "src/app/services/alert.service";
import { CountdownComponent } from "../countdown/countdown.component";

export type TypeAlert = 'success' | 'error' | 'expiration';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements AfterViewInit {

    @Input() title: string;
    @Input() message: string;
    @Input() buttons: IAlterButton[];
    @Output() close = new EventEmitter<void>();
    @Output() countdownEnd = new EventEmitter<void>();
    @ViewChild('backdrop') backdrop: ElementRef<HTMLDivElement>;
    @ViewChild('countdown') countdown: CountdownComponent;
    
    type: TypeAlert;
    maxWidth: string;
    minWidth: string;
    showIcon: boolean;
    timeLeft: number;
    isCloseBackdrop: boolean;

    ngAfterViewInit(): void {
        if (!!this.timeLeft) {
            this.countdown.start();
        }
    }

    onClose(event): void {
        if (
            this.isCloseBackdrop && 
            event.target === this.backdrop.nativeElement
        ) {
            this.close.emit();
        }
    }

    public dismiss() {
        this.close.emit();
    }

    onCountdownEnd(): void {
        this.countdownEnd.emit();
    }

    setStyles(): { [key: string]: any } {
        const { maxWidth, minWidth } = this;
        return {
             maxWidth,
             minWidth
        };
    }

    swapType() {
        return {
            success: () => 'Éxito',
            error: () => 'Error',
            expiration: () => 'SESIÓN POR EXPIRAR',
            default: () => this.title
        }[this.type || 'default']();
    }
}