import { 
    Component, 
    HostBinding, 
    Input, 
    OnInit
} from "@angular/core";

@Component({
    selector: 'app-box-area',
    templateUrl: './box-area.component.html',
    styleUrls: ['./box-area.component.scss']
})
export class BoxAreaComponent implements OnInit {

    @Input() boxWidth: string;
    @Input() boxHeight: string;

    @HostBinding('style.--box-width') hostBoxWidth: string;
    @HostBinding('style.--box-height') hostBoxHeight: string;
    
    ngOnInit(): void {
        this.hostBoxWidth = this.boxWidth;
        this.hostBoxHeight = this.boxHeight;
    }
}