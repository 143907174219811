import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { WebcamComponent, WebcamImage } from 'ngx-webcam';
import { v4 as uuidv4 } from 'uuid';

import { NotificationsService } from 'src/app/services/notifications.service';
import { ServiciosProcesoService } from 'src/app/services/servicios-proceso.service';
import { DropzoneComponent } from '../dropzone/dropzone.component';
import { ChallengeService } from 'src/app/services/api/challenge.service';
import { ChallengeDetails } from 'src/app/models/Challenge.model';
import { BaseUnsubscribe } from 'src/app/util/base-unsubscribe';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

type box = {
  name:'documento' | 'rostro' | 'selfie',
  view?:'carga' | 'captura' | 'captura-selfie',
  img?:string,
  source?:string
}

@Component({
  selector: 'app-firma-facial',
  templateUrl: './firma-facial.component.html',
  styleUrls: ['./firma-facial.component.scss']
})
export class FirmaFacialComponent extends BaseUnsubscribe implements OnInit {

  @ViewChild('drop') drop:DropzoneComponent
  @ViewChild('webcam') webcam:WebcamComponent

  @Output()
  onchange: EventEmitter<box[]> = new EventEmitter<box[]>()
  @Output()
  maxintentos: EventEmitter<any> = new EventEmitter()

  @Input() childMessage: any;

  @ViewChild('modalIntentos') modalIntentos:ModalComponent

  checkPaginas:boolean = true;
  showFaceAreaBox: boolean = false;
  showDocAreaBox: boolean = false;
  resChallengeDetail = null;
  isLoading: boolean = false;

  constructor(
    public notify: NotificationsService,
    public serviciosProcesoService: ServiciosProcesoService,
    private challengeService: ChallengeService
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(this.boxes)
  }

  boxes:box[] = [
    { name: 'documento' },
    { name: 'rostro' }
  ]

  startChallenge(){
    this.challengeService.startChallenge({
      userId: uuidv4(),
      imageWidth: 390,
      imageHeight: 290
    })
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: ChallengeDetails) => {
      this.isLoading = false;
      this.resChallengeDetail = response;
      console.log("resChallengeDetail",this.resChallengeDetail)
    }, (error) => {
      this.isLoading = false;
      console.log('error', error);
    });
  }

  intento:number
  textIntentos:string
  numeroIntentos(box){
    console.log(this.childMessage)
    const data = this.childMessage
    this.serviciosProcesoService.itentosDesafioVida(data).subscribe(data => {
      console.log("itentosDesafioVida",data,data['attemp'])
      this.intento = data['attemp']
      //this.notify.success("Se envió la solicitud correctamente.")
      /*if (this.childMessage['esFirmaMasiva']){
        this.startChallenge()
        console.log(1)
        return false
      }*/

      if (this.intento <= 5){
        //if (!this.childMessage['esFirmaMasiva']) this.notify.info("Intento número: "+this.intento)
        this.notify.info("Intento número: "+this.intento)
        this.startChallenge()
      }else{
        console.log("es masivo "+this.childMessage['esFirmaMasiva'])
        if (!this.childMessage['esFirmaMasiva']) this.textIntentos = "la firma"
        if (this.childMessage['esFirmaMasiva']) this.textIntentos = "firmar en bloque"
        this.modalIntentos.open()
        this.isLoading = false;
        delete box.view
        console.log(3)
      }
    }, error => {
      console.log('error', error);
      this.notify.error("Al parecer ocurrio un problema a retornar el numero de intento")
    })
  }

  closeModalIntentos(){
    this.modalIntentos.close()
    this.maxintentos.emit(false)
  }

  confirmarCodigoSeguridad(){
    this.modalIntentos.close()
    this.maxintentos.emit(true)
  }

  seleccionar(vista: 'carga' | 'captura' | 'captura-selfie', box: box): void {
    console.log(vista,box)
    box.view = vista;
    this.isLoading = true;
    this.resChallengeDetail = null;
    if (vista=='captura' && box.name == 'rostro'){
      this.numeroIntentos(box)
    } 
  }

  verifyResponse(event,box:box){
    console.log('verifyResponse',event,this.resChallengeDetail)
    if (event){
      const challangeId = this.resChallengeDetail.id
      console.log(challangeId)
      
      setTimeout(() => {
        box.name = 'selfie'
        this.seleccionar('captura-selfie', box)
      }, 1000)
      
    }
    this.onchange.emit(this.boxes)
  }

  cargarFoto(event:string, box:box) {
    box.img = event
    this.onchange.emit(this.boxes)
  }

  tomarFoto(event:WebcamImage, box:box) {
    box.img = event.imageAsDataUrl
    this.onchange.emit(this.boxes)
  }

  tomarSelfie(event:WebcamImage, box:box) {
    box.img = event.imageAsDataUrl
    this.onchange.emit(this.boxes)
  }

  removerFoto(box) {
    delete box.img
    this.onchange.emit(this.boxes)
  }

  restart(box:box) {
    setTimeout(() => {
      this.boxes.forEach((b:box) => { 
        delete b.img; 
        delete b.view; 
        if (b.name == 'selfie') b.name = 'rostro'
      })
      console.log("restart",this.boxes)
    }, 800)
  }

  onSwitched(boxName: string) {
    if (boxName === 'documento') {
      this.showDocAreaBox = true;
      return;
    }

    if (boxName === 'rostro') {
      this.showFaceAreaBox = true;
      return;
    }
  }

  private getUUId(): string {
    let userId = window.localStorage.getItem('uuid');
    if (userId === null) {
      userId = uuidv4();
      window.localStorage.setItem('uuid', userId);
    }

    return userId;
  }
}
