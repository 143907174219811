import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-password-validators',
  templateUrl: './password-validators.component.html',
  styleUrls: ['./password-validators.component.scss']
})
export class PasswordValidatorsComponent implements OnInit {

  @Input() form: FormGroup
  @Input() validators: any

  constructor() { }

  ngOnInit(): void {
  }

}
