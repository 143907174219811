<ng-container *ngIf="readonly">
    <div class="input-container disabled">
        <label>{{label}}</label>
        <input 
        type="text"
        readonly
        disabled
        (focus)="$event.preventDefault()"                      
        (click)="$event.preventDefault()"
        (change)="$event.preventDefault()"
        (keyDown)="$event.preventDefault()"
        (keyUp)="$event.preventDefault()"
        (keyPress)="$event.preventDefault()"
        [(ngModel)]="selectedFormat">
    </div>
</ng-container>


<div [class.inline]="inline" class="picker-container" (click)="$event.stopPropagation()" *ngIf="!readonly">

    <div 
    class="input-container" 
    [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
        <label><span *ngIf="required" class="red">*</span> {{label}}</label>
        <input readonly type="text" [(ngModel)]="selectedFormat" (focus)="open()">
    </div>

    <div class="picker" [class.show]="show">

        <!-- Calendar View -->
        <ng-container *ngIf="view === 0">
            <div class="header">
                <div class="month" (click)="view=1">{{months[currentMonth]}}</div>
                <div class="year" (click)="view=2">{{currentYear}}</div>
                <div class="navigation">
                    <button type="button" class="prev" (click)="setMonth(currentMonth-1)">
                        <span></span>
                    </button>
                    <button type="button" class="next" (click)="setMonth(currentMonth+1)">
                        <span></span>
                    </button>
                </div>
            </div>

            <div class="week">
                <div *ngFor="let w of weekDays" class="cell weekday">{{w}}</div>
            </div>
        
            <div class="calendar">
        
                <ng-container *ngFor="let d of prevDays">
                    <div 
                    [class.today]="d.today"
                    [class.active]="d.selected"
                    [class.disabled]="d.disabled"
                    class="cell day other" 
                    (click)="selectDate(d)">{{d.day}}</div>
                </ng-container>
        
                <ng-container *ngFor="let d of currentDays">
                    <div 
                    [class.today]="d.today"
                    [class.active]="d.selected"
                    [class.disabled]="d.disabled"
                    class="cell day" 
                    (click)="selectDate(d)">{{d.day}}</div>
                </ng-container>
        
                <ng-container *ngFor="let d of nextDays">
                    <div 
                    [class.today]="d.today"
                    [class.active]="d.selected"
                    [class.disabled]="d.disabled"
                    class="cell day other" 
                    (click)="selectDate(d)">{{d.day}}</div>
                </ng-container>
        
            </div>
        </ng-container>
        <!-- Calendar View -->

        <!-- Month View -->
        <ng-container *ngIf="view === 1">
            <div class="header">
                <div class="month">MESES</div>
                <div class="year" (click)="view=2">{{currentYear}}</div>
            </div>
        
            <div class="months">
        
                <ng-container *ngFor="let m of months; index as i;">
                    <div class="cell month" (click)="setMonth(i)">{{m | slice:0:3}}</div>
                </ng-container>
        
            </div>
        </ng-container>
        <!-- Month View -->

        <!-- Year View -->
        <ng-container *ngIf="view === 2">
            <div class="header">
                <div class="month">AÑOS</div>
                <div class="year">{{years[currentPage][0] + ' - ' + years[currentPage][years[currentPage].length-1]}}</div>
                <div class="navigation">
                    <button type="button" class="prev" (click)="setPage(false)">
                        <span></span>
                    </button>
                    <button type="button" class="next" (click)="setPage(true)">
                        <span></span>
                    </button>
                </div>
            </div>
        
            <div class="years">
                <div class="year" *ngFor="let y of years[currentPage]" (click)="setYear(y)">{{y}}</div>
            </div>
        </ng-container>
        <!-- Year View -->

    </div>
</div>

<div class="error-block" *ngIf="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
    <p *ngIf="ngControl.control.errors?.required">Este campo es obligatorio</p>
</div>