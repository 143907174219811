import { formatDate } from '@angular/common';
import { Component, OnInit, Self, Optional, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { PickerCalendarComponent } from '../picker-calendar/picker-calendar.component';

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss']
})
export class DaterangepickerComponent implements OnInit, ControlValueAccessor {

  @ViewChild('picker1') picker1: PickerCalendarComponent
  @ViewChild('picker2') picker2: PickerCalendarComponent

  @Input() label:string
  @Input() required:boolean
  @Input() readonly:boolean
  @Input() inline:boolean

  @Input() min:Date
  @Input() max:Date

  @Output() 
  selection: EventEmitter<any> = new EventEmitter()

  @Output()
  firstselection: EventEmitter<any> = new EventEmitter()
  @Output()
  secondselection: EventEmitter<any> = new EventEmitter()

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl
  ) {
    if (this.ngControl) { this.ngControl.valueAccessor = this }
  }

  // private _value:any
  onChangeCallback: any = () => {}
  onTouchedCallback: any = () => {}

  writeValue(value:any) {
    this.onChangeCallback(value)
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn
  }

  show:boolean
  @HostListener('document:click') clickout() {
    if (this.show) this.onTouchedCallback()
    this.close()
  }

  today:Date = new Date()
  next:Date = new Date()
  ngOnInit(): void {
    this.next.setMonth(this.next.getMonth() + 1)
  }

  selectedDate:Date[] = []
  selectedDateTime:number[] = []
  selectedFormat:string

  current:number = 0
  setRange(date:Date) {

    if (this.selectedDate[0] && this.selectedDate[0] > date) {
      this.fillNull()
      this.deselectAll()
      this.current = 0
    }

    if (this.selectedDate[1]) {
      this.fillNull()
      this.deselectAll()
    }

    this.selectedDate[this.current] = date
    this.selectedDateTime[this.current] = date.getTime()

    this.setValues()
    this.setSelected()

    if (this.current === 1) {
      this.colorRanges()
      this.selectedFormat = formatDate(this.selectedDate[0], 'dd/MM/yyyy', 'en_US') + ' - ' + formatDate(this.selectedDate[1], 'dd/MM/yyyy', 'en_US')
      this.writeValue(this.selectedDate)
    }

    this.current = this.current === 0 ? 1 : 0
    console.log(this.selectedDate)
  }

  nav(dir:string) {
    if (dir === 'next') {
      this.picker1.setMonth(this.picker1.currentMonth + 1)
      this.picker2.setMonth(this.picker2.currentMonth + 1)
    } else {
      this.picker1.setMonth(this.picker1.currentMonth - 1)
      this.picker2.setMonth(this.picker2.currentMonth - 1)
    }
  }

  deselectAll() {
    this.picker1.deselectAll()
    this.picker2.deselectAll()
  }

  fillNull() {
    this.selectedDate.fill(null)
    this.selectedDateTime.fill(null)
    this.picker1.range.fill(null)
    this.picker2.rangeDateTime.fill(null)
  }

  setSelected() {
    this.picker1.setSelected()
    this.picker2.setSelected()
  }

  colorRanges() {
    this.picker1.colorRange()
    this.picker2.colorRange()
  }

  setValues() {
    this.picker1.range = [...this.selectedDate]
    this.picker1.rangeDateTime = [...this.selectedDateTime]
    this.picker2.range = [...this.selectedDate]
    this.picker2.rangeDateTime = [...this.selectedDateTime]
  }

  open() {
    this.show = true
  }
  close() {
    this.show = false
  }

}
