<div class="overlay fade" [class.show]="isOpen">
    <div class="modal slide" [class.down]="isOpen" [class.big]="big" [class.full-screen]="fullScreen" [class.auto-width]="autoWidth">

        <!--<div class="modal-header">
            <h2 class="modal-title">{{title}}</h2>
            <button class="modal-close" (click)="close()">
                <i class="las la-times"></i>
            </button>
        </div>-->

        <div class="modal-body" [class.no-footer]="hideFooter" [class.scroller]="scroller">
            <ng-content></ng-content>
        </div>

        <div class="modal-footer" *ngIf="!hideFooter">
            <button class="btn default aceptar" 
            (click)="confirm()" 
            [class.loading]="loading" 
            [class.disabled]="loading || disableConfirm" title="">{{confirmText}}</button>
            <!--<button class="btn danger" (click)="close()" title="">{{cancelText}}</button>-->
        </div>

    </div>
</div>
