import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICompany } from '../models/Company.model';
import { Documento } from '../models/Documento.model';
import { Personalizacion } from '../models/Personalizacion.model';

type RequestBody = {
  method: string
  data?: Documento | ICompany,
  id?: string,
  idCompany?: string,
  subdomain?:string
}


@Injectable({
  providedIn: 'root'
})
export class PersonalizacionService {

  private urlPersonalizacion:string = `${environment.apiCore}/company/`;

  constructor( private http: HttpClient ) { }


  guardarPersonalizacion(personalizacion:Personalizacion) {
    const body: RequestBody = { method: 'ADD-PERZONALIZATION', data: personalizacion }
    return this.http.post(this.urlPersonalizacion, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarImagenesPersonalizacion(domain:string) {
    const body: RequestBody = {
      method:"GET-LOGIN",
      subdomain:domain
    }
    return this.http.post(this.urlPersonalizacion ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarImagenesPersonalizacionLogeado(domain:string,id:string) {
    const body: RequestBody = {
      method:"GET-LOGIN-LOGEADO",
      subdomain:domain,
      id:id
    }
    return this.http.post(this.urlPersonalizacion ,body, { headers: { "x-api-key": environment.xApiKey } })
  }


  addNewCompany(data: ICompany): Observable<any> {
    const body: RequestBody = {
      method: 'ADD-NEW-COMPANY',
      data
    };
    return this.http.post(this.urlPersonalizacion, body, { headers: { "x-api-key": environment.xApiKey } });
  }
}