<div class="multi-select-container" (click)="$event.stopPropagation()">
    <div class="input-container" [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
        <label>{{label}} <span class="red" *ngIf="required">*</span></label>
        <div class="selection-list" (click)="open()" [class.opened]="show">
            <span class="selection" *ngFor="let s of selected; index as i;">
                {{s[displayTextField]}}
                <div class="icon" (click)="remove($event, i, s.itemListIndex)">
                    <i class="las la-times"></i>
                </div>
            </span>
        </div>
    </div>

    <div class="dropdown" *ngIf="show" [class.inscroller]="inscroller">
        <input *ngIf="displayFilter" class="filter" type="text" placeholder="Buscar..." (keyup)="filter($event.target.value)">
        <ul class="item-list" *ngIf="!search">

            <ng-container *ngFor="let item of _items; index as i">
                <li *ngIf="item.visible" class="option" (click)="handleSelect(item)">
                    <span class="check-box" [class.checked]="item.selected"></span>

                    <ng-container *ngIf="template" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{$implicit: item}">
                    </ng-container>

                    <ng-container *ngIf="!template">
                        {{item[displayTextField]}}
                    </ng-container>
                </li>
            </ng-container>

        </ul>

        <ul class="item-list" *ngIf="search">

            <ng-container *ngFor="let item of _items; index as i">
                <li *ngIf="item.visible" class="option" (click)="handleSelectSimple(item)">

                    <ng-container *ngIf="template" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{$implicit: item}">
                    </ng-container>

                    <ng-container *ngIf="!template">
                        {{item[displayTextField]}}
                    </ng-container>
                </li>
            </ng-container>

        </ul>
    </div>

</div>

<div class="error-block" *ngIf="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
    <p *ngIf="ngControl.control.errors?.required">Este campo es obligatorio</p>
    <p *ngIf="ngControl.control.errors?.AtLeastOne">Debe haber al menos una selección</p>
</div>