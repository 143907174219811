export const environment = {
  production: false,
  identityPoolId: 'us-west-2:3dee49f9-bf65-47ce-a748-bf48b96c67a3',
  userPoolId: 'us-west-2_bLb8veukR',
  webClientId: '7ohu3tq8mo3cj1adhov60a0ncs',
  bucket: 'noe-qa',
  api: 'https://au429ftrwk.execute-api.us-west-2.amazonaws.com/qa/noe',
  apiCore: 'https://au429ftrwk.execute-api.us-west-2.amazonaws.com/qa/noe/core',
  xApiKey: 'ZIfBNISTBV2wLIWHUzvIfmeyEl0wHlg4FZn7b6Ri',
  captchaSiteKey:'6LfBENkaAAAAAP9qn7Z73BYMsrdvE-bfcZ92i7VX',
  reporteReplace: 'https://noe-qa.s3.amazonaws.com/',
  TESTING : false,
  rutaRegionQa:'https://noe-qa.s3.us-west-2.amazonaws.com',
  socketEndpoint:'wss://mlr7fbo28d.execute-api.us-west-2.amazonaws.com/noeqa'
};
