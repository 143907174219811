import { Time } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {

  @Input('minutes') minutes:number = this.authService.obtenerTiempoExpiracion() - 1
  @Input() seconds: number = 59;

  @Output()
  onstart: EventEmitter<any> = new EventEmitter()
  @Output()
  onfinish: EventEmitter<any> = new EventEmitter()

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  countDown: Time = { hours: 0, minutes: 0 };
  countEnd: boolean = false
  count: any = null
  start() {
    
    this.onstart.emit()
    clearInterval(this.count)

    this.countDown = { hours: 0, minutes: this.minutes };

    this.count = setInterval(() => {

      if (this.seconds == 0 && this.countDown.minutes == 0) {

        clearInterval(this.count)
        this.countEnd = true
        this.count = null
        this.onfinish.emit()

      } else {
        this.seconds--;
        if (this.seconds == -1) {
          if (this.countDown.minutes > 0) this.countDown.minutes--;
          this.seconds = 59
        }
      }
    }, 1000);
  }

}
