import { Injectable } from "@angular/core";

import { Subject } from "rxjs";

export interface IGlobalLogout {
    show: boolean;
}

@Injectable({ providedIn: 'root' })
export class AlertGlobalLogoutServie {

    globalLogout$ = new Subject<IGlobalLogout>();

    show(): void {
        this.globalLogout$.next({ show: true });
    }
}