import { ActionReducerMap } from '@ngrx/store';

import * as fromLiveDetection from '../shared/liveness-detection/store/liveness-detection.reducer';

export interface AppState {
    liveDetection: fromLiveDetection.State;
}

export const appReducer: ActionReducerMap<AppState> = {
    liveDetection: fromLiveDetection.liveDetectionReducer
}