import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ApiService {

    constructor(private http: HttpClient) {}

    post(path: string, body: Object = {}): Observable<any> {
        return this.http.post(`${environment.api}${path}`, body, { 
            headers: { "x-api-key": environment.xApiKey } 
        });
    }

    put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(`${environment.api}${path}`, body, {
             headers: { "x-api-key": environment.xApiKey } 
        });
    }

    get(path: string, body: Object = {}): Observable<any> {
        return this.http.get(`${environment.api}${path}`, {
             headers: { "x-api-key": environment.xApiKey } 
        });
    }
}