import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { ApiService } from "./base/api.service";
import { ChallengeDetails, IVerify, StartRequestData } from '../../models/Challenge.model';

@Injectable({ providedIn: 'root' })
export class ChallengeService {

    constructor(private apiService: ApiService) {}

    startChallenge(body: StartRequestData): Observable<ChallengeDetails> {
        return this.apiService.post(`/challenge/start`, body);
    }

    saveFrames(challengeId: string, body: any): Observable<any> {
        return this.apiService.put(`/challenge/${challengeId}/frames`, body);
    }

    verification(challengeId: string, body: any): Observable<IVerify> {
        return this.apiService.post(`/challenge/${challengeId}/verify`, body);
    }

    getFrames(challengeId: string, body: any): Observable<any> {
        return this.apiService.get(`/challenge/${challengeId}/frameskey`, body);
    }
}