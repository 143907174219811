import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Perfil } from '../models/Perfil.model';
import { Documento } from '../models/Documento.model';

type requestBody = {
  method: string
  data?: Documento,
  id?: string,
  idCompany?: string
}


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private urlProceso:string = `${environment.apiCore}/process-events/`;

  constructor( private http: HttpClient ) { }

 
  listarDocumentosSubidos(login:string, id:string) {
    const body:requestBody = {
      method:"GET-DOCUMENTS-UPLOAD",
      data:{
        user:login,
        idCompany:id
      }
    }
    return this.http.post(this.urlProceso ,body, { headers: { "x-api-key": environment.xApiKey } })
  }
 
  listarDocumentosSubidosCuentaFree(login:string, id:string) {
    const body:requestBody = {
      method:"DOCUMENTS-COUNT-USER",
      data:{
        user:login,
        idCompany:id
      }
    }
    return this.http.post(this.urlProceso ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  
 
  listarPendientes(empresa:string, user:string, subdomain:string):Observable<any> {
    const body:requestBody = { method: 'GET-DOCUMENTS-PENDING', data: { user:user,idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }
 
  listarProcesos(sub:string, user:string, empresa:any):Observable<any> {
    const body:requestBody = { method: 'GET-DOCUMENTS-PROCESS', data: {user:user,idCompany:empresa,subdomain:sub}}
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentosFinalizados(empresa:string, user:string, subdomain:string):Observable<any>{
    const body:requestBody = { method: 'GET-DOCUMENTS-FINALIZED', data: { user:user,idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentosCancelados(empresa:string, user:string, subdomain:string):Observable<any>{
    const body:requestBody = { method: 'GET-DOCUMENTS-CANCELLED', data: { user:user,idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarPorNotificar(empresa:string, user:string, subdomain:string):Observable<any> {
    const body:requestBody = { method: 'GET-DOCUMENTS-TO-NOTIFY', data: { user:user,idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarNotificado(empresa:string, user:string, subdomain:string):Observable<any> {
    const body:requestBody = { method: 'GET-DOCUMENTS-NOTIFIED', data: { user:user,idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentosReceptor(empresa:string, user:string, subdomain:string):Observable<any> {
    const body:requestBody = { method: 'GET-DOCUMENTS-RECEPTOR', data: { user:user,idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  cantidadesDocumentosRealizados(empresa:string, user:string, subdomain:string):Observable<any>{
    const body:requestBody = { method: 'GET-DASHBOARD-AMOUNT', data: { user:user,idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlProceso, body, { headers: { "x-api-key": environment.xApiKey } })
  }

}