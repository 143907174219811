import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader-full-screen',
  templateUrl: './loader-full-screen.component.html',
  styleUrls: ['./loader-full-screen.component.scss'],
  host: { class: 'app-loader-full-screen' }
})
export class LoaderFullScreenComponent implements OnInit {

  @Input('disabled') disabled:boolean
  @Input('big') big:boolean
  @Input('no-bg') noBg:boolean

  constructor() { }

  ngOnInit(): void {
  }

}
