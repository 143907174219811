import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Oficina } from '../models/Oficina.model';


type requestBody = {
  method:string,
  data?:Oficina,
  idCompany?:string,
  id?:string
}


@Injectable({
  providedIn: 'root'
})
export class OficinaService {

    private urlOficinas:string = `${environment.apiCore}/office/`;

  constructor( private http: HttpClient ) { }

  listarOficinas(empresa:string):Observable<any> {
    const body:requestBody = { method: 'LIST', idCompany: empresa }
    return this.http.post(this.urlOficinas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarOficinasConProcesos(empresa:string):Observable<any> {
    const body:requestBody = { method: 'LIST-FOR-PROCESS', idCompany: empresa }
    return this.http.post(this.urlOficinas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  crearOficina(oficina:Oficina) {
    const body:requestBody = { method: 'POST', data: oficina }
    return this.http.post(this.urlOficinas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerOficina(id:string):Observable<Oficina> {
    const body:requestBody = { method: 'GET', id: id }
    return this.http.post(this.urlOficinas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarOficina(oficina:Oficina) {
    const body:requestBody = { method: 'PUT', data: oficina }
    return this.http.post(this.urlOficinas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  habilitarOficina(id:string) {
    const body:requestBody = { method: 'PATCH', id: id }
    return this.http.post(this.urlOficinas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  deshabilitarOficina(id:string) {
    const body:requestBody = { method: 'DELETE', id: id }
    return this.http.post(this.urlOficinas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

}
