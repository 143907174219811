import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Receptor } from '../models/Receptor.model';


type requestBody = {
  method: string
  data?: Receptor,
  id?: string,
  idCompany?: string
}


@Injectable({
  providedIn: 'root'
})
export class ReceptorService {

  private urlReceptores:string = `${environment.apiCore}/receptor/`;

  constructor( private http: HttpClient ) { }

  listarReceptores(empresa:any):Observable<any> {
    const body:requestBody = { method: 'LIST', idCompany:empresa }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarReceptoresActivosId(empresa:any):Observable<any> {
    const body:requestBody = { method: 'LIST-ACTIVE', idCompany:empresa }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarReceptoresActivos():Observable<any> {
    const body:requestBody = { method: 'LIST-ACTIVE' }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  crearReceptor(receptor:Receptor) {
    const body:requestBody = { method: 'POST', data: receptor }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerReceptor(id:string):Observable<Receptor> {
    const body:requestBody = { method: 'GET', id: id }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarReceptor(receptor:Receptor) {
    const body:requestBody = { method: 'PUT', data: receptor }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  deshabilitarReceptor(id:string) {
    const body:requestBody = { method: 'DELETE', id:id }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  habilitarReceptor(id:string) {
    const body:requestBody = { method: 'PATCH', id:id }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  RestablecerContrasena(login:string, sub:string) {
    const body:requestBody = {
      method:"RESTORE-PASSWORD",
      data:{
        user:login,
        subdomain:sub
      }
    }
    return this.http.post(this.urlReceptores ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  ModificarOnboarding(login:string, sub:string) {
    const body:requestBody = {
      method:"MODIFY-ONBOARDING",
      data:{
        user:login,
        subdomain:sub
      }
    }
    return this.http.post(this.urlReceptores ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarOficinasPorUsuario(empresa:any, subdomain:string, login:string):Observable<any> {
    const body:requestBody = { method:"GET-OFFICES-USER", data:{idCompany:empresa, subdomain:subdomain, email:login} }
    return this.http.post(this.urlReceptores ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  validarLimiteUsuarios(empresa:any, subdomain:string):Observable<any> {
    const body:requestBody = { method:"VALIDATE-USERS-LIMIT", data:{idCompany:empresa, subdomain:subdomain} }
    return this.http.post(this.urlReceptores ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  validarRolesUsuario(email:any, subdomain:string):Observable<any> {
    const body:requestBody = { method:"VALIDATE-ROLE-USER", data:{email:email, subdomain:subdomain} }
    return this.http.post(this.urlReceptores ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  modificarTermsUsuario(login:string, sub:string, val:string) {
    const body:requestBody = { method:"MODIFY-TERMS", data:{user:login,subdomain:sub, terms:val} }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarFechaContraseña(login:string, sub:string) {
    const body:requestBody = { method:"UPDATE-DATE-PASSWORD", data:{user:login,subdomain:sub} }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }
  validarCaducidadContraseña(login:string, sub:string) {
    const body:requestBody = { method:"VALIDATE-EXPIRY-PASSWORD", data:{user:login,subdomain:sub} }
    return this.http.post(this.urlReceptores, body, { headers: { "x-api-key": environment.xApiKey } })
  }
  

}