import { Component, OnInit } from '@angular/core';
//import * as smartsupp from 'smartsupp-widget';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'noe';
  
  constructor() {}

  ngOnInit(): void {
    //this.chatBot();
  }
  
  /*chatBot() {
    smartsupp.init('a466c8c9ed0655f5cd74864163489ddfc099043d', {
        //hideWidget: true,
    })
  }*/
}
