import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Opcion } from '../models/Opcion.model';
// import { Perfil } from '../models/Perfil.model';


type requestBody = {
  method: string
  data?: any,
  id?: string
}


@Injectable({
  providedIn: 'root'
})
export class OpcionesService {

  private urlOpciones:string = `${environment.apiCore}/option/`;

  constructor( private http: HttpClient ) { }

  listarOpciones():Observable<any> {
    const body:requestBody = { method: 'LIST' }
    return this.http.post(this.urlOpciones, body, { headers: { "x-api-key": environment.xApiKey } })
  }
}
