import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-picker-calendar',
  templateUrl: './picker-calendar.component.html',
  styleUrls: ['./picker-calendar.component.scss']
})
export class PickerCalendarComponent implements OnInit {

  @Input('refDate') set refDate (value:Date) { if (value) this.setToday(value) }
  @Input('nav') nav:boolean

  _min: Date
  _max: Date
  @Input() set min (value: Date) { if (value) {
    this._min = new Date(value.getFullYear(), value.getMonth(), value.getDate())
    this.setMinMax()
    this.setDate(this.today)
  } }
  @Input() set max (value: Date) { if (value) {
    this._max = new Date(value.getFullYear(), value.getMonth(), value.getDate())
    this.setMinMax()
    this.setDate(this.today)
  } }

  @Output()
  onnav: EventEmitter<string> = new EventEmitter()
  @Output()
  onselect: EventEmitter<Date> = new EventEmitter()

  rangeDateTime: number[] = []
  range: Date[] = []

  constructor() { }

  currentYear:number
  currentMonth:number
  currentDays:any[] = []

  prevDays:any[] = []
  nextDays:any[] = []

  view:number
  today:Date
  todayTime:number

  ngOnInit(): void {
    this.setMinMax()
    this.listYears()
  }

  setToday(date:Date) {
    this.today = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    this.setDate(this.today)
  }

  months:string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  weekDays:string[] = ['DOM', 'LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB'];
  years:number[][] = [];
  visibleYears:number[] = [];

  minTime:number
  maxTime:number
  setMinMax() {
    if (this._min) this.minTime = this._min.getTime()
    if (this._max) this.maxTime = this._max.getTime()
  }

  listYears() {
    const min:number = 1900
    const years:number[][] = []

    for (let i = 0; i < 11; i ++) {
      const page:number [] = []
      for (let j = 0; j < 30; j ++) {
        page.push(min + j + (i*30))
      }
      years.push(page)
    }
    
    this.years = [...years]
  }
  
  setDate(date:Date):void {

    this.prevDays = []

    this.currentYear = date.getFullYear()
    this.currentMonth = date.getMonth()

    const firstDay:Date = new Date(this.currentYear, this.currentMonth, 1)
    const lastDay:Date = new Date(this.currentYear, this.currentMonth + 1, 0)

    this.populateCurrentDays(lastDay.getDate())

    if (firstDay.getDay() != 0) this.populatePrevDays(firstDay.getDay())
    this.populateNextDays()
    this.populateVisibleYears()
  }

  populateCurrentDays(days:number):void {

    const daysInMonth:any[] = []

    for (let i = 1; i <= days; i ++) {

      const date:Date = new Date(this.currentYear, this.currentMonth, i)
      const dateTime:number = date.getTime()
      daysInMonth.push({
        day:i, 
        date: date, 
        // today: this.todayTime === dateTime, 
        selected: (this.rangeDateTime[0] && this.rangeDateTime[1]) && (this.rangeDateTime[0] === dateTime || this.rangeDateTime[1] === dateTime),
        range: (this.range[0] && this.range[1]) && (date > this.range[0] && date < this.range[1]),
        disabled: dateTime < this.minTime || dateTime > this.maxTime,
        time: dateTime
      })

    }
    this.currentDays = [...daysInMonth]
  }

  populatePrevDays(diff:number):void {

    const days:any[] = []
    const lastDateOfPreviousMonth:number = new Date(this.currentYear, this.currentMonth, 0).getDate()

    for (let i = 1; i <= diff; i ++) {

      // const date:Date = new Date(this.currentYear, this.currentMonth-1, (lastDateOfPreviousMonth-diff+i))
      // const dateTime:number = date.getTime()
      days.push({
        // day: lastDateOfPreviousMonth - diff + i, 
        // date: date,
        // // today: this.todayTime === dateTime,
        // selected: (this.rangeDateTime[0] && this.rangeDateTime[1]) && (this.rangeDateTime[0] === dateTime || this.rangeDateTime[1] === dateTime),
        // range: (this.range[0] && this.range[1]) && (date > this.range[0] && date < this.range[1]),
        // disabled: dateTime < this.minTime || dateTime > this.maxTime,
        // time: dateTime
      })

    }
    this.prevDays = [...days]
  }

  populateNextDays():void {

    const diff:number = 42 - this.currentDays.length - this.prevDays.length
    const days:any[] = []

    for (let i = 1; i <= diff; i ++) {

      // const date:Date = new Date(this.currentYear, this.currentMonth+1, i)
      // const dateTime:number = date.getTime()
      days.push({
        // day:i, 
        // date: date, 
        // // today: this.todayTime === dateTime,
        // selected: (this.rangeDateTime[0] && this.rangeDateTime[1]) && (this.rangeDateTime[0] === dateTime || this.rangeDateTime[1] === dateTime),
        // range: (this.range[0] && this.range[1]) && (date > this.range[0] && date < this.range[1]),
        // disabled: dateTime < this.minTime || dateTime > this.maxTime,
        // time: dateTime
      })

    }
    this.nextDays = [...days]
  }

  currentPage:number
  populateVisibleYears() {

    let page:number = 0
    for (const p of this.years) {
      const year = p.find(y => y === this.currentYear)
      if (year) {
        this.currentPage = page
        break
      }
      page ++
    }
  }

  setMonth(i:number):void {
    this.setDate(new Date(this.currentYear, i, 1))
  }

  selectDate(date:any) {
    date.selected = true
    this.onselect.emit(date.date)
  }

  colorRange() {
    this.prevDays.forEach(d => {d.range = d.date > this.range[0] && d.date < this.range[1]})
    this.currentDays.forEach(d => {d.range = d.date > this.range[0] && d.date < this.range[1]})
    this.nextDays.forEach(d => {d.range = d.date > this.range[0] && d.date < this.range[1]})
  }

  setSelected() {
    this.prevDays.forEach(d => {d.selected = d.time === this.rangeDateTime[0] || d.time === this.rangeDateTime[1]})
    this.currentDays.forEach(d => {d.selected = d.time === this.rangeDateTime[0] || d.time === this.rangeDateTime[1]})
    this.nextDays.forEach(d => {d.selected = d.time === this.rangeDateTime[0] || d.time === this.rangeDateTime[1]})
  }

  deselectAll() {
    this.prevDays.forEach(d => {d.selected = false; d.range = false})
    this.currentDays.forEach(d => {d.selected = false; d.range = false})
    this.nextDays.forEach(d => {d.selected = false; d.range = false})
  }

}
