import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Usuario } from '../models/Usuario.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  private urlUsuarios:string = `${environment.api}/users/`;

  constructor( private http: HttpClient ) { }

  listarUsuarios(empresa:string, cuenta:string):Observable<any> {
    return this.http.post(this.urlUsuarios, {method: 'LIST', idCompany:empresa, cuenta:cuenta}, { headers: { "x-api-key": environment.xApiKey } })
  }

  crearUsuario(body:Usuario) {
    return this.http.post(this.urlUsuarios+'add_user', body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerUsuario(login:string, sub:string):Observable<Usuario> {
    return this.http.get(this.urlUsuarios + login + '/' + sub, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarUsuario(login:string, sub:string, body:Usuario) {
    return this.http.put(this.urlUsuarios + login + '/' + sub, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  RestablecerContrasena(login:string, sub:string) {
    return this.http.patch(this.urlUsuarios + login + '/' + sub, {method: 'RESTORE-PASSWORD'}, { headers: { "x-api-key": environment.xApiKey } })
  }

  habilitarUsuario(login:string, sub:string) {
    return this.http.patch(this.urlUsuarios + login + '/' + sub, {method: 'USER-ENABLE'}, { headers: { "x-api-key": environment.xApiKey } })
  }

  deshabilitarUsuario(login:string, sub:string) {
    return this.http.delete(this.urlUsuarios + login + '/' + sub, { headers: { "x-api-key": environment.xApiKey } })
  }
}
