import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor() { }

  private key:string = 'SAVED_TITLE';
  title:string = localStorage.getItem(this.key)

  setTitle(title:string):void {
    localStorage.setItem(this.key, title)
    this.title = title
  }
}
