import { Action } from "@ngrx/store";

export const NO_FACE_AREA = '[Liveness Detection] No Face Area';
export const FACE_AREA = '[Liveness Detection ] Face Area';
export const NOSE_AREA = '[Liveness Detection] Nose Area';
export const FAIL_DETECTION = '[Liveness Detection] Fail Detection';
export const SUCCESS_DETECTION = '[Liveness Detection] Success Detection';
export const RESET = '[Liveness Detection] Reset';

export class NoFaceAreaAction implements Action {
    readonly type = NO_FACE_AREA;
}

export class FaceAreaAction implements Action {
    readonly type = FACE_AREA;
}

export class NoseAreaAction implements Action {
    readonly type = NOSE_AREA;
}

export class FailAction implements Action {
    readonly type = FAIL_DETECTION;
}

export class SuccessAction implements Action {
    readonly type = SUCCESS_DETECTION;
}

export class ResetAction implements Action {
    readonly type = RESET;
}

export type LiveDetectionAction =
    | NoFaceAreaAction
    | FaceAreaAction
    | NoseAreaAction
    | FailAction
    | SuccessAction
    | ResetAction;

