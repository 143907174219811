import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'capitalizeName' })
export class CapitalizeNamePipe implements PipeTransform {

    /**
     * Capitalize the first letter of the name in each word of the full name.
     */
    transform(value: string, ...args: any[]): string {
        if (value === null) {
            return 'Not assigned';
        }
    
        return value
            .toLowerCase()
            .replace(/\b(\w)/g, (letter) => letter.toUpperCase());
    }
}