import { Component, OnInit, HostListener, Self, Optional, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { formatDate } from '@angular/common';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {

  @Input() label:string
  @Input() required:boolean
  @Input() readonly:boolean
  @Input() inline:boolean
  @Input('mode') mode: 'day' | 'month' | 'year' = 'day'

  _min: Date
  _max: Date
  @Input() set min (value: Date) { if (value) this._min = new Date(value.getFullYear(), value.getMonth(), value.getDate()) }
  @Input() set max (value: Date) { if (value) this._max = new Date(value.getFullYear(), value.getMonth(), value.getDate()) }

  @Output() 
  selection: EventEmitter<any> = new EventEmitter()

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl) {
      if (this.ngControl) { this.ngControl.valueAccessor = this }
    }

  // private _value:any
  onChangeCallback: any = () => {}
  onTouchedCallback: any = () => {}

  writeValue(value:any) {

    if (typeof value === 'string' || value === null) {
      this.selectedDate = null
      this.selectedDateTime = null
      this.selectedFormat = null
    } else {
      if (value && value.getMonth) {
        this.programmaticSet(value)
      }
    }
    
    this.onChangeCallback(value)

  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn
  }
  setDisabledState(disabled:boolean) {
    // this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', disabled);
  }

  currentYear:number
  currentMonth:number
  currentDays:any[] = []

  prevDays:any[] = []
  nextDays:any[] = []

  view:number
  today:Date
  todayTime:number

  show:boolean

  @HostListener('document:click') clickout() {
    if (this.show) this.onTouchedCallback()
    this.close()
  }

  ngOnInit(): void {
    
    this.view = this.mode === 'year' ? 2 : this.mode === 'month' ? 1 : 0
    const today = new Date()
    this.today = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    this.todayTime = this.today.getTime()

    this.setMinMax()

    this.listYears()
    this.setDate(this.today)
  }

  months:string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  weekDays:string[] = ['DOM', 'LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB'];
  years:number[][] = [];
  visibleYears:number[] = [];

  minTime:number
  maxTime:number
  setMinMax() {
    if (this._min) this.minTime = this._min.getTime()
    if (this._max) this.maxTime = this._max.getTime()
  }

  listYears() {
    const min:number = 1900
    const years:number[][] = []

    for (let i = 0; i < 11; i ++) {
      const page:number [] = []
      for (let j = 0; j < 30; j ++) {
        page.push(min + j + (i*30))
      }
      years.push(page)
    }
    
    this.years = [...years]
  }
  
  setDate(date:Date):void {

    this.prevDays = []

    this.currentYear = date.getFullYear()
    this.currentMonth = date.getMonth()

    const firstDay:Date = new Date(this.currentYear, this.currentMonth, 1)
    const lastDay:Date = new Date(this.currentYear, this.currentMonth + 1, 0)

    this.populateCurrentDays(lastDay.getDate())

    if (firstDay.getDay() != 0) this.populatePrevDays(firstDay.getDay())
    this.populateNextDays()
    this.populateVisibleYears()
  }

  populateCurrentDays(days:number):void {

    const daysInMonth:any[] = []

    for (let i = 1; i <= days; i ++) {

      const date:Date = new Date(this.currentYear, this.currentMonth, i)
      const dateTime:number = date.getTime()
      daysInMonth.push({
        day:i, 
        date: date, 
        today: this.todayTime === dateTime, 
        selected: this.selectedDateTime === dateTime,
        disabled: dateTime < this.minTime || dateTime > this.maxTime
      })

    }
    this.currentDays = [...daysInMonth]
  }

  populatePrevDays(diff:number):void {

    const days:any[] = []
    const lastDateOfPreviousMonth:number = new Date(this.currentYear, this.currentMonth, 0).getDate()

    for (let i = 1; i <= diff; i ++) {

      const date:Date = new Date(this.currentYear, this.currentMonth-1, (lastDateOfPreviousMonth-diff+i))
      const dateTime:number = date.getTime()
      days.push({
        day: lastDateOfPreviousMonth - diff + i, 
        date: date,
        today: this.todayTime === dateTime,
        selected: this.selectedDateTime === dateTime,
        disabled: dateTime < this.minTime || dateTime > this.maxTime
      })

    }
    this.prevDays = [...days]
  }

  populateNextDays():void {

    const diff:number = 42 - this.currentDays.length - this.prevDays.length
    const days:any[] = []

    for (let i = 1; i <= diff; i ++) {

      const date:Date = new Date(this.currentYear, this.currentMonth+1, i)
      const dateTime:number = date.getTime()
      days.push({
        day:i, 
        date: date, 
        today: this.todayTime === dateTime,
        selected: this.selectedDateTime === dateTime,
        disabled: dateTime < this.minTime || dateTime > this.maxTime
      })

    }
    this.nextDays = [...days]
  }

  currentPage:number
  populateVisibleYears() {

    let page:number = 0
    for (const p of this.years) {
      const year = p.find(y => y === this.currentYear)
      if (year) {
        this.currentPage = page
        break
      }
      page ++
    }
  }


  setMonth(i:number):void {

    event.stopPropagation()

    if (this.mode !== 'month') {
      this.view = 0
      this.setDate(new Date(this.currentYear, i, 1))
    } else {
      this.selectDate({date: new Date(this.currentYear, i, 1)})
    } 
  }

  setYear(year:number):void {

    this.currentYear = year
    if (this.mode !== 'year') {
      this.view = 1
    } else {
      this.selectDate({date: new Date(this.currentYear, 0, 1)})
    }
  }

  setPage(next:boolean) {

    event.stopPropagation()

    if (next) {
      if (this.years[this.currentPage + 1]) this.currentPage ++
    } else {
      if (this.years[this.currentPage - 1]) this.currentPage --
    }
  }

  selectedDate:Date
  selectedDateTime:number
  selectedFormat:string
  selectDate(date:any) {

    this.deselectAll()
    date.selected = true
    
    this.writeValue(date.date)
    this.selection.emit(date.date)

    this.close()
  }

  programmaticSet(value:Date) {
    this.selectedDate = value
    this.selectedDateTime = value.getTime()
    if (this.mode === 'day') this.selectedFormat = formatDate(value, 'dd/MM/yyyy', 'en_US')
    else if (this.mode === 'month') this.selectedFormat = formatDate(value, 'MM/yyyy', 'en_US')
    else if (this.mode === 'year') this.selectedFormat = formatDate(value, 'yyyy', 'en_US')
  }

  deselectAll() {
    this.prevDays.forEach(d => d.selected = false)
    this.currentDays.forEach(d => d.selected = false)
    this.nextDays.forEach(d => d.selected = false)
  }


  open() {
    this.show = true
  }
  close() {
    this.show = false
  }

}
