import { Injectable, Renderer2 } from "@angular/core";

import { take } from "rxjs/operators";

import { ChallengeDetails, IFramesRequestData } from "src/app/models/Challenge.model";
import { ChallengeService } from "src/app/services/api/challenge.service";
import { LivenessDetectionConstants as Const } from './liveness-detection.constants';

@Injectable()
export class LivenessDetectionService {

    promises: Promise<void>[] = [];

    constructor(
        private renderer: Renderer2,
        private challengeService: ChallengeService
    ) {}

    uploadFrame(videoElement: HTMLVideoElement, challengeDetail: ChallengeDetails): void {
        const { id, token } = challengeDetail;
        const invisibleCanvas: HTMLCanvasElement = this.renderer.createElement('canvas');

        this.renderer.setProperty(invisibleCanvas, 'width', videoElement.width.toString());
        this.renderer.setProperty(invisibleCanvas, 'height', videoElement.width.toString());

        const context = invisibleCanvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, videoElement.width, videoElement.height);
        context.scale(-1, 1);

        this.promises.push(
            new Promise((resolve: () => void, reject: (error: Error) => void) => {
                invisibleCanvas.toBlob(
                    (blob) => {
                        blob
                            ? this.callFramesApi(id, token, blob, resolve, reject)
                            : reject(new Error('Error creating the blob from canvas'));
                    },
                    'image/jpeg',
                    Const.IMAGE_JPG_QUALITY
                );
            })
        );
    }

    callFramesApi(
        challangeId: string,
        token: string,
        blob: Blob,
        resolve: () => void,
        reject: (error: Error) => void
    ): void {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const dataUrl = reader.result as string;
            const request: IFramesRequestData = {
                timestamp: Date.now(),
                frameBase64: dataUrl.slice(dataUrl.indexOf(',') + 1),
                token
            };

            this.challengeService.saveFrames(challangeId, request)
                .pipe(take(1))
                .subscribe(() => {
                    resolve();
                }, (error: any) => {
                    reject(error);
                });
        };
    }
}