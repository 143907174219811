export namespace TopbarConstants {

    export const HEAD_PREMIUM = [
        { title: 'Especificaciones', priority: true },
        { title: 'Free', priority: true },
        { title: 'Premium', priority: true }
    ];

    export const BODY_PREMIUM = [
        [
            { contents: 'CERTIFICADO DIGITAL GRATIS' },
            { contents: 'NO' },
            { contents: 'Si' },
        ],
        [
            { contents: 'MULTIEMPRESA (COMPARTIR ENTRE RAZONES SOCIALES)' },
            { contents: 'NO' },
            { contents: 'SI' },
        ],
        [
            { contents: 'CREACIÓN DE USUARIOS' },
            { contents: 'NO' },
            { contents: 'ILIMITADO' },
        ],
        [
            { contents: 'FLUJOS DE FIRMAS Y NOTIFICACIONES' },
            { contents: 'NO' },
            { contents: 'SI' },
        ],
        [
            { contents: 'FIRMA ELECTRÓNICA (TOKEN, HOLÓGRAFA FIRMA FACIAL, HUELLA BIOMÉTRICA)' },
            { contents: 'SOLO FIRMA DIGITAL (CERTIFICADO DIGITAL)' },
            { contents: 'SI' },
        ],
        [
            { contents: 'PERSONALIZACIONES (COLORES CORPORATIVOS, PLANTILLAS DE CORREO)' },
            { contents: 'NO' },
            { contents: 'SI' },
        ],
        [
            { contents: 'APP MOVIL' },
            { contents: 'NO' },
            { contents: 'SI' },
        ],
        [
            { contents: 'RÚBRICA EN TODAS LAS HOJAS' },
            { contents: 'NO' },
            { contents: 'SI' },
        ],
        [
            { contents: 'TIPO DE NOTIFICACIONES (CORREO, SMS, PUSH MOVIL)' },
            { contents: 'CORREO' },
            { contents: 'MULTIPLES' },
        ],
        [
            { contents: 'DOCUMENTOS DISPONIBLES' },
            { contents: 'Hasta 50' },
            { contents: 'Desde 500' },
        ],
        [
            { contents: 'TAMAÑO DE ARCHIVOS SOPORTADO' },
            { contents: 'Hasta 5 MB' },
            { contents: 'Hasta 100 MB' },
        ],
        [
            { contents: 'ALMACENAMIENTO' },
            { contents: '1 AÑO' },
            { contents: '5 AÑOS' },
        ],
        [
            { contents: 'SOPORTE EXCLUSIVO' },
            { contents: 'NO' },
            { contents: 'ESTÁNDAR' },
        ],
        [
            { contents: 'INTEGRACIÓN API' },
            { contents: 'NO' },
            { contents: 'SI' },
        ],
        [
            { contents: 'SELLO DE TIEMPO' },
            { contents: 'NO' },
            { contents: 'SI' },
        ]
    ];
}