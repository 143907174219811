<input type="file" #fileInputMulti multiple (change)="change($event)">
<input type="file" #fileInput (change)="change($event)">

<div [class.less-padding]="lessPadding" class="dropzone" (click)="handleClick()" (drop)="drop($event)" (dragover)="allowDrop($event)">

    <div class="text">
        <i class="las la-file-invoice"></i>
        {{placeholder}}
    </div>

</div>
