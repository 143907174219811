<div class="sign-overlay">

    <div class="viewer-container">

        <ng-container *ngIf="_masivo">
            <div class="document-selector">
                <span class="selected-doc" (click)="documentListShown=!documentListShown">
                    <b>DOCUMENTO</b>
                    {{pdfList[masivoCurrent].documentName}}
                </span>

                <div class="info">
                    {{masivoCurrent+1}} / {{pdfList.length}}
                </div>

                <button [class.disabled]="masivoCurrent===0" (click)="navegarMasivo('prev')"><i class="las la-angle-left"></i></button>
                <button [class.disabled]="masivoCurrent===pdfList.length-1" (click)="navegarMasivo('next')"><i class="las la-angle-right"></i></button>

                <div class="document-list" [class.open]="documentListShown">
                    <ul>
                        <li *ngFor="let doc of pdfList; index as i" [class.active]="masivoCurrent === i" (click)="masivoCurrent = i">{{doc.documentName}}</li>
                    </ul>
                </div>
            </div>
        </ng-container>

        <div class="instructions" *ngIf="!hayFirmas">Haz click en el documento para agregar la firma seleccionada</div>

        <!--<div class="instructions">
            <div class="document-selector">
                <div class="info">
                    <button [class.disabled]="masivoCurrent===0" (click)="currentPage.value=' '; navegarMasivo('prev')"><i class="las la-angle-left"></i></button>
                    <input class="text-center" id="currentPage" #currentPage onkeypress="this.style.width = (this.value.length + 1) + 'em';" 
                    value="{{masivoCurrent+1}}" (keydown.enter)="sendValue(currentPage.value)" type="text"/>/ {{pdfList.length}}
                    <button [class.disabled]="masivoCurrent===pdfList.length-1" (click)="currentPage.value=' '; navegarMasivo('next')"><i class="las la-angle-right"></i></button>
                </div>
            </div>
        </div>-->

        <div class="viewer" #viewer>

            <ng-container *ngIf="_masivo">
                <ng-container *ngFor="let documento of pdfList; index as i;">
                    <ng-container *ngIf="masivoCurrent === i">
                        <div class="document-container is-masivo" *ngFor="let img of documento.imagenes; index as j;">
                            <div class="document">

                                <app-s3-image [key]="img" (click)="addFirma($event, i, j)"></app-s3-image>

                                <div *ngIf="!checkPaginas">
                                    <div *ngFor="let f of firmasMasivo[i][j]; index as k;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">

                                        <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>

                                        <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">

                                        <div class="remove" (click)="remove(i,j,k)">
                                            <i class="las la-times"></i>
                                        </div>

                                        <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                        <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                        <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                                    </div>
                                </div>




                                <div *ngIf="checkPaginas">
                                    <ng-container *ngIf="!isMassiveStamped(documento, j)">
                                        <div *ngFor="let g of iterar">
                                            <div *ngFor="let f of firmasMasivo[i][g]; index as k;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">

                                                <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>

                                                <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">

                                                <div class="remove" (click)="remove(i,j,k)">
                                                    <i class="las la-times"></i>
                                                </div>

                                                <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                                <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                                <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!_masivo">
                <div class="document-container no-masivo" *ngFor="let img of files; index as i;">
                    <div class="document">

                        <app-s3-image [key]="img" (click)="addFirma($event, i)"></app-s3-image>

                        <div *ngIf="!checkPaginas">

                            <div *ngFor="let f of firmas[i]; index as j;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">

                                <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>

                                <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">

                                <div class="remove" (click)="remove(i,j)">
                                    <i class="las la-times"></i>
                                </div>

                                <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                            </div>

                        </div>



                        <div *ngIf="checkPaginas" class="all-pages">
                            <ng-container *ngIf="!isStamped(i)">
                                <div *ngFor="let k of iterar">
                                    <div *ngFor="let f of firmas[k]; index as j;" class="firma" [style.top.px]="f.y" [style.left.px]="f.x">

                                        <div class="drag-overlay" (mousedown)="onWindowPress($event,f)" (mousemove)="onWindowDrag($event)"></div>

                                        <img [src]="f.src" [style.height.px]="f.height" [style.width.px]="f.width">

                                        <div class="remove" (click)="remove(i,j)">
                                            <i class="las la-times"></i>
                                        </div>

                                        <div class="resize top-left" (mousedown)="onCornerClick($event, f, 'topLeftResize')"></div>
                                        <div class="resize bottom-left" (mousedown)="onCornerClick($event, f, 'bottomLeftResize')"></div>
                                        <div class="resize bottom-right" (mousedown)="onCornerClick($event, f, 'bottomRightResize')"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>



                    </div>
                </div>
            </ng-container>

        </div>
    </div>

    <div class="actions">

        <ng-container *ngIf="firmasUsuario.length === 0">
            <div class="empty">
                <h4>No hay firmas registradas</h4>
                <p>Haga click en el menú para dibujar o cargar una nueva firma holográfica</p>
            </div>
        </ng-container>

        <div class="sign-actions">
            <button class="btn outline" (click)="modalCargar.open()">Cargar Firma</button>
            <button class="btn outline" (click)="modalDibujar.open()">Dibujar Firma</button>

        </div>


        <hr class="dashed">
        <p class="text">
            <span [class.checked]="checkPaginas" class="check-box" (click)="handleCheck()"></span> Firmar en todas las hojas
        </p>
        <ng-container *ngIf="checkPaginas">
            <!--<app-input type="select" label="" [selectOptions]="tipos" [selectOptionsName]="idNameCombo" [selectOptionsKey]="idKeyCombo" [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()"></app-input>-->
            <select [(ngModel)]="comboPersonalizacionSelect" (change)="getPersonalizar()">
               
                <option *ngFor="let o of options">
                   {{o.name}}
                </option>
             </select>

        </ng-container>

        <ng-container *ngIf="verNumeroPaginas">
            <app-input type="text" label="Número Páginas" [(ngModel)]="txtNumeroPaginas"></app-input>
        </ng-container>



        <ng-container *ngIf="!loadingFirmas && firmasUsuario.length > 0">

            <app-input type="select" label="Seleccionar Firma Existente" [selectOptions]="firmasUsuario" [selectOptionsName]="'title'" [selectOptionsKey]="'route'" [(ngModel)]="firmaSeleccionada" (change)="current.ngOnInit()"></app-input>

            <app-s3-image #current *ngIf="firmasUsuario.length > 0" [key]="firmaSeleccionada"></app-s3-image>

        </ng-container>


        <app-loader *ngIf="loadingFirmas" [big]="true"></app-loader>

        <!-- <app-input 
        type="checkbox" 
        label="Acepto los términos y condiciones" 
        [terms]="true" 
        [(ngModel)]="accept"></app-input> -->

        <!--<p class="text-center">
            <span [class.checked]="accept" class="check-box" (click)="handleAccept()"></span>
            <em>Acepto los términos y condiciones</em>
        </p>-->





        <div class="text-center">
            <button class="btn success" (click)="enviar()" [class.loading]="loaderFirmar" [class.disabled]="!accept || !hayFirmas">{{mensaje}}</button>
        </div>
    </div>

</div>


<!--<app-modal #modalTerminos titleModal="Términos y Condiciones" [loading]="cargarLoadingFirma" (onconfirm)="accept = true" [scroller]="true">
    <app-terminos-y-condiciones></app-terminos-y-condiciones>
</app-modal>-->

<app-modal #modalCargar titleModal="Cargar Firma" [loading]="cargarLoadingFirma" (onconfirm)="cargarFirma()"
    (onclose)="reset()" [closeOnConfirm]="false" [disableConfirm]="!title || !preview">
    <h4>Indicaciones:</h4>
    <p><em>- Tamaño:273 x 185
            - Peso: 50KB
            - Formato: PNG, JPG</em></p>

    <div class="drop" *ngIf="!preview">
        <app-dropzone #drop (onconvert)="setPreview($event)" (ondrop)="blob=$event"></app-dropzone>
    </div>

    <div class="preview" *ngIf="preview">
        <button class="remove" (click)="setPreview(null)"><i class="las la-times"></i></button>
        <img [src]="preview">
    </div>

    <app-input type="text" label="Nombre" [(ngModel)]="title"></app-input>

    <app-input type="textarea" label="Descripción" [(ngModel)]="description"></app-input>

</app-modal>

<app-modal #modalDibujar titleModal="Dibujar Firma" [loading]="cargarLoadingFirma" (onconfirm)="cargarFirma()" (onclose)="reset()" [closeOnConfirm]="false" [disableConfirm]="!baseFirma || !title">

    <div class="pad-container">
        <signature-pad #signature (drawEnd)="save()"></signature-pad>
        <button class="clear" (click)="clear()"><i class="las la-times"></i> Limpiar</button>
    </div>

    <app-input type="text" label="Nombre" [(ngModel)]="title"></app-input>

    <app-input type="textarea" label="Descripción" [(ngModel)]="description"></app-input>

</app-modal>

<app-modal #modalDibujar2 class="modalDibujar" titleModal="Cargar nueva firma" [hide-footer]="true" [loading]="cargarLoadingFirma"
    (onclose)="reset()" [disableConfirm]="!titleDraw && !titleWrite && !titleUpload">

    <mat-tab-group>
        <mat-tab label="Dibujar">
            <div class="pad-container" (click)="tabs(1)">
                <signature-pad #signature (drawEnd)="save()"></signature-pad>
                <button class="clear" (click)="clear()"><i class="las la-times"></i> Limpiar</button>
            </div>

            <app-input type="text" label="Nombre" [(ngModel)]="titleDraw"></app-input>

            <app-input type="textarea" label="Descripción" [(ngModel)]="descriptionDraw"></app-input>
        </mat-tab>

        <mat-tab label="Escribir">
            <div class="pad-container" (click)="tabs(2)">
                <textarea [(ngModel)]="signWrite" name="textarea" rows="10" cols="50" placeholder="Escriba aquí"></textarea>
                <button class="clear" (click)="clear()"><i class="las la-times"></i> Limpiar</button>
            </div>

            <app-input type="text" label="Nombre" [(ngModel)]="titleWrite"></app-input>

            <app-input type="textarea" label="Descripción" [(ngModel)]="descriptionWrite"></app-input>

        </mat-tab>

        <mat-tab label="Subir">

            <h4>Indicaciones:</h4>
            <p><em>- Tamaño:273 x 185
                    - Peso: 50KB
                    - Formato: PNG, JPG</em></p>

            <div class="drop" *ngIf="!preview" (click)="tabs(3)">
                <app-dropzone #drop (onconvert)="setPreview($event)" (ondrop)="blob=$event"></app-dropzone>
            </div>

            <div class="preview" *ngIf="preview" (click)="tabs(3)">
                <button class="remove" (click)="setPreview(null)"><i class="las la-times"></i></button>
                <img [src]="preview">
            </div>

            <app-input type="text" label="Nombre" [(ngModel)]="titleUpload"></app-input>
            <app-input type="textarea" label="Descripción" [(ngModel)]="descriptionUpload"></app-input>
        </mat-tab>
    </mat-tab-group>
    <div class="form-footer">
        <button class="btn success btn-cancelar" type="button" (click)="this.modalDibujar.close(); reset();">Cancelar</button>
        <button class="btn success" type="button" (click)="cargarFirma()">Agregar Rubrica</button>
        <!--<button class="btn default disabled loading" type="button"> Eliminar </button>-->
    </div>
</app-modal>

<app-modal #modalCambiarmeCuentaPago titleModal="CAMBIARME A CUENTA PREMIUM" [hide-footer]="true">
    <em>Sólo disponible para Versión premium. Quiero que me contacten</em>
    <div class="form-footer">
        <button class="btn success" (click)="aceptarCambiarmeCuentaPago()" [class.loading]="modalCambiarmeCuentaPagoLoading">Quiero que me contacten</button>
    </div>

</app-modal>