import { Component, Input, ViewEncapsulation } from "@angular/core";

type Position = 'above' | 'right' | 'below' | 'left';

@Component({
    selector: '[appTooltip]',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    host: { class: 'app-tooltip' }
})
export class TooltipComponent {
    
    @Input('appTooltip') tooltipText: string;
    @Input('appTooltipPosition') tooltipPosition: Position = 'above';
}