<div class="notifications" [class.d-none]="notify.notifications.length == 0">

    <div *ngFor="let n of notify.notifications; index as i" (mouseenter)="notify.enter(i)" (mouseleave)="notify.leave(i)"
      class="notify slide {{ n.tipo }}" [class.down]="n.show">
  
      <!-- <ng-container *ngIf="n.tipo != 'message' && n.tipo != 'contrato' && n.tipo != 'formulario' else mensaje"> -->
      <!-- <div class="d-flex"> -->
        <div class="icon">
        </div>
        <div class="text">
          {{ n.mensaje }}
        </div>
        <div class="dismiss" (click)="notify.close(i)">
          <i class="las la-times"></i>
        </div>
      <!-- </div> -->
      <!-- </ng-container> -->
  
    </div>
  
</div>