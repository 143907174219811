<aside class="sidebar sidebar-frente" [class.disabled]="selectedMessage">

    <header class="site-logo" [class.free]="esGratis">
        <span class="icon" #recibirValorBloquear appTooltip="Ir al dashboard" appTooltipPosition="right" (click)="irDashboard()" >
            <i class="las la-home"></i>
        </span>
        <span class="site-logo1__logo-container">
            <img 
                [src]="logoSidebar"
                alt="NOE - Notificaciones Electrónicas"
            >
        </span>
        <div class="free-icon tag success" style="background-color: #27bb62c4;color: white;" *ngIf="esGratis">VERSION GRATUITA</div>
    </header>

    <!-- Usuarios -->
    <ng-container *ngIf="rol !== 'receptor' && esGratis">
        <ng-container *ngFor="let modulo of opciones; index as i;">

            <div class="aside-section">
                <!--<div class="aside-section" *ngIf="modulo.enabled">-->

                <div class="icon">
                    <i *ngIf="i === 0" class="las la-tools"></i>
                    <i *ngIf="i === 1" class="las la-pen"></i>
                    <i *ngIf="i === 2" class="las la-user-cog"></i>
                    <i *ngIf="i === 3" class="las la-bell"></i>
                    <i *ngIf="i === 4" class="las la-user-friends"></i>
                </div>

                <div class="element">
                    <h3 class="aside-title" appMenuDropdown>
                        <!-- {{modulo.name}}-->
                        <div *ngIf="(modulo.name != 'administrador' || modulo.name != 'notificaciones' || modulo.name != 'mantenimiento') ">
                            {{modulo.name}}&nbsp;

                        </div>
                        <div *ngIf="(modulo.name == 'administrador' || modulo.name == 'notificaciones') && esGratis">
                            <em class="free-icon tag success" style="background-color: #27bb62c4;color: white;">premium</em>
                        </div>



                        <div *ngIf="(modulo.name != 'administrador' && modulo.name != 'notificaciones')" class="aside-title__button">
                            <span class="arrow"></span>
                        </div>

                    </h3>



                    <ul *ngIf="modulo.list_options.length > 0" (click)="$event.stopPropagation();">
                        <ng-container *ngFor="let hijo of modulo.list_options">
                            <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000104' ">
                                <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                        {{hijo.name}} 
                                    </a>
                            </li>
                            <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000301'">
                                <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                        {{hijo.name}} 
                                    </a>
                            </li>
                            <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child !== '0000104' && hijo.code_child !== '0000301'">
                                <a (click)="onnavigation.emit()" [routerLink]="'/' + modulo.action + '/' + hijo.action" git routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                                        {{hijo.name}} 
                                        <span 
                                        
                                            *ngIf="
                                                (hijo.code_child == '0000202' && hayPendientes) ||
                                                (hijo.code_child == '0000204' && hayEnProceso) ||
                                                (hijo.code_child == '0000402' && porNotificar)" 
                                            app-dot-new
                                        ></span>
                                    </a>
                            </li>
                        </ng-container>
                    </ul>

                </div>

            </div>
        </ng-container>
    </ng-container>

    <!-- Usuarios -->
    <ng-container *ngIf="rol !== 'receptor' && !esGratis">
        <ng-container *ngFor="let modulo of opciones; index as i;">

            <!-- <div class="aside-section">-->
            <div class="aside-section" *ngIf="modulo.enabled">

                <div class="icon">
                    <i *ngIf="i === 0" class="las la-tools"></i>
                    <i *ngIf="i === 1" class="las la-pen"></i>
                    <i *ngIf="i === 2" class="las la-user-cog"></i>
                    <i *ngIf="i === 3" class="las la-bell"></i>
                    <i *ngIf="i === 4" class="las la-user-friends"></i>

                    <!--<div class="dropdown">
                        <i class="dropbtn" *ngIf="i === 0" class="las la-tools"></i>
                        <div class="dropdown-content">
                            <h3 class="aside-title" appMenuDropdown>
                                {{modulo.name}}
        
                                <div class="aside-title__button">
                                    <span class="arrow"></span>
                                </div>
                            </h3>

                            <ul *ngIf="modulo.list_options.length > 0" (click)="$event.stopPropagation();">
                                <ng-container *ngFor="let hijo of modulo.list_options">
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000104' ">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000301'">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child !== '0000104' && hijo.code_child !== '0000301'">
                                        <a (click)="onnavigation.emit()" [routerLink]="'/' + modulo.action + '/' + hijo.action" git routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                                                {{hijo.name}} 
                                                <span 
                                                
                                                    *ngIf="
                                                        (hijo.code_child == '0000202' && hayPendientes) ||
                                                        (hijo.code_child == '0000204' && hayEnProceso) ||
                                                        (hijo.code_child == '0000402' && porNotificar)" 
                                                    app-dot-new
                                                ></span>
                                            </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>

                    <div class="dropdown">
                        <i class="dropbtn" *ngIf="i === 1" class="las la-pen"></i>
                        <div class="dropdown-content">
                            <h3 class="aside-title" appMenuDropdown>
                                {{modulo.name}}
        
                                <div class="aside-title__button">
                                    <span class="arrow"></span>
                                </div>
                            </h3>

                            <ul *ngIf="modulo.list_options.length > 0" (click)="$event.stopPropagation();">
                                <ng-container *ngFor="let hijo of modulo.list_options">
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000104' ">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000301'">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child !== '0000104' && hijo.code_child !== '0000301'">
                                        <a (click)="onnavigation.emit()" [routerLink]="'/' + modulo.action + '/' + hijo.action" git routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                                                {{hijo.name}} 
                                                <span 
                                                    *ngIf="
                                                        (hijo.code_child == '0000202' && hayPendientes) ||
                                                        (hijo.code_child == '0000204' && hayEnProceso) ||
                                                        (hijo.code_child == '0000402' && porNotificar)" 
                                                    app-dot-new
                                                ></span>
                                            </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>

                    <div class="dropdown">
                        <i class="dropbtn" *ngIf="i === 2" class="las la-user-cog"></i>
                        <div class="dropdown-content">
                            <h3 class="aside-title" appMenuDropdown>
                                {{modulo.name}}
        
                                <div class="aside-title__button">
                                    <span class="arrow"></span>
                                </div>
                            </h3>

                            <ul *ngIf="modulo.list_options.length > 0" (click)="$event.stopPropagation();">
                                <ng-container *ngFor="let hijo of modulo.list_options">
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000104' ">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000301'">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child !== '0000104' && hijo.code_child !== '0000301'">
                                        <a (click)="onnavigation.emit()" [routerLink]="'/' + modulo.action + '/' + hijo.action" git routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                                                {{hijo.name}} 
                                                <span 
                                                    *ngIf="
                                                        (hijo.code_child == '0000202' && hayPendientes) ||
                                                        (hijo.code_child == '0000204' && hayEnProceso) ||
                                                        (hijo.code_child == '0000402' && porNotificar)" 
                                                    app-dot-new
                                                ></span>
                                            </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>

                    <div class="dropdown">
                        <i class="dropbtn" *ngIf="i === 3" class="las la-bell"></i>
                        <div class="dropdown-content">
                            <h3 class="aside-title" appMenuDropdown>
                                {{modulo.name}}
        
                                <div class="aside-title__button">
                                    <span class="arrow"></span>
                                </div>
                            </h3>

                            <ul *ngIf="modulo.list_options.length > 0" (click)="$event.stopPropagation();">
                                <ng-container *ngFor="let hijo of modulo.list_options">
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000104' ">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000301'">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child !== '0000104' && hijo.code_child !== '0000301'">
                                        <a (click)="onnavigation.emit()" [routerLink]="'/' + modulo.action + '/' + hijo.action" git routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                                                {{hijo.name}} 
                                                <span  
                                                    *ngIf="
                                                        (hijo.code_child == '0000202' && hayPendientes) ||
                                                        (hijo.code_child == '0000204' && hayEnProceso) ||
                                                        (hijo.code_child == '0000402' && porNotificar)" 
                                                    app-dot-new
                                                ></span>
                                            </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>

                    <div class="dropdown">
                        <i class="dropbtn" *ngIf="i === 4" class="las la-user-friends"></i>
                        <div class="dropdown-content">
                            <h3 class="aside-title" appMenuDropdown>
                                {{modulo.name}}
        
                                <div class="aside-title__button">
                                    <span class="arrow"></span>
                                </div>
                            </h3>

                            <ul *ngIf="modulo.list_options.length > 0" (click)="$event.stopPropagation();">
                                <ng-container *ngFor="let hijo of modulo.list_options">
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000104' ">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000301'">
                                        <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                                {{hijo.name}} 
                                            </a>
                                    </li>
                                    <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child !== '0000104' && hijo.code_child !== '0000301'">
                                        <a (click)="onnavigation.emit()" [routerLink]="'/' + modulo.action + '/' + hijo.action" git routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                                                {{hijo.name}} 
                                                <span
                                                    *ngIf="
                                                        (hijo.code_child == '0000202' && hayPendientes) ||
                                                        (hijo.code_child == '0000204' && hayEnProceso) ||
                                                        (hijo.code_child == '0000402' && porNotificar)" 
                                                    app-dot-new
                                                ></span>
                                            </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>-->

                </div>

                <div class="element">
                    <h3 class="aside-title" appMenuDropdown>
                        {{modulo.name}}
                        <!-- <div *ngIf="(modulo.name != 'administrador' || modulo.name != 'notificaciones') && esGratis">
                            {{modulo.name}}&nbsp;
                        </div>
                        <div *ngIf="(modulo.name == 'administrador' || modulo.name == 'notificaciones') && esGratis">
                            <em class="free-icon tag success" style="background-color: #27bb62c4;color: white;">premium</em>
                        </div>

                        <div *ngIf="!esGratis">
                            {{modulo.name}}
                        </div>-->

                        <div class="aside-title__button">
                            <span class="arrow"></span>
                        </div>
                    </h3>

                    <ul *ngIf="modulo.list_options.length > 0" (click)="$event.stopPropagation();">
                        <ng-container *ngFor="let hijo of modulo.list_options">
                            <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000104' ">
                                <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                        {{hijo.name}} 
                                    </a>
                            </li>
                            <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child === '0000301'">
                                <a (click)="alertaAviso(modulo.action,hijo.action)" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" style="cursor:pointer;" [readonly]="true">
                                        {{hijo.name}} 
                                    </a>
                            </li>
                            <li class="nav-item" *ngIf="hijo.enabled && hijo.code_child !== '0000104' && hijo.code_child !== '0000301'">
                                <a (click)="onnavigation.emit()" [routerLink]="'/' + modulo.action + '/' + hijo.action" git routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                                        {{hijo.name}} 
                                        <span 
                                        
                                            *ngIf="
                                                (hijo.code_child == '0000202' && hayPendientes) ||
                                                (hijo.code_child == '0000204' && hayEnProceso) ||
                                                (hijo.code_child == '0000402' && porNotificar)" 
                                            app-dot-new
                                        ></span>
                                    </a>
                            </li>
                        </ng-container>
                    </ul>

                </div>

            </div>
        </ng-container>
    </ng-container>


    <!-- Receptores -->
    <ng-container *ngIf="rol === 'receptor'">
        <div class="aside-section">

            <div class="icon">
                <i class="las la-email"></i>
            </div>

            <div class="element">
                <h3 class="aside-title" appMenuDropdown>
                    Receptor
                    <div class="aside-title__button">
                        <span class="arrow"></span>
                    </div>
                </h3>

                <ul (click)="$event.stopPropagation();">
                    <li class="nav-item">
                        <a [routerLink]="'/receptor/bandeja-de-entrada'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                            Bandeja de entrada
                            <span *ngIf="hayDocumentosReceptor" app-dot-new></span>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="aside-section" *ngIf="noHayTiposDeFirma" >

            <div class="icon">
                <i class="las la-pen-nib"></i>
            </div>

            <div class="element">
                <h3 class="aside-title" appMenuDropdown>
                    Panel de Firmas
                    <div class="aside-title__button">
                        <span class="arrow"></span>
                    </div>
                </h3>

                <ul (click)="$event.stopPropagation();">
                    <li class="nav-item">
                        <a [routerLink]="'/panel-de-firmas/configurar-rúbrica'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                            Configurar Rúbrica
                        </a>
                    </li>
                </ul>
            </div>

        </div>

    </ng-container>


</aside>

<app-modal-inicio #modalInicioTerminos titleModal="Términos y Condiciones" (onconfirm)="accept = true" (click)="handleAcceptConfirm()" [scroller]="true">
    <app-terminos-y-condiciones></app-terminos-y-condiciones>        
</app-modal-inicio>

<app-modal-lectura #modalLecturaTerminos titleModal="Términos y Condiciones" [scroller]="true">
    <app-terminos-y-condiciones></app-terminos-y-condiciones>
</app-modal-lectura>

<!-- Modal Caducidad Contraseña -->
<app-modal-inicio #modalCaducidadConstrasena (onclose)="passForm.reset()" titleModal="Contraseña Caducada" [hide-footer]="true">
        <form [formGroup]="passForm" (submit)="changePassword($event)" *ngIf="passForm">
            <p>La contraseña actual a caducado, por favor realizar el cambio de su contraseña.</p><br>
            <app-input type="password" formControlName="oldPass" label="Contraseña Actual" [required]="true"></app-input>
            <app-input type="password" formControlName="password" label="Nueva Contraseña" [required]="true"></app-input>
            <app-input type="password" formControlName="confirm" label="Confirmar Contraseña" [required]="true"></app-input>
            <p class="error-message" *ngIf="passForm.errors?.mismatch">Las contraseñas deben coincidir</p>
            <app-password-validators [form]="passForm" [validators]="this.validators.validators"></app-password-validators>
    
            <div class="form-footer">
                <button *ngIf="!pLoading" class="btn" type="submit">Confirmar</button>
    
                <button *ngIf="pLoading" class="btn disabled loading" type="submit"> Confirmando </button>
            </div>
        </form>
</app-modal-inicio>


<!-- Modal Roles -->
<app-modal #modalObtenerPremium titleModal="CAMBIARME A CUENTA PREMIUM" [hide-footer]="true">

    <em>Opciones solo para la versión Pagada, solicitar ahora</em>

    <div class="form-footer">
        <button class="btn success" (click)="confirmarObtenerPremium()" [class.loading]="modalCambiarmeCuentaPagoLoading">Quiero que me contacten</button>
    </div>
</app-modal>