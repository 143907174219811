import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Auth, Storage } from 'aws-amplify';

@Component({
  selector: 'app-s3-image',
  templateUrl: './s3-image.component.html',
  styleUrls: ['./s3-image.component.scss']
})
export class S3ImageComponent implements OnInit {

  @ViewChild('el') el:ElementRef
  @Input('key') key:string

  constructor() { }

  ngOnInit(): void {
    this.getUrl()
  }

  src:any
  async getUrl() {
    const src = await Storage.get(this.key, {customPrefix: {public: ''}})
    this.src = src
  }

}
