<div style="margin:10px; text-align:justify">
    <h4>TÉRMINOS Y CONDICIONES DE USO DEL SITIO https://www.noe.pe</h4>

<hr>

<h5>1. ACEPTACIÓN DE LOS TÉRMINOS</h5>

<p>Bienvenido al Sitio Web https://www.noe.pe propiedad de “Innova Digital Solutions S.A.C.”, en adelante “La empresa”. Los servicios provistos a través de esta página se regularán por los siguientes Términos de Servicio (TS).</p>

<p>Todas las guías de uso o reglas se encuentran incorporadas por referencia en los TOS. La utilización del Sito le atribuye a usted la condición de usuario o cliente de  “La empresa” e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este TS en la versión dada por “La Empresa” en el momento mismo en que el Usuario acceda al Sitio. En consecuencia, el Usuario debe leer atentamente el presente TS en cada una de las ocasiones en que se proponga utilizar el Sitio, ya que este puede sufrir modificaciones.</p>

<p>La utilización de ciertos servicios ofrecidos a los Usuarios a través del Sito se encuentra sometida a condiciones particulares propias que, según los casos, sustituyen, completan y/o modifican el presente TS. Por lo tanto, con anterioridad a la utilización de dichos servicios, el Usuario también ha de leer atentamente las correspondientes Condiciones Particulares. Asimismo, la utilización del Sitio se encuentra sometida igualmente a todos los avisos, reglamentos de uso e instrucciones, puestos en conocimiento del Usuario por la Empresa, que sustituyen, completan y/o modifican el presente TS.</p>

<h5>2. DESCRIPCIÓN DEL SERVICIO</h5>

<p>A través del Sitio “La Empresa” facilita a los Usuarios el acceso y la utilización de diversos servicios, productos y contenidos puestos a disposición por “La Empresa” o por terceros. A menos que se indique expresamente lo contrario, cualquier nueva aplicación que aumente o mejore los Servicios actuales, incluyendo el lanzamiento de nuevas propiedades del Sitio, estará sujeta a estos TS. El Usuario conviene y acepta que “La Empresa” no será responsable por el retraso, borrado, entrega equivocada o falla al guardar cualquier comunicación del usuario, o sus criterios de personalización en virtud de que el Servicio que presta “La Empresa” está sujeto a disponibilidad.</p>

<p>Para poder utilizar el Servicio, el Usuario deberá obtener acceso al World Wide Web, ya sea directamente o por medio de algún dispositivo que tenga acceso a contenidos con base en la red, y pagar el precio por los servicios asociados con dicho acceso.</p>

<p>En general, la prestación del servicio del Sitio por parte de “La empresa” tiene carácter gratuito para los Usuarios y no exige la previa suscripción o registro del Usuario. No obstante, la utilización de ciertos Servicios sólo puede darse mediante suscripción o registro del Usuario y/o pago de un precio, de la forma en que se indica expresamente en sus correspondientes Condiciones Particulares.</p>

<h5>3. OBLIGACIONES DEL USUARIO</h5>

<p>Con motivo de su uso del Servicio, el Usuario se obliga a proveer cuando a si se requiera información verdadera, correcta, actual, y completa de su persona del modo requerido en el formulario de registro del Servicio y mantener y actualizar en todo momento los Datos de Inscripción a fin de conservarlos, correctos, actuales y completos. Si suministra información que no sea verdadera, correcta, actual o completa, o si la Empresa considera que dicha información no es verdadera, correcta, actual o completa, la Empresa tendrá el derecho de suspender o terminar su cuenta y negarle el uso presente o futuro del Servicio (o cualquier parte del mismo).</p>

<p>Lo mismo ocurrirá cuando “La Empresa” reciba cualquier notificación de alguna autoridad o institución financiera que indique que la información que usted proporcionó fue sustraída de su titular o utilizada sin su consentimiento.</p>

<h5>4. POLÍTICA DE PRIVACIDAD</h5>

<p>Los Datos de Inscripción y otra información se encuentran sujetos a nuestro aviso de privacidad, la cual se describe en forma completa línea más arriba.</p>

<h5>5. CUENTA DE USUARIO, CONTRASEÑA Y SEGURIDAD</h5>

<p>Si se requiere, el Usuario creará una contraseña y una cuenta después de que complete el proceso de registro, de la cual el Usuario será responsable de mantener en confidencialidad y a su vez será responsable absoluto por todas las actividades que ocurran bajo su contraseña o cuenta. En virtud de lo anterior, El usuario se compromete a: (a) notificar inmediatamente a la Empresa de cualquier uso no autorizado de la contraseña o cuenta o de cualquier otra falla de seguridad, y (b) asegurarse de que la cuenta sea cerrada al final de cada sesión. La Empresa no será responsable por ninguna pérdida o daño que resulte como consecuencia de su incumplimiento a las disposiciones de esta Sección.</p>

<h5>6. CONDUCTA DEL USUARIO</h5>

<p>El usuario conoce y acepta que toda la información, datos, textos, software, música, sonido, fotografías, gráficas, video, mensajes u otros materiales, ya sea públicamente anunciado o transmitido privadamente, son responsabilidad únicamente de la persona que originó dicho Contenido. Esto significa que el usuario, y no la Empresa, es completamente responsable por todo el Contenido que usted cargue («upload»), anuncie, envíe por correo electrónico o de cualquier otra forma transmita vía el Servicio.</p>

<p>El Usuario deberá abstenerse de obtener e incluso de intentar obtener informaciones, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material accesibles a través del Sitio o de los Servicios empleando para ello medios o procedimientos distintos de los que, según los casos, se hayan puesto a su disposición a este efecto o se hayan indicado a este efecto en las páginas web donde se encuentren los Contenidos o, en general, de los que se empleen habitualmente en Internet a este efecto siempre que no entrañen un riesgo de daño o inutilización del Sitio, de los Servicios y/o de los Contenidos.</p>

<p>El Usuario se obliga a usar los Contenidos de forma diligente, correcta y lícita y, en particular, se compromete a abstenerse de (a) utilizar los Contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público; (b) reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificar los Contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido; (c) suprimir, eludir o manipular el «copyright» y demás datos identificativos de los derechos de la Empresa o de sus titulares incorporados a los Contenidos.</p>

<h5>7. ENLACES</h5>

<p>Los Usuarios y, en general, aquellas personas que se propongan establecer un hiperenlace entre su página web y el Sitio biometriaaplicada.com deberán cumplir las condiciones siguientes: (a) el Hiperenlace únicamente permitirá el acceso a la home-page o página de inicio del Sitio, pero no podrá reproducirlas de ninguna forma; (b) no se creará un frame sobre las páginas web del Sitio; (c) no se realizarán manifestaciones o indicaciones falsas, inexactas o incorrectas sobre la Empresa, sus directivos, sus empleados, las páginas web del Sitio y los Servicios suministrados; (d) no se declarará ni se dará a entender que la Empresa ha autorizado el Hiperenlace o que ha supervisado o asumido de cualquier forma los servicios ofrecidos o puestos a disposición de la página web en la que se establece el Hiperenlace; (e) excepción hecha de aquellos signos que formen parte del mismo Hiperenlace, la página web en la que se establezca el Hiperenlace no contendrá ninguna marca, nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a la Empresa; y (f) la página web en la que se establezca el Hiperenlace no contendrá informaciones o contenidos ilícitos, contrarios a la moral y a las buenas costumbres generalmente aceptadas y al orden público, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros. El establecimiento del Hiperenlace no implica en ningún caso la existencia de relaciones entre la Empresa y el propietario de la página web en la que se establezca, ni la aceptación y aprobación por parte de la Empresa de sus contenidos o servicios.</p>

<h5>8. LÍMITE DE LAS GARANTÍAS Y RESPONSABILIDAD</h5>

<p>La empresa excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos, y en particular, aunque no de modo exclusivo, por los daños y perjuicios que puedan deberse a (a) el incumplimiento de la ley, la moral y las buenas costumbres generalmente aceptadas o el orden público como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos; (b) la infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, de compromisos contractuales de cualquier clase, de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas, de los derechos de propiedad y de toda otra naturaleza pertenecientes a un tercero como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos; (c) la realización de actos de competencia desleal y publicidad ilícita como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos; (d) la falta de veracidad, exactitud, exhaustividad, pertinencia y/o actualidad de los contenidos; (e) la inadecuación para cualquier clase de propósito de y la defraudación de las expectativas generadas por los contenidos; (f) el incumplimiento, retraso en el cumplimiento, cumplimiento defectuoso o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros a través de o con motivo del acceso a los contenidos; (g) los vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición o de otra forma transmitidos o puestos a disposición, recibidos, obtenidos o a los que se haya accedido a través del sitio o de los servicios.</p>

<h5>EL USUARIO EXPRESAMENTE COMPRENDE Y CONVIENE EN QUE:</h5>

<p>El uso del servicio es bajo su propio riesgo. el servicio es provisto y según se encuentre «disponible», por lo que la empresa no otorga garantía de ningún tipo, ya sea expresa o implícita, incluyendo sin limitación a las garantías implícitas de comercialidad, aptitud para un propósito en particular, y no violación de los usuarios.</p>

<p>La empresa no garantiza que (i) el servicio se ajustará a sus requisitos, (ii) el servicio será ininterrumpido, puntual, seguro, o libre de error, (iii) los resultados que puedan ser obtenidos del uso del servicio sean veraces o confiables, (iv) la calidad de cualquier producto, servicio, información, u otro material comprado u obtenido por el usuario por medio del servicio se ajustará a sus expectativas y (v) cualquier error en el software será corregido.</p>

<p>Cualquier material descargado («downloaded») o de cualquier otra forma obtenido por medio del uso del servicio es hecho bajo su propia discreción y riesgo y usted será absolutamente responsable por cualquier daño al sistema de su computadora o pérdida de datos que resulte de la descarga («download») de cualquiera de estos materiales.</p>

<p>Ningún consejo o información, ya sea oral o escrito, obtenido de la empresa por el usuario, o por medio del servicio constituirá garantía alguna, a no ser que se encuentre expresamente establecido en los tos.</p>

<p>Del límite de responsabilidad el usuario comprende y acuerda expresamente que la empresa no será responsable por ningún daño o perjuicio directo indirecto, como resultado de: (i) el uso o imposibilidad de usar el servicio; (ii) el costo de obtención de bienes sustituibles y servicios resultantes de cualquier bien, dato, información o servicio adquirido u obtenido, o mensaje recibido, o transacciones realizadas por medio del servicio, o desde el servicio (iii) acceso no autorizado a sus transmisiones, o alteración de las mismas, o de sus datos; (iv) declaraciones o conductas de alguna tercera persona en el servicio; o (v) cualquier otro asunto relacionado al servicio.</p>

<h5>9. DERECHOS DE AUTOR Y MARCAS</h5>

<p>El Sitio y los Contenidos generados por La empresa o por los terceros que se los hayan provisto legalmente o por una relación de negocios, están protegidos por la legislación aplicable y por los tratados internacionales en materia de derechos de autor. El diseño, la diagramación y la disposición gráfica en conjunto del Sitio, y los Servicios, son propiedad exclusiva de la Empresa</p>

<p>La Empresa no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con el Sitio, los Servicios o los Contenidos.</p>

<h5>10. RETIRADA Y SUSPENSIÓN DE LOS SERVICIOS</h5>

<p>La Empresa podrá retirar o suspender en cualquier momento y sin necesidad de aviso previo la prestación de los Servicios a aquellos Usuarios que incumplan lo establecido en el presente TS.</p>

<h5>11. DURACIÓN Y TERMINACIÓN</h5>

<p>La prestación del servicio del Sitio y de los demás Servicios tiene, en principio, una duración indefinida. La Empresa, no obstante, está autorizada para dar por terminada o suspender la prestación del servicio del Sitio y/o de cualquiera de los Servicios en cualquier momento, sin perjuicio de lo que se hubiere dispuesto al respecto en las correspondientes Condiciones Particulares. Cuando ello sea razonablemente posible, La Empresa advertirá previamente la terminación o suspensión de la prestación del servicio del Sitio y de los demás Servicios.</p>

<h5>12. LEY Y JURIDICCIÓN APLICABLES</h5>

<p>Estas TS se rigen por las leyes de Perú. La Empresa y el Usuario, con renuncia expresa a cualquier otro fuero, se someten al de los Juzgados y Tribunales del Perú.</p>

</div>
