<div class="overlay fade" [class.show]="isOpen">
    <div class="modal slide" [class.down]="isOpen" [class.big]="big" [class.full-screen]="fullScreen" [class.auto-width]="autoWidth">

        <div class="modal-header">
            <h2 class="modal-title">{{title}}</h2>
            <button class="modal-close" (click)="this.modalCerrarVisor.open()">
                <i class="las la-window-close"></i>
            </button>
        </div>

        <div class="modal-body" [class.no-footer]="hideFooter" [class.scroller]="scroller">
            <ng-content></ng-content>
        </div>

        <div class="modal-footer" *ngIf="!hideFooter">
            <button class="btn default" 
            (click)="confirm()" 
            [class.loading]="loading" 
            [class.disabled]="loading || disableConfirm" title="">{{confirmText}}</button>
            <button class="btn danger" (click)="close()" title="">{{cancelText}}</button>
        </div>

    </div>
</div>

<!-- Modal Cerrar Visor Nuevo -->
<app-modal #modalCerrarVisor class="modalCerrarVisor" [hide-footer]="true" [closeOnConfirm]="true">
    <p class="title">¿Estás seguro que deseas salir?</p>
    <p class="label-subtitle">Recuerda que estás cerrando todos tus documentos sin firmar.</p>
    <div class="form-footer">
        <button class="btn success btn-cancelar" type="button" (click)="this.modalCerrarVisor.close()">Cancelar</button>
        <button class="btn success" type="button" (click)="this.modalCerrarVisor.close();close()">Salir</button>
        <!--<button class="btn default disabled loading" type="button"> Eliminar </button>-->
    </div>
</app-modal>
