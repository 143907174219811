import {
    Directive, 
    ElementRef, 
    EventEmitter,
    OnInit,
    Output,
    Renderer2
} from "@angular/core";

import { InputComponent } from "@noe/shared/input/input.component";

@Directive({
    selector: 'app-input[appInputPassIcon]'
})
export class InputPasswordDirective implements OnInit {
    @Output() typeChange: EventEmitter<string> = new EventEmitter<string>();
    
    inputType = 'password';
    showIconEye = false;

    constructor(
        private elRef: ElementRef<InputComponent>,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        const iconEyeEl = this.renderer.nextSibling(this.elRef.nativeElement);

        this.renderer.listen(iconEyeEl, 'click', () => {
            this.inputType = this.inputType === 'password'
                ? 'text'
                : 'password';

            this.typeChange.emit(this.inputType);
            
            this.showIconEye = !this.showIconEye;

            if (this.showIconEye) {
                this.renderer.removeClass(iconEyeEl, 'la-eye-slash');
                this.renderer.addClass(iconEyeEl, 'la-eye');
            } else {
                this.renderer.removeClass(iconEyeEl, 'la-eye');
                this.renderer.addClass(iconEyeEl, 'la-eye-slash');
            }
        });
        
    }
}