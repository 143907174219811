import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader-scroll',
  templateUrl: './loader-scroll.component.html',
  styleUrls: ['./loader-scroll.component.scss'],
  host: { class: 'app-loader-scroll' }
})
export class LoaderScrollComponent implements OnInit {

  @Input('disabled') disabled:boolean
  @Input('big') big:boolean
  @Input('no-bg') noBg:boolean

  constructor() { }

  ngOnInit(): void {
  }

}
