import { AbstractControl } from '@angular/forms';

// Al menos una letra mayúscula
export function CapitalValidator(control: AbstractControl) {
    if (control.pristine) {
        return null;
    }
    const regex = /.*[A-Z].*/
    if (regex.test(control.value)) {
        return null
    } else {
        return { capitalValidator: true }
    }
}

// Al menos una letra minúscula
export function LowercaseValidator(control: AbstractControl) {
    if (control.pristine) {
        return null;
    }
    const regex = /.*[a-z].*/
    if (regex.test(control.value)) {
        return null
    } else {
        return { lowercaseValidator: true }
    }
}

// Al menos un número
export function NumberValidator(control: AbstractControl) {
    if (control.pristine) {
        return null;
    }
    const regex = /.*[0-9].*/
    if (regex.test(control.value)) {
        return null
    } else {
        return { numberValidator: true }
    }
}

// Al menos un símbolo
export function SymbolValidator(control: AbstractControl) {
    if (control.pristine) {
        return null;
    }
    const regex = /.*[\[\]{}%=+@':;_#$&/<>^~|()?¿!¡\\-`.,\"*].*/
    if (regex.test(control.value)) {
        return null
    } else {
        return { symbolValidator: true }
    }
}


export function AtLeastOne(control: AbstractControl) {
    if (control.pristine) {
        return null;
    }
    let invalid:boolean = false
    if (!control.value || control.value.length === 0) {
        invalid = true
    }
    if (invalid) {
        return { AtLeastOne: true }
    } else {
        return  null
    }
}