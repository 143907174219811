import { Directive, ElementRef, Renderer2 } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { ValueAccessorBase } from "./value-accessor-base";

@Directive({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: TextValueAccessorDirective
        }
    ],
    selector: 'app-autocomplete'
})
export class TextValueAccessorDirective extends ValueAccessorBase {

    constructor(elRef: ElementRef, renderer: Renderer2) {
        super(elRef, renderer);
    }
}