<div class="backdrop" (click)="onClose($event)" #backdrop>
    <div 
        class="alert-box" 
        [ngStyle]="setStyles()"
    >
        <h2 class="alert-box__title" [ngClass]="type">
            <i 
                *ngIf="showIcon"
                class="las la-check-square alert-icon"
            ></i>
            {{ swapType() }}
        </h2>
        <p class="alert-box__message"
            [innerHtml]="message"
        ></p>
        <app-countdown
            #countdown
            *ngIf="type === 'expiration'"
            [minutes]="0"
            [seconds]="timeLeft"
            (onfinish)="onCountdownEnd()"
        ></app-countdown>
        <div
            class="alert-box__actions"
            *ngIf="buttons.length > 0"
        >
            <button
                *ngFor="let btn of buttons"
                [class]="btn.classes"
                type="button"
                (click)="btn.handler(btn.modalInstance)"
            >{{ btn.text }}</button>
        </div>
    </div>
</div>