export const environment = {
    production: false,
    identityPoolId: 'us-west-2:b5a4ca13-5a00-4f2d-9c18-a870472426bc',
    userPoolId: 'us-west-2_6jMEUvuub',
    webClientId: '2iti4pcngt67cdoq9qjpvadm8b',
    bucket: 'noe-comercial',
    api: 'https://5gmo3l1dbc.execute-api.us-west-2.amazonaws.com/comercial/noe',
    apiCore: 'https://5gmo3l1dbc.execute-api.us-west-2.amazonaws.com/comercial/noe/core',
    xApiKey: 'LkfeKxCIkg8Pcq4a5ZFIz6DV00Fd6lYRg3GlAIc9',
    captchaSiteKey:'6LfBENkaAAAAAP9qn7Z73BYMsrdvE-bfcZ92i7VX',
    reporteReplace: 'https://noe-comercial.s3.amazonaws.com/',
    TESTING : false,
    rutaRegionQa:'https://noe-comercial.s3.us-west-2.amazonaws.com',
    socketEndpoint:'wss://hj6frf4o89.execute-api.us-west-2.amazonaws.com/noecomercial' 
  };
