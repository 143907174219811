import { Pipe, PipeTransform } from '@angular/core';

/**
 * Capitalize the fist letter of the string
 */
@Pipe({ name: 'capitalizeFirst' })
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: any, args?: any[]): any {
    if (value === null) {
        return 'Not assigned';
    }        
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}