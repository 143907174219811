import { 
  Component, 
  EventEmitter, 
  Input, 
  Output 
} from '@angular/core';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
  @Input() placeholder: string = '';
  @Input() value: any;

  /**
   * Input property options for autocomplete.
   */
  @Input()
  get options() {
    return this.lastOptions;
  }
  set options(value: any[]) {
    if (value) {
      this.lastOptions = value;
    }
  }

  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

  @Output() noeInput: EventEmitter<any> = new EventEmitter<any>();

  private lastOptions = ['One', 'Two', 'Three'];

  constructor() {}

  onSelected(event: MatAutocompleteSelectedEvent) {
    console.log('event.option.value', event.option.value);
    this.optionSelected.emit(event.option.value);
  }
}
