import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-modal-documentos',
  templateUrl: './modal-documentos.component.html',
  styleUrls: ['./modal-documentos.component.scss']
})
export class ModalDocumentosComponent implements OnInit {

  @Input('titleModal') title:string
  @Input('subtitle') subtitle:string
  @Input('loading') loading:boolean
  @Input('confirmText') confirmText:string = 'Finalizar'
  @Input('cancelText') cancelText:string = 'Cerrar'
  @Input('closeOnConfirm') closeOnConfirm:boolean = true
  @Input('disableConfirm') disableConfirm:boolean
  @Input('hide-footer') hideFooter:boolean
  @Input('big') big:boolean
  @Input('fullScreen') fullScreen:boolean
  @Input('auto-width') autoWidth:boolean
  @Input('scroller') scroller:boolean

  @Output() 
  onclose: EventEmitter<any> = new EventEmitter();
  @Output()
  onconfirm: EventEmitter<any> = new EventEmitter();


  isOpen:boolean

  constructor() { }

  ngOnInit(): void {
  }

  open() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    this.isOpen = true
  }

  close() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-open');
    this.isOpen = false;
    this.onclose.emit();
  }
  
  confirmarModal: boolean
  confirm() {
    this.onconfirm.emit()
    if (this.closeOnConfirm) this.isOpen = false;
    this.close();
  }

}
