import { IDrawOptions } from "src/app/models/Challenge.model";
import * as LiveDetectionActions from "./liveness-detection.actions";

export interface State {
    drawOptions: IDrawOptions;
    helpMessage: string;
    shouldSaveFrame: boolean;
    success: boolean;
    end: boolean;
}

const initialState: State = {
    drawOptions: null,
    helpMessage: null,
    shouldSaveFrame: false,
    success: false,
    end: false
};

export function liveDetectionReducer(
    state: State = initialState,
    action: LiveDetectionActions.LiveDetectionAction
): State {
    const COLOR_RED = 'rgba(255, 0, 0, 1)';
    const COLOR_GREEN = 'rgba(0, 255, 0, 1)';
    const COLOR_YELLOW = 'rgba(255, 255, 0, 1)';

    switch(action.type) {
        case LiveDetectionActions.NO_FACE_AREA:

            const newDrawOptions: IDrawOptions = {
                ...state.drawOptions,
                faceDrawBoxOptions: {
                    ...state.drawOptions?.faceDrawBoxOptions,
                    boxColor: COLOR_RED
                }
            };

            return {
                ...state,
                drawOptions: newDrawOptions,
                helpMessage: 'Centra tu rostro dentro del área'
            };
        case LiveDetectionActions.FACE_AREA:

            const drawOptionsFace: IDrawOptions = {
                ...state.drawOptions,
                faceDrawBoxOptions: {
                    ...state.drawOptions?.faceDrawBoxOptions,
                    boxColor: COLOR_GREEN
                }
            };  

            return {
                ...state,
                drawOptions: drawOptionsFace
            };
        case LiveDetectionActions.NOSE_AREA:

            const drawOptionsNose: IDrawOptions = {
                ...state.drawOptions,
                noseDrawBoxOptions: {
                    ...state.drawOptions?.noseDrawBoxOptions,
                    boxColor: COLOR_YELLOW
                }
            }    

            return {
                ...state,
                drawOptions: drawOptionsNose,
                shouldSaveFrame: true,
                helpMessage: 'Mueva la punta de su nariz dentro del área amarilla'
            };
        case LiveDetectionActions.FAIL_DETECTION:
            return {
                ...state,
                end: true,
                success: false,
                shouldSaveFrame: false
            };
        case LiveDetectionActions.SUCCESS_DETECTION:
            return {
                ...state,
                end: true,
                success: true,
                shouldSaveFrame: true
            };
        case LiveDetectionActions.RESET:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
}