<ng-container *ngIf="readonly">
    <div class="input-container disabled">
        <label>{{label}}</label>
        <input 
        type="text"
        readonly
        disabled
        (focus)="$event.preventDefault()"                      
        (click)="$event.preventDefault()"
        (change)="$event.preventDefault()"
        (keyDown)="$event.preventDefault()"
        (keyUp)="$event.preventDefault()"
        (keyPress)="$event.preventDefault()"
        [(ngModel)]="selectedFormat">
    </div>
</ng-container>



<div [class.inline]="inline" class="picker-container" (click)="$event.stopPropagation()" *ngIf="!readonly">

    <div 
    class="input-container" 
    [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
        <label><span *ngIf="required" class="red">*</span> {{label}}</label>
        <input readonly type="text" [(ngModel)]="selectedFormat" (focus)="open()">
    </div>

    <div class="picker-area" [class.show]="show">
        <div class="range-pickers">

            <app-picker-calendar 
            [refDate]="today" 
            [min]="min"
            [max]="max"
            (onselect)="setRange($event)" 
            (onnav)="nav($event)" #picker1></app-picker-calendar>

            <app-picker-calendar 
            [refDate]="next" 
            [min]="min"
            [max]="max"
            [nav]="true"
            (onselect)="setRange($event)" 
            (onnav)="nav($event)" #picker2></app-picker-calendar>

        </div>

        <!-- <p>saltar al</p> -->
    </div>
</div>

<div class="error-block" *ngIf="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
    <p *ngIf="ngControl.control.errors?.required">Este campo es obligatorio</p>
</div>