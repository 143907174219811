const countries = [
    {
        "en": "Afghanistan",
        "es": "Afganistán",
        "dial": "+93",
        "code": "AF"
    },
    {
        "en": "Albania",
        "es": "Albania",
        "dial": "+355",
        "code": "AL"
    },
    {
        "en": "Algeria",
        "es": "Argelia",
        "dial": "+213",
        "code": "DZ"
    },
    {
        "en": "AmericanSamoa",
        "es": "Samoa Americana",
        "dial": "+1684",
        "code": "AS"
    },
    {
        "en": "Andorra",
        "es": "Andorra",
        "dial": "+376",
        "code": "AD"
    },
    {
        "en": "Angola",
        "es": "Angola",
        "dial": "+244",
        "code": "AO"
    },
    {
        "en": "Anguilla",
        "es": "Anguilla",
        "dial": "+1264",
        "code": "AI"
    },
    {
        "en": "Antarctica",
        "es": "Antártida",
        "dial": "+672",
        "code": "AQ"
    },
    {
        "en": "Antigua and Barbuda",
        "es": "Antigua y Barbuda",
        "dial": "+1268",
        "code": "AG"
    },
    {
        "en": "Argentina",
        "es": "Argentina",
        "dial": "+54",
        "code": "AR"
    },
    {
        "en": "Armenia",
        "es": "Armenia",
        "dial": "+374",
        "code": "AM"
    },
    {
        "en": "Aruba",
        "es": "Aruba",
        "dial": "+297",
        "code": "AW"
    },
    {
        "en": "Australia",
        "es": "Australia",
        "dial": "+61",
        "code": "AU"
    },
    {
        "en": "Austria",
        "es": "Austria",
        "dial": "+43",
        "code": "AT"
    },
    {
        "en": "Azerbaijan",
        "es": "Azerbaiyán",
        "dial": "+994",
        "code": "AZ"
    },
    {
        "en": "Bahamas",
        "es": "Bahamas",
        "dial": "+1242",
        "code": "BS"
    },
    {
        "en": "Bahrain",
        "es": "Baréin",
        "dial": "+973",
        "code": "BH"
    },
    {
        "en": "Bangladesh",
        "es": "Banglades",
        "dial": "+880",
        "code": "BD"
    },
    {
        "en": "Barbados",
        "es": "Barbados",
        "dial": "+1246",
        "code": "BB"
    },
    {
        "en": "Belarus",
        "es": "Bielorrusia",
        "dial": "+375",
        "code": "BY"
    },
    {
        "en": "Belgium",
        "es": "Bélgica",
        "dial": "+32",
        "code": "BE"
    },
    {
        "en": "Belize",
        "es": "Belice",
        "dial": "+501",
        "code": "BZ"
    },
    {
        "en": "Benin",
        "es": "Benin",
        "dial": "+229",
        "code": "BJ"
    },
    {
        "en": "Bermuda",
        "es": "Bermudas",
        "dial": "+1441",
        "code": "BM"
    },
    {
        "en": "Bhutan",
        "es": "Butan",
        "dial": "+975",
        "code": "BT"
    },
    {
        "en": "Bolivia",
        "es": "Bolivia",
        "dial": "+591",
        "code": "BO"
    },
    {
        "en": "Bosnia and Herzegovina",
        "es": "Bosnia-Herzegovina",
        "dial": "+387",
        "code": "BA"
    },
    {
        "en": "Botswana",
        "es": "Botsuana",
        "dial": "+267",
        "code": "BW"
    },
    {
        "en": "Brazil",
        "es": "Brasil",
        "dial": "+55",
        "code": "BR"
    },
    {
        "en": "British Indian Ocean Territory",
        "es": "Territorio Británico del Océano Índico",
        "dial": "+246",
        "code": "IO"
    },
    {
        "en": "Brunei Darussalam",
        "es": "Brunei",
        "dial": "+673",
        "code": "BN"
    },
    {
        "en": "Bulgaria",
        "es": "Bulgaria",
        "dial": "+359",
        "code": "BG"
    },
    {
        "en": "Burkina Faso",
        "es": "Burkina Faso",
        "dial": "+226",
        "code": "BF"
    },
    {
        "en": "Burundi",
        "es": "Burundi",
        "dial": "+257",
        "code": "BI"
    },
    {
        "en": "Cambodia",
        "es": "Camboya",
        "dial": "+855",
        "code": "KH"
    },
    {
        "en": "Cameroon",
        "es": "Camerún",
        "dial": "+237",
        "code": "CM"
    },
    {
        "en": "Canada",
        "es": "Canadá",
        "dial": "+1",
        "code": "CA"
    },
    {
        "en": "Cape Verde",
        "es": "Cabo Verde",
        "dial": "+238",
        "code": "CV"
    },
    {
        "en": "Cayman Islands",
        "es": "Islas Caimán",
        "dial": "+ 345",
        "code": "KY"
    },
    {
        "en": "Central African Republic",
        "es": "República Centroafricana",
        "dial": "+236",
        "code": "CF"
    },
    {
        "en": "Chad",
        "es": "Chad",
        "dial": "+235",
        "code": "TD"
    },
    {
        "en": "Chile",
        "es": "Chile",
        "dial": "+56",
        "code": "CL"
    },
    {
        "en": "China",
        "es": "China",
        "dial": "+86",
        "code": "CN"
    },
    {
        "en": "Christmas Island",
        "es": "Isla de Navidad",
        "dial": "+61",
        "code": "CX"
    },
    {
        "en": "Cocos (Keeling) Islands",
        "es": "Islas Cocos",
        "dial": "+61",
        "code": "CC"
    },
    {
        "en": "Colombia",
        "es": "Colombia",
        "dial": "+57",
        "code": "CO"
    },
    {
        "en": "Comoros",
        "es": "Comoras",
        "dial": "+269",
        "code": "KM"
    },
    {
        "en": "Congo",
        "es": "Congo",
        "dial": "+242",
        "code": "CG"
    },
    {
        "en": "Congo, The Democratic Republic of the",
        "es": "República Democrática del Congo",
        "dial": "+243",
        "code": "CD"
    },
    {
        "en": "Cook Islands",
        "es": "Islas Cook",
        "dial": "+682",
        "code": "CK"
    },
    {
        "en": "Costa Rica",
        "es": "Costa Rica",
        "dial": "+506",
        "code": "CR"
    },
    {
        "en": "Cote d'Ivoire",
        "es": "Costa de Marfil",
        "dial": "+225",
        "code": "CI"
    },
    {
        "en": "Croatia",
        "es": "Croacia",
        "dial": "+385",
        "code": "HR"
    },
    {
        "en": "Cuba",
        "es": "Cuba",
        "dial": "+53",
        "code": "CU"
    },
    {
        "en": "Cyprus",
        "es": "Chipre",
        "dial": "+537",
        "code": "CY"
    },
    {
        "en": "Czechia",
        "es": "Chequia",
        "dial": "+420",
        "code": "CZ"
    },
    {
        "en": "Denmark",
        "es": "Dinamarca",
        "dial": "+45",
        "code": "DK"
    },
    {
        "en": "Djibouti",
        "es": "Yibuti",
        "dial": "+253",
        "code": "DJ"
    },
    {
        "en": "Dominica",
        "es": "Dominica",
        "dial": "+1767",
        "code": "DM"
    },
    {
        "en": "Dominican Republic",
        "es": "República Dominicana",
        "dial": "+1849",
        "code": "DO"
    },
    {
        "en": "Ecuador",
        "es": "Ecuador",
        "dial": "+593",
        "code": "EC"
    },
    {
        "en": "Egypt",
        "es": "Egipto",
        "dial": "+20",
        "code": "EG"
    },
    {
        "en": "El Salvador",
        "es": "El Salvador",
        "dial": "+503",
        "code": "SV"
    },
    {
        "en": "Equatorial Guinea",
        "es": "Guinea Ecuatorial",
        "dial": "+240",
        "code": "GQ"
    },
    {
        "en": "Eritrea",
        "es": "Eritrea",
        "dial": "+291",
        "code": "ER"
    },
    {
        "en": "Estonia",
        "es": "Estonia",
        "dial": "+372",
        "code": "EE"
    },
    {
        "en": "Ethiopia",
        "es": "Etiopía",
        "dial": "+251",
        "code": "ET"
    },
    {
        "en": "Falkland Islands (Malvinas)",
        "es": "Islas Malvinas",
        "dial": "+500",
        "code": "FK"
    },
    {
        "en": "Faroe Islands",
        "es": "Islas Feroe",
        "dial": "+298",
        "code": "FO"
    },
    {
        "en": "Fiji",
        "es": "Fiyi",
        "dial": "+679",
        "code": "FJ"
    },
    {
        "en": "Finland",
        "es": "Finlandia",
        "dial": "+358",
        "code": "FI"
    },
    {
        "en": "France",
        "es": "Francia",
        "dial": "+33",
        "code": "FR"
    },
    {
        "en": "French Guiana",
        "es": "Guayana Francesa",
        "dial": "+594",
        "code": "GF"
    },
    {
        "en": "French Polynesia",
        "es": "Polinesia Francesa",
        "dial": "+689",
        "code": "PF"
    },
    {
        "en": "Gabon",
        "es": "Gabón",
        "dial": "+241",
        "code": "GA"
    },
    {
        "en": "Gambia",
        "es": "Gambia",
        "dial": "+220",
        "code": "GM"
    },
    {
        "en": "Georgia",
        "es": "Georgia",
        "dial": "+995",
        "code": "GE"
    },
    {
        "en": "Germany",
        "es": "Alemania",
        "dial": "+49",
        "code": "DE"
    },
    {
        "en": "Ghana",
        "es": "Ghana",
        "dial": "+233",
        "code": "GH"
    },
    {
        "en": "Gibraltar",
        "es": "Gibraltar",
        "dial": "+350",
        "code": "GI"
    },
    {
        "en": "Greece",
        "es": "Grecia",
        "dial": "+30",
        "code": "GR"
    },
    {
        "en": "Greenland",
        "es": "Groenlandia",
        "dial": "+299",
        "code": "GL"
    },
    {
        "en": "Grenada",
        "es": "Granada",
        "dial": "+1473",
        "code": "GD"
    },
    {
        "en": "Guadeloupe",
        "es": "Guadalupe",
        "dial": "+590",
        "code": "GP"
    },
    {
        "en": "Guam",
        "es": "Guam",
        "dial": "+1671",
        "code": "GU"
    },
    {
        "en": "Guatemala",
        "es": "Guatemala",
        "dial": "+502",
        "code": "GT"
    },
    {
        "en": "Guernsey",
        "es": "Guernsey",
        "dial": "+44",
        "code": "GG"
    },
    {
        "en": "Guinea",
        "es": "Guinea",
        "dial": "+224",
        "code": "GN"
    },
    {
        "en": "Guinea-Bissau",
        "es": "Guinea-Bisau",
        "dial": "+245",
        "code": "GW"
    },
    {
        "en": "Guyana",
        "es": "Guyana",
        "dial": "+595",
        "code": "GY"
    },
    {
        "en": "Haiti",
        "es": "Haití",
        "dial": "+509",
        "code": "HT"
    },
    {
        "en": "Holy See (Vatican City State)",
        "es": "Ciudad del Vaticano",
        "dial": "+379",
        "code": "VA"
    },
    {
        "en": "Honduras",
        "es": "Honduras",
        "dial": "+504",
        "code": "HN"
    },
    {
        "en": "Hong Kong",
        "es": "Hong Kong",
        "dial": "+852",
        "code": "HK"
    },
    {
        "en": "Hungary",
        "es": "Hungría",
        "dial": "+36",
        "code": "HU"
    },
    {
        "en": "Iceland",
        "es": "Islandia",
        "dial": "+354",
        "code": "IS"
    },
    {
        "en": "India",
        "es": "India",
        "dial": "+91",
        "code": "IN"
    },
    {
        "en": "Indonesia",
        "es": "Indonesia",
        "dial": "+62",
        "code": "ID"
    },
    {
        "en": "Iran, Islamic Republic of",
        "es": "Irán",
        "dial": "+98",
        "code": "IR"
    },
    {
        "en": "Iraq",
        "es": "Iraq",
        "dial": "+964",
        "code": "IQ"
    },
    {
        "en": "Ireland",
        "es": "Irlanda",
        "dial": "+353",
        "code": "IE"
    },
    {
        "en": "Isle of Man",
        "es": "Isla de Man",
        "dial": "+44",
        "code": "IM"
    },
    {
        "en": "Israel",
        "es": "Israel",
        "dial": "+972",
        "code": "IL"
    },
    {
        "en": "Italy",
        "es": "Italia",
        "dial": "+39",
        "code": "IT"
    },
    {
        "en": "Jamaica",
        "es": "Jamaica",
        "dial": "+1876",
        "code": "JM"
    },
    {
        "en": "Japan",
        "es": "Japón",
        "dial": "+81",
        "code": "JP"
    },
    {
        "en": "Jersey",
        "es": "Jersey",
        "dial": "+44",
        "code": "JE"
    },
    {
        "en": "Jordan",
        "es": "Jordania",
        "dial": "+962",
        "code": "JO"
    },
    {
        "en": "Kazakhstan",
        "es": "Kazajistán",
        "dial": "+7",
        "code": "KZ"
    },
    {
        "en": "Kenya",
        "es": "Kenia",
        "dial": "+254",
        "code": "KE"
    },
    {
        "en": "Kiribati",
        "es": "Kiribati",
        "dial": "+686",
        "code": "KI"
    },
    {
        "en": "Korea, Democratic People's Republic of",
        "es": "Corea del Norte",
        "dial": "+850",
        "code": "KP"
    },
    {
        "en": "Korea, Republic of",
        "es": "Corea del Sur",
        "dial": "+82",
        "code": "KR"
    },
    {
        "en": "Kosovo",
        "es": "Kosovo",
        "dial": "+383",
        "code": "XK"
    },
    {
        "en": "Kuwait",
        "es": "Kuwait",
        "dial": "+965",
        "code": "KW"
    },
    {
        "en": "Kyrgyzstan",
        "es": "Kirguistán",
        "dial": "+996",
        "code": "KG"
    },
    {
        "en": "Lao People's Democratic Republic",
        "es": "Laos",
        "dial": "+856",
        "code": "LA"
    },
    {
        "en": "Latvia",
        "es": "Letonia",
        "dial": "+371",
        "code": "LV"
    },
    {
        "en": "Lebanon",
        "es": "Líbano",
        "dial": "+961",
        "code": "LB"
    },
    {
        "en": "Lesotho",
        "es": "Lesoto",
        "dial": "+266",
        "code": "LS"
    },
    {
        "en": "Liberia",
        "es": "Liberia",
        "dial": "+231",
        "code": "LR"
    },
    {
        "en": "Libyan Arab Jamahiriya",
        "es": "Libia",
        "dial": "+218",
        "code": "LY"
    },
    {
        "en": "Liechtenstein",
        "es": "Liechtenstein",
        "dial": "+423",
        "code": "LI"
    },
    {
        "en": "Lithuania",
        "es": "Lituania",
        "dial": "+370",
        "code": "LT"
    },
    {
        "en": "Luxembourg",
        "es": "Luxemburgo",
        "dial": "+352",
        "code": "LU"
    },
    {
        "en": "Macao",
        "es": "Macao",
        "dial": "+853",
        "code": "MO"
    },
    {
        "en": "Macedonia, The Former Yugoslav Republic of",
        "es": "República de Macedonia",
        "dial": "+389",
        "code": "MK"
    },
    {
        "en": "Madagascar",
        "es": "Madagascar",
        "dial": "+261",
        "code": "MG"
    },
    {
        "en": "Malawi",
        "es": "Malaui",
        "dial": "+265",
        "code": "MW"
    },
    {
        "en": "Malaysia",
        "es": "Malasia",
        "dial": "+60",
        "code": "MY"
    },
    {
        "en": "Maldives",
        "es": "Maldivas",
        "dial": "+960",
        "code": "MV"
    },
    {
        "en": "Mali",
        "es": "Malí",
        "dial": "+223",
        "code": "ML"
    },
    {
        "en": "Malta",
        "es": "Malta",
        "dial": "+356",
        "code": "MT"
    },
    {
        "en": "Marshall Islands",
        "es": "Islas Marshall",
        "dial": "+692",
        "code": "MH"
    },
    {
        "en": "Martinique",
        "es": "Martinica",
        "dial": "+596",
        "code": "MQ"
    },
    {
        "en": "Mauritania",
        "es": "Mauritania",
        "dial": "+222",
        "code": "MR"
    },
    {
        "en": "Mauritius",
        "es": "Mauricio",
        "dial": "+230",
        "code": "MU"
    },
    {
        "en": "Mayotte",
        "es": "Mayotte",
        "dial": "+262",
        "code": "YT"
    },
    {
        "en": "Mexico",
        "es": "México",
        "dial": "+52",
        "code": "MX"
    },
    {
        "en": "Micronesia, Federated States of",
        "es": "Estados Federados de Micronesia",
        "dial": "+691",
        "code": "FM"
    },
    {
        "en": "Moldova, Republic of",
        "es": "Moldavia",
        "dial": "+373",
        "code": "MD"
    },
    {
        "en": "Monaco",
        "es": "Monaco",
        "dial": "+377",
        "code": "MC"
    },
    {
        "en": "Mongolia",
        "es": "Mongolia",
        "dial": "+976",
        "code": "MN"
    },
    {
        "en": "Montenegro",
        "es": "Montenegro",
        "dial": "+382",
        "code": "ME"
    },
    {
        "en": "Montserrat",
        "es": "Montserrat",
        "dial": "+1664",
        "code": "MS"
    },
    {
        "en": "Morocco",
        "es": "Marruecos",
        "dial": "+212",
        "code": "MA"
    },
    {
        "en": "Mozambique",
        "es": "Mozambique",
        "dial": "+258",
        "code": "MZ"
    },
    {
        "en": "Myanmar",
        "es": "Birmania",
        "dial": "+95",
        "code": "MM"
    },
    {
        "en": "Namibia",
        "es": "Namibia",
        "dial": "+264",
        "code": "NA"
    },
    {
        "en": "Nauru",
        "es": "Nauru",
        "dial": "+674",
        "code": "NR"
    },
    {
        "en": "Nepal",
        "es": "Nepal",
        "dial": "+977",
        "code": "NP"
    },
    {
        "en": "Netherlands",
        "es": "Holanda",
        "dial": "+31",
        "code": "NL"
    },
    {
        "en": "Netherlands Antilles",
        "es": "Antillas Holandesas",
        "dial": "+599",
        "code": "AN"
    },
    {
        "en": "New Caledonia",
        "es": "Nueva Caledonia",
        "dial": "+687",
        "code": "NC"
    },
    {
        "en": "New Zealand",
        "es": "Nueva Zelanda",
        "dial": "+64",
        "code": "NZ"
    },
    {
        "en": "Nicaragua",
        "es": "Nicaragua",
        "dial": "+505",
        "code": "NI"
    },
    {
        "en": "Niger",
        "es": "Niger",
        "dial": "+227",
        "code": "NE"
    },
    {
        "en": "Nigeria",
        "es": "Nigeria",
        "dial": "+234",
        "code": "NG"
    },
    {
        "en": "Niue",
        "es": "Niue",
        "dial": "+683",
        "code": "NU"
    },
    {
        "en": "NorfolkIsland",
        "es": "IslaNorfolk",
        "dial": "+672",
        "code": "NF"
    },
    {
        "en": "NorthernMarianaIslands",
        "es": "IslasMarianasdelNorte",
        "dial": "+1670",
        "code": "MP"
    },
    {
        "en": "Norway",
        "es": "Noruega",
        "dial": "+47",
        "code": "NO"
    },
    {
        "en": "Oman",
        "es": "Omán",
        "dial": "+968",
        "code": "OM"
    },
    {
        "en": "Pakistan",
        "es": "Pakistán",
        "dial": "+92",
        "code": "PK"
    },
    {
        "en": "Palau",
        "es": "Palaos",
        "dial": "+680",
        "code": "PW"
    },
    {
        "en": "Panama",
        "es": "Panamá",
        "dial": "+507",
        "code": "PA"
    },
    {
        "en": "Papua New Guinea",
        "es": "Papúa Nueva Guinea",
        "dial": "+675",
        "code": "PG"
    },
    {
        "en": "Paraguay",
        "es": "Paraguay",
        "dial": "+595",
        "code": "PY"
    },
    {
        "en": "Peru",
        "es": "Perú",
        "dial": "+51",
        "code": "PE"
    },
    {
        "en": "Philippines",
        "es": "Filipinas",
        "dial": "+63",
        "code": "PH"
    },
    {
        "en": "Pitcairn",
        "es": "Islas Pitcairn",
        "dial": "+872",
        "code": "PN"
    },
    {
        "en": "Poland",
        "es": "Polonia",
        "dial": "+48",
        "code": "PL"
    },
    {
        "en": "Portugal",
        "es": "Portugal",
        "dial": "+351",
        "code": "PT"
    },
    {
        "en": "Puerto Rico",
        "es": "Puerto Rico",
        "dial": "+1939",
        "code": "PR"
    },
    {
        "en": "Qatar",
        "es": "Qatar",
        "dial": "+974",
        "code": "QA"
    },
    {
        "en": "Romania",
        "es": "Rumania",
        "dial": "+40",
        "code": "RO"
    },
    {
        "en": "Russia",
        "es": "Rusia",
        "dial": "+7",
        "code": "RU"
    },
    {
        "en": "Rwanda",
        "es": "Ruanda",
        "dial": "+250",
        "code": "RW"
    },
    {
        "en": "Réunion",
        "es": "Reunion",
        "dial": "+262",
        "code": "RE"
    },
    {
        "en": "Saint Barthélemy",
        "es": "San Bartolome",
        "dial": "+590",
        "code": "BL"
    },
    {
        "en": "Saint Helena, Ascension and Tristan Da Cunha",
        "es": "Santa Elena, Ascensión y Tristán de Acuña",
        "dial": "+290",
        "code": "SH"
    },
    {
        "en": "Saint Kitts and Nevis",
        "es": "San Cristóbal y Nieves",
        "dial": "+1869",
        "code": "KN"
    },
    {
        "en": "Saint Lucia",
        "es": "Santa Lucía",
        "dial": "+1758",
        "code": "LC"
    },
    {
        "en": "Saint Martin",
        "es": "Isla de San Martín",
        "dial": "+590",
        "code": "MF"
    },
    {
        "en": "Saint Pierre and Miquelon",
        "es": "San Pedro y Miquelon",
        "dial": "+508",
        "code": "PM"
    },
    {
        "en": "Saint Vincent and the Grenadines",
        "es": "San Vicente y las Granadinas",
        "dial": "+1784",
        "code": "VC"
    },
    {
        "en": "Samoa",
        "es": "Samoa",
        "dial": "+685",
        "code": "WS"
    },
    {
        "en": "San Marino",
        "es": "San Marino",
        "dial": "+378",
        "code": "SM"
    },
    {
        "en": "Sao Tome and Principe",
        "es": " Santo Tomé y Príncipe",
        "dial": "+239",
        "code": "ST"
    },
    {
        "en": "Saudi Arabia",
        "es": "Arabia Saudita",
        "dial": "+966",
        "code": "SA"
    },
    {
        "en": "Senegal",
        "es": "Senegal",
        "dial": "+221",
        "code": "SN"
    },
    {
        "en": "Serbia",
        "es": "Serbia",
        "dial": "+381",
        "code": "RS"
    },
    {
        "en": "Seychelles",
        "es": "Seychelles",
        "dial": "+248",
        "code": "SC"
    },
    {
        "en": "Sierra Leone",
        "es": "Sierra Leona",
        "dial": "+232",
        "code": "SL"
    },
    {
        "en": "Singapore",
        "es": "Singapur",
        "dial": "+65",
        "code": "SG"
    },
    {
        "en": "Slovakia",
        "es": "Eslovaquia",
        "dial": "+421",
        "code": "SK"
    },
    {
        "en": "Slovenia",
        "es": "Eslovenia",
        "dial": "+386",
        "code": "SI"
    },
    {
        "en": "Solomon Islands",
        "es": "Islas Salomón",
        "dial": "+677",
        "code": "SB"
    },
    {
        "en": "Somalia",
        "es": "Somalia",
        "dial": "+252",
        "code": "SO"
    },
    {
        "en": "South Africa",
        "es": "Sudáfrica",
        "dial": "+27",
        "code": "ZA"
    },
    {
        "en": "South Sudan",
        "es": "Sudán del Sur",
        "dial": "+211",
        "code": "SS"
    },
    {
        "en": "Spain",
        "es": "España",
        "dial": "+34",
        "code": "ES"
    },
    {
        "en": "Sri Lanka",
        "es": "Sri Lanka",
        "dial": "+94",
        "code": "LK"
    },
    {
        "en": "State of Palestine",
        "es": "Estado de Palestina",
        "dial": "+970",
        "code": "PS"
    },
    {
        "en": "Sudan",
        "es": "Sudán",
        "dial": "+249",
        "code": "SD"
    },
    {
        "en": "Suriname",
        "es": "Surinam",
        "dial": "+597",
        "code": "SR"
    },
    {
        "en": "Svalbard and Jan Mayen",
        "es": "Svalbard y Jan Mayen",
        "dial": "+47",
        "code": "SJ"
    },
    {
        "en": "Swaziland",
        "es": "Suazilandia",
        "dial": "+268",
        "code": "SZ"
    },
    {
        "en": "Sweden",
        "es": "Suecia",
        "dial": "+46",
        "code": "SE"
    },
    {
        "en": "Switzerland",
        "es": "Suiza",
        "dial": "+41",
        "code": "CH"
    },
    {
        "en": "Syrian Arab Republic",
        "es": "Siria",
        "dial": "+963",
        "code": "SY"
    },
    {
        "en": "Taiwan, Province of China",
        "es": "Taiwán",
        "dial": "+886",
        "code": "TW"
    },
    {
        "en": "Tayikistan",
        "es": "Tayikistán",
        "dial": "+992",
        "code": "TJ"
    },
    {
        "en": "Tanzania, United Republic of",
        "es": "Tanzania",
        "dial": "+255",
        "code": "TZ"
    },
    {
        "en": "Thailand",
        "es": "Tailandia",
        "dial": "+66",
        "code": "TH"
    },
    {
        "en": "Timor-Leste",
        "es": "Timor Oriental",
        "dial": "+670",
        "code": "TL"
    },
    {
        "en": "Togo",
        "es": "Togo",
        "dial": "+228",
        "code": "TG"
    },
    {
        "en": "Tokelau",
        "es": "Tokelau",
        "dial": "+690",
        "code": "TK"
    },
    {
        "en": "Tonga",
        "es": "Tonga",
        "dial": "+676",
        "code": "TO"
    },
    {
        "en": "Trinidad and Tobago",
        "es": "Trinidad y Tobago",
        "dial": "+1868",
        "code": "TT"
    },
    {
        "en": "Tunisia",
        "es": "Túnez",
        "dial": "+216",
        "code": "TN"
    },
    {
        "en": "Turkey",
        "es": "Turquía",
        "dial": "+90",
        "code": "TR"
    },
    {
        "en": "Turkmenistan",
        "es": "Turkmenistán",
        "dial": "+993",
        "code": "TM"
    },
    {
        "en": "Turks and Caicos Islands",
        "es": "Islas Turcas y Caicos",
        "dial": "+1649",
        "code": "TC"
    },
    {
        "en": "Tuvalu",
        "es": "Tuvalu",
        "dial": "+688",
        "code": "TV"
    },
    {
        "en": "Uganda",
        "es": "Uganda",
        "dial": "+256",
        "code": "UG"
    },
    {
        "en": "Ukraine",
        "es": "Ucrania",
        "dial": "+380",
        "code": "UA"
    },
    {
        "en": "United Arab Emirates",
        "es": "Emiratos Árabes Unidos",
        "dial": "+971",
        "code": "AE"
    },
    {
        "en": "United Kingdom",
        "es": "Reino Unido",
        "dial": "+44",
        "code": "GB"
    },
    {
        "en": "United States",
        "es": "Estados Unidos",
        "dial": "+1",
        "code": "US"
    },
    {
        "en": "Uruguay",
        "es": "Uruguay",
        "dial": "+598",
        "code": "UY"
    },
    {
        "en": "Uzbekistan",
        "es": "Uzbekistán",
        "dial": "+998",
        "code": "UZ"
    },
    {
        "en": "Vanuatu",
        "es": "Vanuatu",
        "dial": "+678",
        "code": "VU"
    },
    {
        "en": "Venezuela, Bolivarian Republic of",
        "es": "Venezuela",
        "dial": "+58",
        "code": "VE"
    },
    {
        "en": "Vietnam",
        "es": "Vietnam",
        "dial": "+84",
        "code": "VN"
    },
    {
        "en": "Virgin Islands, British",
        "es": "Islas Vírgenes Británicas",
        "dial": "+1284",
        "code": "VG"
    },
    {
        "en": "Virgin Islands, U.S.",
        "es": "Islas Vírgenes de los Estados Unidos",
        "dial": "+1340",
        "code": "VI"
    },
    {
        "en": "Wallis and Futuna",
        "es": "Wallis y Futuna",
        "dial": "+681",
        "code": "WF"
    },
    {
        "en": "Yemen",
        "es": "Yemen",
        "dial": "+967",
        "code": "YE"
    },
    {
        "en": "Zambia",
        "es": "Zambia",
        "dial": "+260",
        "code": "ZM"
    },
    {
        "en": "Zimbabwe",
        "es": "Zimbabue",
        "dial": "+263",
        "code": "ZW"
    },
    {
        "en": "Åland Islands",
        "es": "Åland",
        "dial": "+358",
        "code": "AX"
    }
]

export default countries