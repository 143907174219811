import { Component, OnInit, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-pdf-overlay',
  templateUrl: './pdf-overlay.component.html',
  styleUrls: ['./pdf-overlay.component.scss']
})
export class PdfOverlayComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) { }

  isOpen:boolean

  ngOnInit(): void {
  }

  open() {
    this.renderer.addClass(document.body, 'overflow-hidden')
    this.isOpen = true
  }

  close() {
    this.isOpen = false
    this.renderer.removeClass(document.body, 'overflow-hidden')
  }

}
