<div class="auth-container" [appThemeColor]="colorTheme">

    <div class="left" style="background-image: url({{fondoCentral}});">
        <app-loader *ngIf="loadingImagenes" class="box" [big]="true"></app-loader>
        <div *ngIf="!loadingImagenes && imagenCentral" class="box central-image" style="
                background-size: cover; 
                border-top-left-radius: 5px; 
                border-bottom-left-radius: 5px;
                background-image: url({{imagenCentral}});
                background-size: 100% 100%;">
        </div>
    </div>

    <div class="right">
        <div class="box container-form">
            <form [formGroup]="loginForm" class="login-form" (ngSubmit)="checkCaptcha($event)">
                <app-loader *ngIf="loadingImagenes" class="logo d-flex justify-content-center" [big]="false"></app-loader>
                <img *ngIf="!loadingImagenes && logoWeb" class="logo" src='{{logoWeb}}' 
                    style=" width: 140px; display: block; margin: 0 auto; margin-bottom: 5px;"
                >
                <img *ngIf="!loadingImagenes && !logoWeb" class="logo" src='../../assets/img/noe.svg' style=" width: 140px; display: block; margin: 0 auto;">

                <div class="input-user-container">
                    <app-input
                        type="text" 
                        label="Usuario" 
                        formControlName="user"
                        placeholder="nombre@correo.com"
                    ></app-input>
                    <i 
                        class="las la-info-circle cursor-pointer"
                        appTooltip="Ingresar correo electrónico"
                    ></i>
                </div>

                <div class="input-pass-container">
                    <app-input 
                        [(type)]="inputType" 
                        label="Contraseña" 
                        formControlName="password"
                        appInputPassIcon
                    ></app-input>
                    <i class="las la-eye-slash"></i>
                </div>

                <p class="error-message" *ngIf="errorCredenciales">Error de Credenciales</p>
                <p class="error-message" *ngIf="errorReset">La contraseña del usuario debe ser restaurada</p>
                <p class="error-message" *ngIf="errorSubdominio">El usuario no pertenece a este subdominio</p>

                <p class="login-help" (click)="recovery()">¿Olvidé mi contraseña?</p>

                <ngx-invisible-recaptcha #captcha [siteKey]="siteKey" (reset)="reset()" (ready)="ready()" (load)="load()" (success)="success($event)" (expire)="expire()" (error)="error()" [useGlobalDomain]="false" badge="bottomleft" [(ngModel)]="recaptcha" [ngModelOptions]="{ standalone: true }">
                </ngx-invisible-recaptcha>

                <button *ngIf="!loading" type="submit">Ingresar</button>

                <button *ngIf="loading" class="disabled loading" type="submit">
                    Ingresando
                </button>

                <app-footer></app-footer>

            </form>

        </div>
    </div>

    <!-- <div class="form-container">

        <div class="display">
            <img src="../../assets/img/bg.jpg">
        </div>
        
        <form [formGroup]="loginForm" class="login-form" (submit)="login($event)">

            <img class="logo" src="../../assets/img/noe.svg" alt="NOE">

            <app-input
            type="text"
            label="Usuario"
            formControlName="user"></app-input>

            <app-input
            type="password"
            label="Contraseña"
            formControlName="password"></app-input>

            <p class="error-message" *ngIf="errorCredenciales">Error de Credenciales</p>
            <p class="error-message" *ngIf="errorReset">La contraseña del usuario debe ser restaurada</p>

            <p class="login-help" (click)="recovery()">He olvidado mi contraseña</p>

            <button 
            *ngIf="!loading"
            class="login-submit" 
            type="submit">Ingresar</button>

            <button 
            *ngIf="loading"
            class="login-submit disabled loading" 
            type="submit">
                Ingresando
            </button>

        </form>

    </div> -->
</div>

<app-loader 
    *ngIf="loading" 
    class="login-loading" 
    [big]="true"
    [no-bg]="true"
></app-loader>

<!-- Modal Cambiar Contraseña Temporal -->
<app-modal #passModal titleModal="Crear Nueva Contraseña" [hide-footer]="true">


    <form [formGroup]="passForm" (submit)="completarPass($event)">

        <app-input type="password" label="Nueva Contraseña" formControlName="password" [required]="true"></app-input>

        <app-input type="password" label="Confirmar Contraseña" formControlName="confirm" [required]="true"></app-input>

        <app-password-validators [form]="passForm" [validators]="this.validators.validators">
        </app-password-validators>

        <div class="form-footer">
            <button *ngIf="!pLoading" class="login-submit d-block w-100" type="submit">Ingresar</button>

            <button *ngIf="pLoading" class="login-submit disabled loading d-block w-100" type="submit">
                Ingresando
            </button>
        </div>

    </form>

</app-modal>


<!-- Modal Recuperar -->
<app-modal #recoveryModal (closed)="clearRecovery()" titleModal="Recuperar Contraseña" [hide-footer]="true">

    <form [formGroup]="recoveryForm" (submit)="recover($event)" [hidden]="insertCode">

        <div class="recover-container">
            <img 
                class="recover-container__img"
                src="/assets/img/recover-password.png" 
                width="160" alt=""
            >
            
            <div class="recover-container__actions">
                <app-input
                    #user 
                    type="text" 
                    label="Usuario"
                    placeholder="nombre@correo.com"
                    formControlName="user"
                ></app-input>

                <p class="error-message" *ngIf="errorNoVerificado">
                    No podemos restaurar la contraseña de {{recoveryForm.get('user').value}} porque este usuario no posee correo electrónico o número de teléfono verificados.
                </p>
                <p class="error-message" *ngIf="errorNoExiste">
                    Este usuario no existe
                </p>
                <p class="error-message" *ngIf="errorTemporal && !rLoading">
                    No podemos restaurar una contraseña temporal. Se ha reenviado la invitación de bienvenida con su contraseña al correo <b>{{correoUsuario}}</b>.
                </p>
                <p class="error-message" *ngIf="maximoDeIntentos">
                    Ha superado el máximo de intentos de recuperación. Por favor espere unos momentos antes de intentar de nuevo.
                </p>

                <!-- <p class="error-message" *ngIf="errorSubdominio">El usuario no pertenece a este subdominio</p> -->

                <div class="form-footer">
                    <button 
                        *ngIf="!rLoading && !errorTemporal" 
                        class="login-submit d-block w-xs-50 w-100" 
                        type="submit"
                    >Continuar</button>

                    <button 
                        *ngIf="rLoading" 
                        class="login-submit disabled loading d-block w-xs-50 w-100" 
                        type="submit"
                    >
                        Enviando
                    </button>

                    <button 
                        *ngIf="errorTemporal && !rLoading" 
                        type="submit" 
                        class="login-submit d-block w-xs-50 w-100" 
                        (click)="clearRecovery(); recoveryModal.close()"
                    >Entendido</button>
                </div>
            </div>
        </div>

    </form>

    <form [formGroup]="codeForm" (submit)="submitCode($event)" [hidden]="!insertCode">

        <em>Se te ha enviado un correo con un “código de verificación” para que puedas generar una nueva contraseña.</em>

        <app-input type="text" label="Usuario" formControlName="user" [required]="true"></app-input>

        <app-input type="text" label="Código de verificación" formControlName="code" [required]="true"></app-input>

        <p class="error-message" *ngIf="errorCode">Código de verificación incorrecto</p>

        <app-input type="password" label="Nueva Contraseña" formControlName="password" [required]="true"></app-input>

        <app-input type="password" label="Confirmar Contraseña" formControlName="confirm" [required]="true"></app-input>

        <app-password-validators [form]="codeForm" [validators]="this.validators.validators">
        </app-password-validators>

        <div class="form-footer">
            <button *ngIf="!cLoading" class="login-submit d-block w-100" type="submit">Confirmar</button>

            <button *ngIf="cLoading" class="login-submit disabled loading d-block w-100" type="submit">
                Enviando
            </button>
        </div>

    </form>

</app-modal>


<!-- Modal Sesión Terminada -->
<app-modal #modalSesion>

    <div class="display info w-100 mt-1">
        <span class="d-inline-block"><i class="las la-clock"></i></span>
        <p class=" text-center mt-2">{{sessionMsg}}</p>
    </div>

    <div class="text-center mt-4">
        <button class="btn success" (click)="modalSesion.close()">Entendido</button>
    </div>
</app-modal>