import {
    Directive,
    Input, 
    OnChanges,
    SimpleChanges
} from "@angular/core";

@Directive({
    selector: '[appThemeColor]'
})
export class CustomColorDirective implements OnChanges {

    @Input('appThemeColor') customThemeColor: string;

    private readonly defaultColor = '#119ad7';

    ngOnChanges(change: SimpleChanges): void {
        const { customThemeColor } = change;

        if (customThemeColor) {
            const color = customThemeColor.currentValue ?? this.defaultColor;
            document.documentElement.style.setProperty('--primary', color);
        }
    }
}