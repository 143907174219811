import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BaseUnsubscribe } from '@noe/util/base-unsubscribe';

@Component({
  selector: 'app-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.scss']
})
export class WizardStepComponent extends BaseUnsubscribe implements OnInit {

  @Input('active') active:boolean
  @Input('index') index:number
  @Input('disable') disable:boolean

  @Output()
  onvalidate: EventEmitter<{index:number, valid:boolean}> = new EventEmitter()

  valid:boolean

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  updateState() {
    this.onvalidate.emit({index:this.index,valid:this.valid})
  }

}
