<h4 class="req-title">Requisitos de Contraseña</h4>

<ul class="req-list" *ngIf="validators">
    <li *ngIf="validators.length8Validator"
    [class.error]="(form.touched && form.controls.password.value == '') || form.controls.password.errors?.minlength"
    [class.success]="form.controls.password.value && form.controls.password.value != '' && !form.controls.password.errors?.minlength">
        <span></span>Al menos 8 caracteres
    </li>
    <li *ngIf="validators.length6Validator"
    [class.error]="(form.touched && form.controls.password.value == '') || form.controls.password.errors?.minlength"
    [class.success]="form.controls.password.value && form.controls.password.value != '' && !form.controls.password.errors?.minlength">
        <span></span>Al menos 6 caracteres
    </li>
    <li *ngIf="validators.max8Validator"
    [class.error]="(form.touched && form.controls.password.value == '') || form.controls.password.errors?.maxlength"
    [class.success]="form.controls.password.value && form.controls.password.value != '' && !form.controls.password.errors?.maxlength">
        <span></span>No más de 8 caracteres
    </li>
    <li *ngIf="validators.lowercaseValidator"
    [class.error]="form.controls.password.errors?.lowercaseValidator"
    [class.success]="form.controls.password.value && form.controls.password.value != '' &&!form.controls.password.errors?.lowercaseValidator">
        <span></span>Al menos 1 letra minúscula
    </li>
    <li *ngIf="validators.capitalValidator"
    [class.error]="form.controls.password.errors?.capitalValidator"
    [class.success]="form.controls.password.value && form.controls.password.value != '' &&!form.controls.password.errors?.capitalValidator">
        <span></span>Al menos 1 letra mayúscula
    </li>
    <li *ngIf="validators.numberValidator"
    [class.error]="form.controls.password.errors?.numberValidator"
    [class.success]="form.controls.password.value && form.controls.password.value != '' &&!form.controls.password.errors?.numberValidator">
        <span></span>Al menos 1 número
    </li>
    <li *ngIf="validators.symbolValidator"
    [class.error]="form.controls.password.errors?.symbolValidator"
    [class.success]="form.controls.password.value && form.controls.password.value != '' &&!form.controls.password.errors?.symbolValidator">
        <span></span>Al menos 1 símbolo. {{ 'Ej: = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < \' : ; | _ ~ `' }}
    </li>
</ul>