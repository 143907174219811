import { Component, ElementRef, OnInit, Self, Optional, Input, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @ViewChild('element') element:ElementRef
  @Input('preventDefault') preventDefault:boolean
  @Input('no-margin') noMargin:boolean

  @Output()
  onactivate: EventEmitter<any> = new EventEmitter()
  @Output()
  ondeactivate: EventEmitter<any> = new EventEmitter()

  value:boolean

  constructor(
    private renderer: Renderer2,
    @Self()
    @Optional()
    public ngControl: NgControl) {
      if (this.ngControl) { this.ngControl.valueAccessor = this }
  }

  onChangeCallback: any = () => {}
  onTouchedCallback: any = () => {}

  writeValue(value:any) {
    // if (this.type==='select') console.log('wv', value)
    this.value = value?.target ? value.target.value : value
    this.onChangeCallback(value)
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn
  }
  setDisabledState(disabled:boolean) {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', disabled);
  }

  ngOnInit(): void {
  }

  handleClick(e) {
    e.preventDefault()
    this.value ? this.ondeactivate.emit() : this.onactivate.emit()
  }

  handleChange(e) {

    let val:any = e.target.checked
    this.writeValue(val)
    
    val ? this.onactivate.emit() : this.ondeactivate.emit()
  }

}
