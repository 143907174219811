<input type="file" #fileInputMulti multiple (change)="change($event)">
<input type="file" #fileInput (change)="change($event)">

<div [class.less-padding]="lessPadding" class="dropzone" (drop)="drop($event)" (dragover)="allowDrop($event)">

    <div class="text">
        <!--<i class="las la-file-invoice"></i>-->
        {{placeholder}}
        <div class="text-med">Tamaño: 273 x 185</div>
        <div class="opcion">o</div>
        <div class="boton-archivo" (click)="handleClick()">Seleccione un archivo</div>
    </div>

</div>
