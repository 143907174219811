import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminos-y-condiciones',
  templateUrl: './terminos-y-condiciones.component.html',
  styles: ['hr {border:none; border-top:1px solid #eee; margin:20px 0;}']
})
export class TerminosYCondicionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
