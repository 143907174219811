import { 
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    OnInit
} from "@angular/core";

import { takeUntil } from "rxjs/operators";

import { MenuExpandedService } from "./menu-expanded.service";
import { BaseUnsubscribe } from "@noe/util/base-unsubscribe";

@Directive({
    selector: '[appMenuDropdown]'
})
export class MenuDropdownDirective extends BaseUnsubscribe implements OnInit {
    @HostBinding('class.open') isOpen = false;
    toggleSidebar: EventEmitter<any> = new EventEmitter()
    toggleSidebarCollapse: EventEmitter<any> = new EventEmitter()


    @HostListener('click')
    toggleOpen() {
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            this.menuService.expanded$.next(this);
        }

        event.stopPropagation()
        this.toggleSidebar.emit()
        this.toggleSidebarCollapse.emit()
    }
    
    constructor(private menuService: MenuExpandedService) {
        super();
    }

    ngOnInit(): void {
        this.closeOtherMenus();
    }
    
    closeOtherMenus(): void {
        this.menuService.expanded$
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: MenuDropdownDirective) => {
    
                if (this !== value) {
                    this.isOpen = false;
                }
            });
            event.stopPropagation()
            this.toggleSidebar.emit()
            this.toggleSidebarCollapse.emit()
            
    }
}