import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface IDataRoute {
    modulo: string;
    hijo: string;
    isCustom: boolean;
}

@Injectable({ providedIn: 'root' })
export class SideBarService {
    showWarning$: Subject<IDataRoute> = new Subject<IDataRoute>();
}