<div class="picker">

    <!-- Calendar View -->
    <div class="header">
        <div class="month">{{months[currentMonth]}}</div>
        <div class="year">{{currentYear}}</div>
        <div class="navigation">
            <ng-container *ngIf="nav">
                <button type="button" class="prev" (click)="onnav.emit('prev')">
                    <span></span>
                </button>
                <button type="button" class="next" (click)="onnav.emit('next')">
                    <span></span>
                </button>
            </ng-container>
        </div>
    </div>

    <div class="week">
        <div *ngFor="let w of weekDays" class="cell weekday">{{w}}</div>
    </div>

    <div class="calendar">

        <ng-container *ngFor="let d of prevDays">
            <div></div>
        </ng-container>

        <ng-container *ngFor="let d of currentDays">
            <div 
            [class.active]="d.selected"
            [class.disabled]="d.disabled"
            [class.range]="d.range"
            class="cell day" 
            (click)="selectDate(d)"><span>{{d.day}}</span></div>
        </ng-container>

        <ng-container *ngFor="let d of nextDays">
            <div></div>
        </ng-container>

    </div>
    <!-- Calendar View -->

</div>
