import { Injectable } from "@angular/core";
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormValidationService {

    constructor(){ }

    validateForm(form: FormGroup) {
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

}