<div class="facial-grid">

    <div class="box" *ngFor="let box of boxes">

        <ng-container *ngIf="!box.img">

            <!-- Selección -->
            <ng-container *ngIf="!box.view">
                <h5 class="title">Capturar {{box.name}}</h5>
                <div *ngIf="box.name === 'documento'">
                    <img 
                        src="../../../assets/img/facial/capture-dni.gif" 
                        width="130" height="120"
                        alt="dni"
                    >
                </div>
                <div *ngIf="box.name === 'rostro'">
                    <img 
                        src="../../../assets/img/facial/capture-facial.gif" 
                        width="130" height="120"
                        alt="facial"
                    >
                </div>

                <!--<div class="icon">
                    <i *ngIf="box.name === 'documento'" class="las la-id-card"></i>
                    <i *ngIf="box.name === 'rostro'" class="las la-user-circle"></i>
                </div>-->


                <button class="action-link" *ngIf="box.name === 'documento'" (click)="seleccionar('carga', box)">Cargar Imágen</button>
                <button class="action-link" (click)="seleccionar('indicaciones', box)">Tomar Fotografía</button>
            </ng-container>

            <!-- Cargar Indicaciones -->
            <ng-container *ngIf="box.view === 'indicaciones'">
                <h5 class="title-facial color-texto">
                    Capturar {{ box.name | capitalizeName }}
                </h5>
                <p class="text-center" *ngIf="box.name === 'documento'">
                    <span>Posicione su documento de identidad y proceda a realizar la captura</span>
                </p>
                <p class="text-center" *ngIf="box.name === 'rostro'">
                    <span>Usamos su rostro para realizar la validación de vitalidad</span>
                </p>

                <img 
                    *ngIf="box.name === 'documento'"
                    src="../../../assets/img/facial/capture-dni.gif" 
                    width="130" height="120"
                    alt="dni"
                >
                <img 
                    *ngIf="box.name === 'rostro'"
                    src="../../../assets/img/facial/capture-facial.gif" 
                    width="130" height="120"
                    alt="facial"
                >
                <ng-container *ngIf="box.name === 'documento'">

                    <div class="facial-check-container document">
                        <div class="facial-check__content">
                            <span [class.checked]="checkPaginas" class="check-box round"></span>
                        </div>
                        <div class="facial-check__text">
                            <div class="bold">Buena iluminación</div>
                            <div>Asegúrese de estar en un area con buena iluminación.</div>
                        </div>
                    </div>
                    <div class="facial-check-container">
                        <div class="facial-check__content">
                            <span [class.checked]="checkPaginas" class="check-box round"></span>
                        </div>
                        <div class="facial-check__text">
                            <div class="bold">Buen enfoque</div>
                            <div>Antes de tomar la captura, verifique que su DNI este bien enfocado.</div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="box.name === 'rostro'">
                    <div class="facial-check-container">
                        <div class="facial-check__content">
                            <span [class.checked]="checkPaginas" class="check-box round"></span>
                        </div>
                        <div class="facial-check__text">
                            <div class="bold">Buena iluminación</div>
                            <div>Asegúrese de estar en un área con buena iluminación y que sus orejas estén descubiertas.</div>
                        </div>
                    </div>
                    <div class="facial-check-container">
                        <div class="facial-check__content">
                            <span [class.checked]="checkPaginas" class="check-box round"></span>
                        </div>
                        <div class="facial-check__text">
                            <div class="bold">Mirada recta</div>
                            <div>Mantenga su dispositivo a la altura de los ojos y mire directamente a la cámara.</div>
                        </div>
                    </div>
                </ng-container>

                <button 
                    class="btn success" 
                    (click)="seleccionar('captura', box)"
                >Siguiente</button>
            </ng-container>


            <ng-container *ngIf="box.view === 'carga'">
                <em>Peso Máximo: 1 MB</em>
                <app-dropzone #drop [esFacial]="true" (onconvert)="cargarFoto($event, box)"></app-dropzone>

                <button class="text-only" (click)="box.view = null"><i class="las la-angle-left"></i> Regresar</button>
            </ng-container>

            <ng-container *ngIf="box.view === 'captura-selfie'">
                <h5 class="title-facial color-texto">
                    Capturar {{ box.name | capitalizeName }}
                </h5>
                <p class="text-center" *ngIf="box.name === 'selfie'">
                    <span>Usamos su rostro para comparar con la foto de su documento de identidad</span>
                </p>
                <img 
                    *ngIf="box.name === 'selfie'"
                    src="../../../assets/img/facial/capture-facial.gif" 
                    width="130" height="120"
                    alt="facial"
                >
                <ng-container *ngIf="box.name === 'selfie'">
                    <div class="facial-check-container">
                        <div class="facial-check__content">
                            <span [class.checked]="checkPaginas" class="check-box round"></span>
                        </div>
                        <div class="facial-check__text">
                            <div class="bold">Buena iluminación</div>
                            <div>Asegúrese de estar en un área con buena iluminación y que sus orejas estén descubiertas.</div>
                        </div>
                    </div>
                    <div class="facial-check-container">
                        <div class="facial-check__content">
                            <span [class.checked]="checkPaginas" class="check-box round"></span>
                        </div>
                        <div class="facial-check__text">
                            <div class="bold">Mirada recta</div>
                            <div>Mantenga su dispositivo a la altura de los ojos y mire directamente a la cámara.</div>
                        </div>
                    </div>
                </ng-container>
                <button class="action-link" (click)="seleccionar('captura', box)">Tomar Selfie</button>
            </ng-container>

            <!-- Tomar Foto -->
            <ng-container *ngIf="box.view === 'captura'">
                <ng-container *ngIf="box.name === 'documento'">
                    <div class="cam-view">
                        <webcam 
                            #webcam 
                            [allowCameraSwitch]="false" 
                            (imageCapture)="tomarFoto($event, box)" 
                            (initError)="notify.error('No se pudo acceder a la web cam')"
                            (cameraSwitched)="onSwitched(box.name)"
                        ></webcam>
    
                        <button 
                            class="cam-shot" 
                            (click)="webcam.takeSnapshot()" 
                            title="Tomar Foto"
                        ><i class="las la-camera"></i></button>
                        <app-box-area 
                            boxWidth="200px" 
                            boxHeight="120px"
                            *ngIf="showDocAreaBox"
                        ></app-box-area>
                    </div>
                </ng-container>
                <ng-container *ngIf="box.name === 'rostro'">
                    <app-liveness-detection
                        *ngIf="!!resChallengeDetail && !isLoading"
                        [challengeDetail]="resChallengeDetail"
                        (success)="verifyResponse($event,box)"
                    ></app-liveness-detection>
                    <app-loader
                        *ngIf="isLoading"
                        [big]="true"
                        [no-bg]="true"
                    ></app-loader>
                </ng-container>

                <ng-container *ngIf="box.name === 'selfie'">
                    <div class="cam-view">
                        <webcam 
                            #webcam 
                            [allowCameraSwitch]="false" 
                            (imageCapture)="tomarSelfie($event, box)" 
                            (initError)="notify.error('No se pudo acceder a la web cam')"
                            (cameraSwitched)="onSwitched(box.name)"
                        ></webcam>
    
                        <button 
                            class="cam-shot" 
                            (click)="webcam.takeSnapshot()" 
                            title="Tomar Foto"
                        ><i class="las la-camera"></i></button>
                        <app-box-area 
                            boxWidth="200px" 
                            boxHeight="120px"
                            *ngIf="showDocAreaBox"
                        ></app-box-area>
                    </div>
                </ng-container>

                <button
                    class="text-only"
                    (click)="box.view = null"
                ><i class="las la-angle-left"></i> Regresar</button>
            </ng-container>

        </ng-container>

        <!-- Preview -->
        <ng-container *ngIf="box.img">
            <div class="preview-container">
                <img class="img-preview" [src]="box.img">
                <button title="Eliminar" class="preview-remove" (click)="removerFoto(box)"><i class="las la-times"></i></button>
            </div>
        </ng-container>

    </div>

</div>

<!-- Modal intentos -->
<app-modal #modalIntentos titleModal="Limite de Intentos" [hide-footer]="true">
    <p>¡Superó su número de intentos!</p>
    <p><strong>¿Desea realizar {{textIntentos}} con código de seguridad?</strong></p>

    <div class="form-footer">
        <button class="btn default" (click)="confirmarCodigoSeguridad()" >Sí</button>
        <button class="btn danger" (click)="closeModalIntentos()" >No</button>
    </div>
</app-modal>