import { MediaStreamInfo } from "src/app/models/Challenge.model";

export class LivenessDetectionUtil {

    private static mediaStreamInfo: MediaStreamInfo;

    static getMediaStreamInfo(): MediaStreamInfo {
        return LivenessDetectionUtil.mediaStreamInfo;
    }

    static loadMediaStream(
        height: number,
        width: number,
        successCallback: (stream: MediaStreamInfo) => void,
        errorCallback: (message: string) => void
    ) {
        const constraints: MediaStreamConstraints = {
            audio: false,
            video: {
                width,
                height,
                facingMode: 'user'
            }
        };

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((mediaStream: MediaStream) => {
                try {
                    LivenessDetectionUtil.mediaStreamInfo = {
                        mediaStream,
                        actualHeight: mediaStream.getVideoTracks()[0].getSettings().height,
                        actualWidth: mediaStream.getVideoTracks()[0].getSettings().width
                    };
                    successCallback(LivenessDetectionUtil.mediaStreamInfo);
                } catch (error) {
                    errorCallback('Error al obtener los tamaños reales del video');
                }
            })
            .catch(() => {
                errorCallback('Ocurrio un error al acceder a la cámara');
            });
    }

    static stopMediaStream() {
        const { mediaStream } = LivenessDetectionUtil.getMediaStreamInfo();

        if (mediaStream) {
            mediaStream.getTracks()[0].stop();
        }
    }
}