import { NgModule } from "@angular/core";

import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';

const MATERIAL_MODULES = [
    MatSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule
];

@NgModule({
    exports: [MATERIAL_MODULES]
})
export class MaterialModule {}