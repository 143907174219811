import { 
  Component, 
  OnInit, 
  Input, 
  Output, 
  ViewChild, 
  ElementRef, 
  HostListener, 
  EventEmitter, 
  OnChanges, 
  SimpleChanges
} from '@angular/core';

import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Storage } from 'aws-amplify';

import { FirmaHolograficaService } from 'src/app/services/firma-holografica.service';
import { S3ImageComponent } from '../s3-image/s3-image.component';
import { firma } from 'src/app/util/types';
import { ModalComponent } from '../modal/modal.component';
import { FirmaHolografica } from 'src/app/models/FirmaHolografica.model';
import { NotificationsService } from 'src/app/services/notifications.service';
import { AuthService } from 'src/app/services/auth.service';
import { FirmarPdfService } from './fimar-pdf.service';
import { ServiciosProcesoService } from '../../services/servicios-proceso.service';

@Component({
  selector: 'app-firmar-pdf',
  templateUrl: './firmar-pdf.component.html',
  styleUrls: ['./firmar-pdf.component.scss'],
  providers: [FirmarPdfService]
})
export class FirmarPdfComponent implements OnChanges, OnInit {

  @ViewChild('signature') pad:SignaturePadComponent
  @ViewChild('viewer') viewer: ElementRef
  @ViewChild('current') current: S3ImageComponent
  @ViewChild('modalDibujar') modalDibujar:ModalComponent
  @ViewChild('modalCargar') modalCargar:ModalComponent
  @ViewChild('modalTerminos') modalTerminos:ModalComponent
  @ViewChild('modalCambiarmeCuentaPago') modalCambiarmeCuentaPago:ModalComponent

 @ViewChild('currentPage') inputSearch;

  @Input('masivo') set masivo (valor: boolean) {
    this._masivo = valor
  }
  pdfList:any[] = []
  @Input('pdfList') set getPdfList (lista: any) {
    this.pdfList = [...lista];

    if (this._masivo) {
      this.initFirmasMasivo();
    }
  }
  @Input('files') files:string[] = [];
  loaderFirmar:boolean = false
  @Input('loading') set getLoading (estado: any) {
    this.loaderFirmar = estado
  }

  @Input('currentDoc') currentDoc: any;
  @Output() 
  onconfirm: EventEmitter<any> = new EventEmitter()
  @Output() onCheckTodosPaginas: EventEmitter<boolean> = new EventEmitter()

  masivoCurrent: number = 0;
  _masivo:boolean;
  // only for single doument.
  isSignedDoc: boolean = false;
  esGratis:boolean
  checkPaginas : boolean = false

  
  
  constructor(
    public firmaHolograficaService: FirmaHolograficaService,
    public notify: NotificationsService,
    public authService: AuthService,
    private signService: FirmarPdfService,
    public serviciosProcesoService: ServiciosProcesoService
  ) { 
    
  }

  draggingCorner: boolean
  draggingWindow: boolean

  ngOnChanges(change: SimpleChanges): void {
    this.checkPaginas = false  
    this.loaderFirmar = false;
    const { masivo, currentDoc } = change;
    if (masivo) {
      this.masivo = masivo.currentValue;
      this.signService.buildCheckboxesStatus(this.pdfList.length);
      this.signService.buildSignaturesStatus(this.pdfList.length);
    }

    if (!!currentDoc && currentDoc?.currentValue) {
      const { signedStatusDocument, statusSignatureExternal } = currentDoc.currentValue;
      this.isSignedDoc = !!signedStatusDocument && !!this.authService.obtenerEstadoActivarTimbrado() && !statusSignatureExternal;
    }
  }


  ngOnInit(): void {
    this.listarFirmasHolograficas();
    this.esGratis = this.authService.obtenerTipoCuenta() === 'GRATUITA'
    //this.esGratis = true
  }
  
  checkFirmarTodas:boolean

  mensaje: string = "Firmar";
    saludo(value) {
    this.mensaje = value;
    }
    
  initFirmasMasivo() {
    this.firmasMasivo.length = this.pdfList.length;
    this.firmasMasivo.forEach(f => {
      f = []; 
      f.forEach(p => {
        p = [];
      });
    });
  }

  
  //christian anchivilca
  comboPersonalizacionSelect:any 
  idNameCombo:string
  idKeyCombo:string
  verNumeroPaginas :boolean=false
  txtNumeroPaginas:string
  tipos:any[] = [
    'Todos',
    //'Personalizado'
  ]

  options = [
    
    { name: "Todos", value: 1 },
    //{ name: "Personalizado", value: 2 }
  ]

  getPersonalizar(){
    //this.comboPersonalizacionSelect = this.tipos[this.tipos.length - 1]
    if(this.comboPersonalizacionSelect === 'Personalizado'){
      this.verNumeroPaginas = true
    }else{
      this.verNumeroPaginas = false
    }

  }

  firmaSeleccionada:any
  
  firmasUsuario:any[] = []
  loadingFirmas:boolean
  listarFirmasHolograficas() {
  
    this.loadingFirmas = true
    this.firmaHolograficaService.listarFirmas(
      this.authService.obtenerIdEmpresaActiva(),
      this.authService.obtenerUsername()
      ).subscribe(data => {
      this.firmasUsuario = [...data]
      this.firmaSeleccionada = this.firmasUsuario[this.firmasUsuario.length - 1]?.route
      this.loadingFirmas = false
    }, error => {
      console.log(error)
      this.loadingFirmas = false
    })
  }


  iterar:number[] = [0,1]

  hayFirmas: boolean = false;
  firmas:firma[][] = []
  firmasMasivo:firma[][][] = [[]]
  indexMasivo:number  

  accept:boolean
  recibeAccept(e) {
    this.accept = e;
  }

  addFirma(e: MouseEvent, index: number, masivoIndex: number = null) {
    const target: HTMLImageElement = e.target as HTMLImageElement;

    this.recibeAccept(e);

    if (!this._masivo && this.isStamped(index)) {
      return;
    }
    if (this.draggingWindow || this.draggingCorner || !this.firmaSeleccionada) {
      return;
    }
    if (this.hayFirmas) {
      return;
    }
    
    const firma = {
      key: this.current.key,
      src: this.current.el.nativeElement.src,
      x: e.offsetX,
      y: e.offsetY,
      height: this.current.el.nativeElement.height,
      width: this.current.el.nativeElement.width,
      minArea: e.offsetY * e.offsetX,
      docClientHeight: target.clientHeight,
      docClientWidth: target.clientWidth,
      docNaturalHeight: target.naturalHeight,
      docNaturalWidth: target.naturalWidth,
      page: (masivoIndex ? masivoIndex + 1 : index + 1).toString()
    }

    if (this._masivo) {

      if (!this.firmasMasivo[index][masivoIndex]) this.firmasMasivo[index][masivoIndex] = []
      this.firmasMasivo[index][masivoIndex].push(firma);
      
      this.signService.signStatusList[this.masivoCurrent] = true;
    } else {
      if (!this.firmas[index]) this.firmas[index] = []
      this.firmas[index].push(firma);
    }

    this.hayFirmas = true;
  }

  remove(doc: number, firma: number, masivoFirma: number = null) {
    if (this._masivo) {
      this.firmasMasivo[doc][firma].splice(masivoFirma, 1);
      this.signService.signStatusList[this.masivoCurrent] = false;
    } else {
      this.firmas[doc].splice(firma, 1);
    }
    this.hayFirmas = false;
  }

  isMassiveStamped(document: any, indexImage: number) {
    const { imagenes, signedStatusDocument } = document;

    return !!signedStatusDocument && indexImage === imagenes.length - 1;
  }

  isStamped(indexPage: number): boolean {
    const isLastPage = indexPage === this.files.length - 1;
    return isLastPage && this.isSignedDoc;
  }

  /*accept:boolean
  handleAccept() {
    if (this.accept) {
      this.accept = false
    } else {
      this.modalTerminos.open()
    }
  }*/

  
  handleCheck() {


    if(this.esGratis){

      this.modalCambiarmeCuentaPago.open()

    }else{

      if (this.checkPaginas) {
        this.checkPaginas = false
        this.verNumeroPaginas = false
      } else {
  
    
        this.checkPaginas = true
        if(this.comboPersonalizacionSelect === 'Personalizado'){
          this.verNumeroPaginas = true
    
        }else{
          this.verNumeroPaginas = false
        }
      }
  
      if (this._masivo) {
        this.signService.checkStatusList[this.masivoCurrent] = this.checkPaginas;
      }
      
      this.onCheckTodosPaginas.emit(this.checkPaginas);

    }
 
  }

  modalCambiarmeCuentaPagoLoading:boolean
  aceptarCambiarmeCuentaPago(){
    this.modalCambiarmeCuentaPagoLoading = true
    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio()
     
   
    }
    this.serviciosProcesoService.enviarCorreoContactarme(data).subscribe(data => {
     // console.log(""data)
      this.notify.success("Se envió la solicitud correctamente.")
      this.modalCambiarmeCuentaPagoLoading = false
      this.modalCambiarmeCuentaPago.close()
      //this.modalContactarCuentaFreeLoading = false
    }, error => {
      this.notify.error('Ocurrio un error inesperado')
      this.modalCambiarmeCuentaPagoLoading = false
      this.modalCambiarmeCuentaPago.close()
      //this.modalContactarCuentaFreeLoading = false
    })

  }

  enviar() {
    if (this._masivo) {
      this.setSignMassiveWithChecks();
    }

    this.loaderFirmar = true;

    this.onconfirm.emit(this._masivo ? this.firmasMasivo : this.firmas);
  }

  /**
   * Set massive signature with checkAllPages property.
   */
  setSignMassiveWithChecks(): void {
    const checkList = this.signService.checkStatusList;

    this.firmasMasivo = this.firmasMasivo.map((pdfs: firma[][], index) => {
      return pdfs.map((pdfArr) => {
        return pdfArr.map((pdf) => {
          return {
            ...pdf,
            checkAllPages: checkList[index]
          };
        });
      });
    });
  }



  // DRAG & RESIZE



  area(f:firma) {
    return f.width * f.height;
  }

  currentFirma:firma = {}
  onWindowPress(event: MouseEvent, f:firma) {
    this.currentFirma = f
    this.draggingWindow = true;
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
  }

  onWindowDrag(event: MouseEvent) {
    if (!this.draggingWindow) {
      return;
    }
    let offsetX = event.clientX - this.currentFirma.px;
    let offsetY = event.clientY - this.currentFirma.py;

    this.currentFirma.x += offsetX;
    this.currentFirma.y += offsetY;
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
  }

  topLeftResize(offsetX: number, offsetY: number) {
    this.currentFirma.x += offsetX;
    this.currentFirma.y += offsetY;
    this.currentFirma.width -= offsetX;
    this.currentFirma.height -= offsetY;
  }

  topRightResize(offsetX: number, offsetY: number) {
    this.currentFirma.y += offsetY;
    this.currentFirma.width += offsetX;
    this.currentFirma.height -= offsetY;
  }

  bottomLeftResize(offsetX: number, offsetY: number) {
    this.currentFirma.x += offsetX;
    this.currentFirma.width -= offsetX;
    this.currentFirma.height += offsetY;
  }

  bottomRightResize(offsetX: number, offsetY: number) {
    this.currentFirma.width += offsetX;
    this.currentFirma.height += offsetY;
  }

  onCornerClick(event: MouseEvent, f:firma, resizer?:string) {
    this.currentFirma = f
    this.draggingCorner = true;
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
    this.currentFirma.resizer = resizer;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    if (!this.draggingCorner) {
      return;
    }

    let offsetX = event.clientX - this.currentFirma.px;
    let offsetY = event.clientY - this.currentFirma.py;

    let lastX = this.currentFirma.x;
    let lastY = this.currentFirma.y;
    let pWidth = this.currentFirma.width;
    let pHeight = this.currentFirma.height;

    this[this.currentFirma.resizer](offsetX, offsetY);
    if (this.area(this.currentFirma) < 2000) {
      this.currentFirma.x = lastX;
      this.currentFirma.y = lastY;
      this.currentFirma.width = pWidth;
      this.currentFirma.height = pHeight;
    }
    this.currentFirma.px = event.clientX;
    this.currentFirma.py = event.clientY;
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease(event: MouseEvent) {
    this.draggingWindow = false;
    this.draggingCorner = false;
  }




  preview:string
  setPreview(base:string) {
    this.preview = base
  }

  title:string
  titleDraw:string
  titleUpload:string
  titleWrite:string
  descriptionDraw:string
  descriptionWrite:string
  descriptionUpload:string
  signWrite:string

  description:string
  baseFirma:any
  blob:any
  currentFirmaNueva:any

  tab:number


  tabs(id:number){
    this.tab = id
    console.log('pintame el tabs',this.tab)
  }

  save() {
    console.log("pintame imagen",this.blob)
    this.pad.getCanvas().toBlob((blob) => {this.blob = blob}, 'image/jpg', 0.95)
    this.baseFirma = this.pad.toDataURL()
    console.log("pintame imagen 2",this.baseFirma)
  }

  /*clear() {
    if (this.tab == 1) {
      this.pad.clear()
      this.blob = null
      this.baseFirma  = false
      console.log('pintame el tabs',this.tab)
    }else if (this.tab == 2) {
      this.signWrite = null
      console.log('pintame el tabs',this.tab)
    }else if (this.tab == 3) {
      this.preview = null
      this.blob = null
      console.log('pintame el tabs',this.tab)
    }
  }*/
  clear() {
    this.pad.clear()
    this.blob = null
    this.baseFirma  = false
  }


  cargarLoadingFirma:boolean
  async cargarFirma() {

    //Validacion de campos para carga de firma
    let validation = this.validarCargarFirma()
    if (!validation){
      this.notify.info('Completar los datos para cargar la firma.')
      return;
    }
  
    this.cargarLoadingFirma = true
    const timestamp:number = new Date().getTime()
    const key:string = `${this.authService.obtenerRucEmpresaActiva()}/usuarios/${this.authService.obtenerDocumento()}/firmas_holografas/${timestamp}.jpg`
    const ruta:string = `public/${key}`

    if (this.tab != 2){
      if (this.blob.size > 50000){
        this.notify.info('Por favor ingrese otra imagen de menor peso. Verificar la indicaciones dadas.')
        this.cargarLoadingFirma = false
        return
      }
  
      try {
        await Storage.put(key, this.blob)
      } catch (error) {
        this.notify.error('Hubo un problema al cargar las imágen')
      }
    }

    if (this.tab == 1) {
      this.title = this.titleDraw
      this.description = this.descriptionDraw
      console.log('title', this.title)
      console.log('description', this.description)
    } else if (this.tab == 2) {
      this.title = this.titleWrite
      this.description = this.descriptionWrite
      console.log('title', this.title)
      console.log('description', this.description)
    } else if (this.tab == 3) {
      this.title = this.titleUpload
      this.description = this.descriptionUpload
      console.log('title', this.title)
      console.log('description', this.description)
    }

    const firma:FirmaHolografica = {
      title: this.title,
      description: this.description,
      route: ruta,
      user: this.authService.obtenerUsername(),
      idCompany: this.authService.obtenerIdEmpresaActiva(),
      textoFirma: this.signWrite //Añadido para convertir texto en imagen
    }
    console.log('Firma enviada', firma)

    this.firmaHolograficaService.crearFirma(firma).subscribe(data => {

      this.listarFirmasHolograficas()
      this.modalCargar.close()
      this.modalDibujar.close()
      this.notify.success('Operación exitosa')
      this.reset()
    }, error => {
      this.cargarLoadingFirma = false
      this.notify.error('Hubo un problema al crear su firma')
    })

  }

  validarCargarFirma(): boolean {
    console.log("tab", this.tab)
    if (this.tab == 1 && this.blob != undefined && this.descriptionDraw != undefined && this.titleDraw != undefined && this.blob != null &&
      this.descriptionDraw != null && this.titleDraw != null && this.descriptionDraw != '' && this.titleDraw != '') {
      return true;
    } else if (this.tab == 2 && this.signWrite != undefined && this.descriptionWrite != undefined && this.titleWrite != undefined && this.signWrite != null &&
      this.descriptionWrite != null && this.titleWrite != null && this.descriptionWrite != '' && this.titleWrite != '') {
      return true;
    } else if (this.tab == 3 && this.preview != undefined && this.descriptionUpload != undefined && this.titleUpload != undefined && this.preview != null &&
      this.descriptionUpload != null && this.titleUpload != null && this.descriptionUpload != '' && this.titleUpload != '') {
      return true;
    } else if (this.tab == undefined && this.title != undefined && this.title != null && this.title != '') {
      return true;
    } else {
      return false;
    }
  }

  reset() {
    this.preview = null
    this.blob = null
    this.baseFirma = null
    this.cargarLoadingFirma = false
    this.title = null
    this.description = null
    this.currentFirmaNueva = null
    
    this.titleDraw = null
    this.titleWrite = null
    this.titleUpload = null

    this.descriptionDraw = null
    this.descriptionWrite = null
    this.descriptionUpload = null

    this.signWrite = null
    this.pad.clear()
  }

  documentListShown:boolean = false

  navegarMasivo(action:string) {
 
    if (action === 'prev') {
      if (this.masivoCurrent > 0) {
        if (!this.firmasMasivo[this.masivoCurrent - 1]) this.firmasMasivo[this.masivoCurrent - 1] = [[]]
        this.masivoCurrent--;
      }

    } else {
      if (this.masivoCurrent < this.pdfList.length - 1) {
        if (!this.firmasMasivo[this.masivoCurrent + 1]) this.firmasMasivo[this.masivoCurrent + 1] = [[]]
        this.masivoCurrent++;
      }
    }

    this.checkPaginas = this.signService.checkStatusList[this.masivoCurrent];
    this.hayFirmas = this.signService.signStatusList[this.masivoCurrent];
  }

  /*numPag: number = 0;
  navegarMasivo(action:string) {
    this.inputSearch.nativeElement.value = ' ';
    //console.log(this.inputSearch.nativeElement.value);

    if (action === 'prev') {
      if (this.masivoCurrent > 0) {
        if (!this.firmasMasivo[this.masivoCurrent - 1]) this.firmasMasivo[this.masivoCurrent - 1] = [[]]
        this.masivoCurrent--;
      }
      this.numPaginas();
    } else {
      if (this.masivoCurrent < this.pdfList.length - 1) {
        if (!this.firmasMasivo[this.masivoCurrent + 1]) this.firmasMasivo[this.masivoCurrent + 1] = [[]]
        this.masivoCurrent++;
      }
       console.log(this.masivoCurrent++);
       this.numPaginas();
    }

    this.checkPaginas = this.signService.checkStatusList[this.masivoCurrent];
    this.hayFirmas = this.signService.signStatusList[this.masivoCurrent];
  }

  vnumPag: number
  sendValue(inputValue) {
    this.vnumPag = inputValue
    console.log(this.vnumPag);

    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio(),
      numPag: this.vnumPag
    }

    this.envioNumPag(data);
  }
  
  numPaginas(){
    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio(),
      numPag: this.masivoCurrent + 1
    }
    
    this.envioNumPag(data);
  }

  envioNumPag(data){
    this.serviciosProcesoService.enviarCorreoContactarme(data).subscribe(data => {
      //this.notify.success("Se envió la solicitud correctamente.")
      if (this.vnumPag == 0 || this.vnumPag > 4) {
        this.notify.error("No hay ninguna página con el número " + "'" + this.vnumPag + "'" + " en este documento.")
      } 
    }, error => {
      //this.notify.error('Ocurrio un error inesperado')
    })

    console.log("DATA",data)
  }*/

  restart() {
    setTimeout(() => {
      this.accept = false
      this.hayFirmas = false
      //this.checkPaginas=false
      this.firmas = []
      this.firmasMasivo = [[]]
    }, 800)
  }

}
