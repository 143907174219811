<div 
    class="video-container" 
    *ngIf="!endVerify && !isLoading && !isFail"
>
    <video
        #webcamVideo
        class="webcam-video"
        [width]="videoWidth"
        [height]="videoHeight"
        autoplay
        muted
    ></video>
    <canvas
        #overlayCanvas
        class="overlay-canvas"
        [width]="videoWidth"
        [height]="videoHeight"
    ></canvas>
    <div *ngIf="helpMessage">
        <h5 class="help-message">{{ helpMessage }}</h5>
    </div>
</div>

<div *ngIf="isLoading">
    <app-loader
        [big]="true"
        [no-bg]="true"
    ></app-loader>
    <h2>Procesando...</h2>
</div>
<ng-container *ngIf="!isLoading">
    <div *ngIf="sucessVerify">
        <img src="/assets/img/facial/sucess.svg" height="120px" alt="success"/>
        <div>
            <h2 class="text-message">verificado!</h2>
            <h3 class="text-muted">
                Verificado con éxito.
            </h3>
        </div>
    </div>
    <div *ngIf="(!sucessVerify && endVerify && !isErrorVerify) || isFail">
        <img src="/assets/img/facial/error.svg" height="120px" alt="error"/>
        <h2 class="text-message" *ngIf="isFail; else verifyFail">
            No pudimos detectar un movimiento.
        </h2>
        <ng-template #verifyFail>
            <h2>Hubo un problema en la ubicación de la nariz.</h2>
        </ng-template>
    </div>
    <div *ngIf="isErrorVerify">
        <img src="/assets/img/facial/wrong.svg" height="120px" alt="wrong"/>
        <h2 class="text-message">
            Algo salió mal
        </h2>
    </div>
</ng-container>