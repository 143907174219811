import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirmaHolografica } from '../models/FirmaHolografica.model';


type requestBody = {
  method: string
  data?: FirmaHolografica,
  id?: string,
  idCompany?: string,
  email?: string
}


@Injectable({
  providedIn: 'root'
})
export class FirmaHolograficaService {

  private urlFirmasHolograficas:string = `${environment.apiCore}/holographic-signature/`;

  constructor( private http: HttpClient ) { }

  listarFirmas(empresa:string, user:string):Observable<any> {
    const body:requestBody = { method: 'LIST', idCompany:empresa, email:user }
    return this.http.post(this.urlFirmasHolograficas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  crearFirma(firma:FirmaHolografica) {
    const body:requestBody = { method: 'POST', data: firma }
    return this.http.post(this.urlFirmasHolograficas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerFirma(id:string):Observable<FirmaHolografica> {
    const body:requestBody = { method: 'GET', id: id }
    return this.http.post(this.urlFirmasHolograficas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarFirma(firma:FirmaHolografica) {
    const body:requestBody = { method: 'PUT', data: firma }
    return this.http.post(this.urlFirmasHolograficas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  habilitarFirma(id:string) {
    const body:requestBody = { method: 'PATCH', id: id }
    return this.http.post(this.urlFirmasHolograficas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  deshabilitarFirma(id:string) {
    const body:requestBody = { method: 'DELETE', id: id }
    return this.http.post(this.urlFirmasHolograficas, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  rotateFirma(firma:FirmaHolografica) {
    const body:requestBody = { method: 'POST-ANGLE', data : firma }
    return this.http.post(this.urlFirmasHolograficas, body, { headers: { "x-api-key": environment.xApiKey } })
  }
}
