<mat-form-field floatLabel="never">
    <ng-container matSuffix>
        <ng-content select="[noeSuffix]"></ng-content>
    </ng-container>

    <input
        #inputNative
        matInput
        type="text"
        [placeholder]="placeholder"
        [value]="value || ''"
        [matAutocomplete]="auto"
        (input)="noeInput.emit($event)"
    />

    <mat-autocomplete 
        #auto="matAutocomplete" 
        (optionSelected)="onSelected($event)"
    >
        <mat-option *ngFor="let option of options" [value]="option">
            {{ option }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

